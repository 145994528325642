import { TabelaPrecoItem } from "./TabelaPrecoItem";

export class TabelaPreco implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    nome: string = "";
    ativo: boolean = true;

    itens: TabelaPrecoItem[] = [];

    constructor(model?: TabelaPreco){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.nome = model.nome;
        this.ativo = model.ativo;

        this.itens = model.itens;
    }



}