import { Estado } from "./Estado";

export class Municipio implements Shared.IEntity{

    id: number = 0;
    nome: string = '';
    estado!: Estado;
    estadoId: number = 0;

    constructor(model?: Municipio){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.estado = model.estado;
        this.estadoId = model.estadoId;
    }
}