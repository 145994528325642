
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { ContaCorrenteService, FluxoBancarioService } from '@/core/services/financeiro';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ContaCorrente } from '@/core/models/financeiro';

@Component
export default class CadastroFluxoBancarioTransferencia extends CrudBase{
    @Prop() private value!: string;
    @Prop() private contaCorrenteId!: number;

    item: any = {
        contaCorrenteId: 0,
        historico: '',
        dataCompetencia: '',
        valor: 0,
        identificacao: '',
        receitaId: null,
        despesaId: null
    };
    debitar: boolean = false;
    debitoCredito: any[] = [
        { id: true, nome: "Debitar" },
        { id: false, nome: "Creditar" }
    ]
    service: FluxoBancarioService = new FluxoBancarioService();
    valid: boolean = true;
    $refs!: {
        form: HTMLFormElement
    }

    contaCorrentes: ContaCorrente[] = [];
    contaCorrenteService: ContaCorrenteService = new ContaCorrenteService();

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            this.item.contaCorrenteId = this.contaCorrenteId;
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');        
        }        
    }

    Carregar(){
        this.contaCorrenteService.AutoComplete(true).then(
            res => {
                this.contaCorrentes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if(this.$refs.form.validate()){
            this.loading = true;
            if(this.debitar){
                this.service.Debitar(this.item.contaCorrenteId, this.item.historico, this.item.dataCompetencia, this.item.valor, this.item.identificacao).then(
                    res => {
                        AlertSimpleRes("Aviso!", res);
                        this.Salvou();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                    this.loading = false;
                })
            }
            else{
                this.service.Creditar(this.item.contaCorrenteId, this.item.historico, this.item.dataCompetencia, this.item.valor, this.item.identificacao).then(
                    res => {
                        AlertSimpleRes("Aviso!", res);
                        this.Salvou();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                    this.loading = false;
                })
            }
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.debitar = false;
        this.item = {
            contaCorrenteId: 0,
            historico: '',
            dataCompetencia: '',
            valor: 0,
            identificacao: '',
            receitaId: null,
            despesaId: null
        };
        this.dialog = false;
    }
}
