import { ContaCorrente, FormaPagamento, ReceitaParcela } from ".";
import { Arquivo, Operacao } from "../shared";

export class ReceitaBaixa implements Shared.IEntity {
    
    id: number = 0;
    parcelaId: number = 0;
    parcela!: ReceitaParcela;
    usuarioId!: string;
    dataPagamento: string = '';
    multa: number = 0;
    juros: number = 0;
    desconto: number = 0;
    valor: number = 0;
    contaCorrenteId: number = 0;
    contaCorrente!: ContaCorrente;
    formaPagamentoId: number = 0;
    formaPagamento!: FormaPagamento;
    cancelamento!: Operacao;
    automatica: boolean = false;
    arquivoComprovante: string = '';
    comprovante?: Arquivo;

    constructor(model?: ReceitaBaixa) {
        
        if (!model)
            return;

        this.id = model.id;
        this.parcelaId = model.parcelaId;
        this.parcela = model.parcela;
        this.usuarioId = model.usuarioId;
        this.dataPagamento = model.dataPagamento;
        this.multa = model.multa;
        this.juros = model.juros;
        this.desconto = model.desconto;
        this.valor = model.valor;
        this.contaCorrenteId = model.contaCorrenteId;
        this.contaCorrente = model.contaCorrente;
        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento;
        this.cancelamento = model.cancelamento;
        this.automatica = model.automatica;
        this.arquivoComprovante = model.arquivoComprovante;
        this.comprovante = model.comprovante;
    }
}