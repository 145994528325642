import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class PortoService extends Service {

    constructor() {
        super('v1/porto');
    }

    public AutoComplete(preCarregado: boolean, q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                preCarregado,
                q
            }
        });
    }

    public TernoHoras(id: number) {
        return http.get(`${this.GetNomeControle()}/${id}/TernoHoras`);
    }

    public GetDocumentoPorto(id: any){
        return http.get(`${this.GetNomeControle()}/${id}/documentos`)
    }

    public Documento(id: any, arquivo: any, model: any) : Promise<any> {
        let bodyFormData = new FormData();
        bodyFormData.append("model", JSON.stringify(model));
        if (arquivo != null) {
            bodyFormData.append("arquivo", new Blob([arquivo]), arquivo.name)
        }
        return http.post(`${this.GetNomeControle()}/${id}/documentos`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }
}