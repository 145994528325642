import { LocalOperacaoPeriodo } from "../geral";
import { PropostaOperacaoPortuaria } from "./Proposta";

export class PropostaLocalOperacaoPeriodo implements Shared.IEntity{

    id: number = 0;
    propostaId: number = 0;
    proposta!: PropostaOperacaoPortuaria;
    periodoId: number = 0;
    periodo!: LocalOperacaoPeriodo;
    valor: number = 0;

    constructor(model?: PropostaLocalOperacaoPeriodo){

        if(!model)
            return;

        this.id = model.id;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;
        this.periodoId = model.periodoId;
        this.periodo = model.periodo;
        this.valor = model.valor;
    }
}