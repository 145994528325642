import { CentroCusto, ContaGerencial, Receita } from ".";

export class ReceitaClassificacao implements Shared.IEntity {
    
    id: number = 0;
    receitaId: number = 0;
    receita!: Receita;
    centroCustoId: number = 0;
    centroCusto!: CentroCusto;
    contaGerencialId: number = 0;
    contaGerencial!: ContaGerencial;
    valor: number = 0;
    percentual: number = 0;

    constructor(model?: ReceitaClassificacao) {
        
        if (!model)
            return;

        this.id = model.id;
        this.receitaId = model.receitaId;
        this.receita = model.receita;
        this.centroCustoId = model.centroCustoId;
        this.centroCusto = model.centroCusto;
        this.contaGerencialId = model.contaGerencialId;
        this.contaGerencial = model.contaGerencial;
        this.valor = model.valor;
        this.percentual = model.percentual;
    }
}