
import { Component, Watch, Prop } from 'vue-property-decorator'
import { PageBase } from '@/core/models/shared';
import { AlertQuestion } from '@/core/services/shared/AlertService';
import { DespesaBaixa } from '@/core/models/financeiro';

@Component
export default class CadastroDespesaParcela extends PageBase {    
    @Prop() private value!: string;
    @Prop() items!: DespesaBaixa[];
    @Prop() private selected!: DespesaBaixa[];

    dialog: boolean = false;        
    localSelected: DespesaBaixa[] = [];

    header: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Conta Bancária', value:'contaCorrente.descricao' },
        { text: 'Data Pagamento', value:'dataPagamento' },
        { text: 'Valor', value:'valor' },
        { text: 'Desconto', value:'desconto' },
        { text: 'Juros', value:'juros' },
        { text: 'Multa', value:'multa' },
        { text: 'Total', value:'total' }
    ]
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.localSelected = [];
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        
    }

    Selecionar() {
        const context = this;
        const selecionar = function () {
            return new Promise( async function (resolve, reject){
                context.$emit('update:selected', context.localSelected);
                if (context.localSelected.length > 0){
                    context.$emit('selecionou');
                }                
            });
        }        
        AlertQuestion("Aviso!", "Tem certeza que deseja selecionar as baixas atuais ?", selecionar);
    }    

    Close(){
        this.dialog = false;
    }
}
