
import { Component, Prop, Watch } from "vue-property-decorator";
import { CrudBase } from '@/core/models/shared';
import { LocalOperacao, LocalOperacaoPeriodo } from "@/core/models/geral";
import { AlertExcludeQuestion, AlertSimple } from "@/core/services/shared/AlertService";

@Component
export default class CadastroPortoLocalOperacao extends CrudBase{
    @Prop() item!: LocalOperacao;
    @Prop() private value!: string;    

    $refs!: {
        form: HTMLFormElement,
        formPeriodo: HTMLFormElement
    }

    validPeriodo: boolean = true;
    periodo: LocalOperacaoPeriodo = new LocalOperacaoPeriodo();
    periodoHeaders: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Tempo (Horas)', value: 'tempo' },
    ];

    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    AdicionarPeriodo(){
        if(this.$refs.formPeriodo.validate()){
            this.periodo.localOperacaoId = this.item.id;
            this.item.periodos.push(this.periodo);
            this.periodo = new LocalOperacaoPeriodo();
            if (this.$refs.formPeriodo) {
                this.$refs.formPeriodo.resetValidation();
            }
        }
    }

    ExcluirPeriodo(item: LocalOperacaoPeriodo){
        const context = this;
        const excluir = function () {
        return new Promise( async function (resolve, reject){
            const index = context.item.periodos.indexOf(item);
            context.item.periodos.splice(index, 1);
        });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {

            if(!this.item.calcularPeriodoOperanteInoperante && this.item.periodos.length == 0){
                AlertSimple("Aviso!", "Não é possível salvar com o período de operação em branco!", "warning");
                return;
            }

            this.$emit("salvou");
            this.Close();
        }
    }    

    Close() {
        this.dialog = false;
    }
}   

