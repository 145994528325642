import { CategoriaVeiculo, TipoRodado } from "../transporte";
import { ModeloSelecao } from "./ModeloSelecao";
import { ModeloSelecaoTipo } from "./ModeloSelecaoTipo";
import { TipoDocumento } from "./TipoDocumento";

export class ModeloSelecaoVeiculo implements Shared.IEntity {

    id : number = 0;
    nome: string = "";
    entidadeId: number = 0;
    entidade: string = "";
    modeloSelecao!: ModeloSelecao;
    modeloSelecaoId?: string;
    categoriaVeiculo!: CategoriaVeiculo;
    categoriaVeiculoId: number = 0;
    tiposDocumentos : ModeloSelecaoTipo[] = [];

    constructor (model? : ModeloSelecaoVeiculo) {
        if (!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.entidadeId = model.entidadeId;
        this.entidade = model.entidade;
        this.modeloSelecao = model.modeloSelecao;
        this.categoriaVeiculo = model.categoriaVeiculo;
        this.categoriaVeiculoId = model.categoriaVeiculoId;
        this.tiposDocumentos = model.tiposDocumentos;
    }
}