import { SituacaoContrato, ModalidadeFrete, ContratoItem, ContratoPessoaEndereco } from ".";
import { CondicaoPagamento, FormaPagamento } from "../financeiro";
import { Pessoa, PessoaEndereco } from "../geral";

export class Contrato implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    dataHora: string = new Date().toYYYYMMDD();
    isPorQuantidade: boolean = true;
    descricao: string = "";
    clienteId: number = 0;
    cliente!: Pessoa;
    enderecoCobrancaId?: number;
    enderecoCobranca!: PessoaEndereco;
    vendedorId?: number;
    vendedor!: Pessoa;
    aliquotaComissao: number = 0;
    observacao: string = "";
    observacaoDFe: string = "";
    formaPagamentoId: number = 0;
    formaPagamento!: FormaPagamento;
    condicaoPagamentoId: number = 0;
    condicaoPagamento!: CondicaoPagamento;
    valor?: number;
    juntarFrete: boolean = false;
    freteInvisivel: boolean = false;
    modalidadeFreteId: number = 9;
    modalidadeFrete!: ModalidadeFrete;
    situacaoId: number = 1;
    situacao!: SituacaoContrato;
    fretePadrao: number = 0;

    valorEntregue?: number;

    enderecoEntregas: ContratoPessoaEndereco[] = [];
    itens: ContratoItem[] = [];
    
    constructor(model?: Contrato) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.dataHora = model.dataHora;
        this.isPorQuantidade = model.isPorQuantidade;
        this.descricao = model.descricao;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.enderecoCobrancaId = model.enderecoCobrancaId;
        this.enderecoCobranca = model.enderecoCobranca;
        this.vendedorId = model.vendedorId;
        this.vendedor = model.vendedor;
        this.aliquotaComissao = model.aliquotaComissao;
        this.observacao = model.observacao;
        this.observacaoDFe = model.observacaoDFe;
        this.formaPagamentoId = model.formaPagamentoId;
        this.formaPagamento = model.formaPagamento;
        this.condicaoPagamentoId = model.condicaoPagamentoId;
        this.condicaoPagamento = model.condicaoPagamento;
        this.valor = model.valor;
        this.juntarFrete = model.juntarFrete;
        this.freteInvisivel = model.freteInvisivel;
        this.modalidadeFreteId = model.modalidadeFreteId;
        this.modalidadeFrete = model.modalidadeFrete;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;

        this.valorEntregue = model.valorEntregue;

        this.enderecoEntregas = model.enderecoEntregas;
        this.itens = model.itens;
    }
}