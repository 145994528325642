import { LocalOperacao, Porto } from "../geral";

export class LocalOperacaoPeriodo implements Shared.IEntity {
    
    id: number = 0;
    localOperacaoId: number = 0;
    localOperacao!: LocalOperacao;
    tempo: number = 0;

    constructor (model? : LocalOperacaoPeriodo) {
        
        if (!model)
            return;

        this.id = model.id;
        this.localOperacaoId = model.localOperacaoId;
        this.localOperacao = model.localOperacao;
        this.tempo = model.tempo;
    }
}