
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { MDFe } from '@/core/models/dfe/MDFe';
import { MDFeService } from '@/core/services/dfe/MDFeService';
import { DFeService } from '@/core/services/dfe';
import { EnderecoService } from '@/core/services/finder';
import { PessoaService } from '@/core/services/geral';
import { Pessoa } from '@/core/models/geral';
import { MDFeContratante } from '@/core/models/dfe/MDFeContratante';
import { MDFeCondutor } from '@/core/models/dfe/MDFeCondutor';
import { MDFeCarregamento } from '@/core/models/dfe/MDFeCarregamento';
import { MDFeDescarregamento } from '@/core/models/dfe/MDFeDescarregamento';
import { Veiculo } from '@/core/models/transporte';
import { VeiculoService } from '@/core/services/transporte';
import { MDFeSeguro } from '@/core/models/dfe/MDFeSeguro';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';


@Component
export default class CadastroMDFe extends CrudBase{
    @Prop() item!: MDFe;
    @Prop() private value!: string;

    panel = [0,1,2,3,4,5,6,7]

    $refs!: {
        form: HTMLFormElement,
        formContratante: HTMLFormElement,
        formCondutor: HTMLFormElement,
        formCarga: HTMLFormElement,
        formCarregamento: HTMLFormElement,
        formDescarregamento: HTMLFormElement,
        formSeguro: HTMLFormElement,
        file: HTMLFormElement
    }

    headerContratantes: any[] = [
        { text: '', value: 'actions', class: 'action', sortable:  false },
        { text: 'Contratante', value: 'contratante.cpfCnpjNome', sortable:  false },
    ]

    headerCondutores: any[] = [
        { text: '', value: 'actions', class: 'action', sortable:  false },
        { text: 'Condutor', value: 'condutor.cpfCnpjNome', sortable:  false },
    ]

    headerCarregamentos: any[] = [
        { text: '', value: 'actions', class: 'action', sortable:  false },
        { text: 'Município', value: 'municipio.nome', sortable:  false },
    ]

    headerDescarregamentos: any[] = [
    { text: '', value: 'actions', class: 'action', sortable:  false },
        { text: 'Município', value: 'municipio.nome', sortable:  false },
        { text: 'Chaves CT-e/NF-e', value: 'chaves', sortable: false }
    ]

    headerSeguros: any[] = [
        { text: '', value: 'actions', class: 'action', sortable:  false },
        { text: 'Tipo Responsável', value: 'responsavelSeguroId', sortable:  false },
        { text: 'Responsável', value: 'responsavel.cpfCnpjNome', sortable: false },
        { text: 'Seguradora', value: 'seguradora.cpfCnpjNome', sortable: false },
        { text: 'Apólice', value: 'numeroApolice', sortable: false },
        { text: 'Averbação', value: 'numeroAverbacao', sortable: false }
    ]

    dfeService: DFeService = new DFeService();
    itemOriginal!: MDFe;
    service: MDFeService = new MDFeService();

    validContratante: boolean = true;
    validCondutor: boolean = true;
    validCarga: boolean = true;
    validCarregamento: boolean = true;
    validDescarregamento: boolean = true;
    validSeguro: boolean = true;

    enderecoService: EnderecoService = new EnderecoService();

    tipoTransportadores: any[] = [];
    tipoEmitentes: any[] = [];
    unidadesMedida: any[] = [];
    responsaveisSeguro: any[] = [];

    MDFeContratante: MDFeContratante = new MDFeContratante();
    contratantes: MDFeContratante[] = [];
    MDFeCondutor: MDFeCondutor = new MDFeCondutor();
    condutores: MDFeCondutor[] = [];

    MDFeSeguro: MDFeSeguro = new MDFeSeguro();
    seguros: MDFeSeguro[] = [];
    responsaveis: Pessoa[] = [];
    seguradoras: Pessoa[] = [];

    veiculos: Veiculo[] = [];
    isVeiculoLoading: boolean = false;
    onSearchVeiculo: any = null;
    veiculoService: VeiculoService = new VeiculoService();

    estados: Estado[] = [];
    estadoId: number = 0;
    municipiosCarregamento: Municipio[] = [];
    municipiosDescarregamento: Municipio[] = [];
    carregamento: MDFeCarregamento = new MDFeCarregamento();
    descarregamento: MDFeDescarregamento = new MDFeDescarregamento();

    clienteService: PessoaService = new PessoaService();
    onSearchCliente: any = null;
    clientes: Pessoa[] = [];
    isClienteLoading: boolean = false;
    

    responsavelService: PessoaService = new PessoaService();
    seguradoraService: PessoaService = new PessoaService();
    onSearchResponsavel: any = null;
    isResponsavelLoading: boolean = false;
    onSearchSeguradora: any = null;
    isSeguradoraLoading: boolean = false;

    transportadorService: PessoaService = new PessoaService();
    onSearchTransportador: any = null;
    transportadores: Pessoa[] = [];
    isTransportadorLoading: boolean = false;

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
        this.Carregar();
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if(this.item.veiculoId)
                this.veiculos.push(this.item.veiculo);
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    CarregarMunicipioCarregamento(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipiosCarregamento = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    @Watch("item.ufCarregamentoId")
    onUfCarregamentoIdChanged(value: number) {
        this.CarregarMunicipioCarregamento(value);
    }

    CarregarMunicipioDescarregamento(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipiosDescarregamento = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    @Watch("item.ufDescarregamentoId")
    onUfDescarregamentoIdChanged(value: number) {
        this.CarregarMunicipioDescarregamento(value);
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.MDFeContratante.contratanteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isClienteLoading = false;
        });
    }

    @Watch('onSearchResponsavel')
    searchResponsavel (val: string) {
        if (this.MDFeSeguro.responsavelId) return;
        if (this.isResponsavelLoading) return;
        if (!val) return;
        this.isResponsavelLoading = true
        this.responsavelService.AutoComplete(val).then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isResponsavelLoading = false;
        });
    }

    @Watch('onSearchSeguradora')
    searchSeguradora (val: string) {
        if (this.MDFeSeguro.seguradoraId) return;
        if (this.isSeguradoraLoading) return;
        if (!val) return;
        this.isSeguradoraLoading = true
        this.seguradoraService.AutoComplete(val).then(
            res => {
                this.seguradoras = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isSeguradoraLoading = false;
        });
    }


    @Watch('onSearchTransportador')
    searchTransportador (val: string) {
        if (this.MDFeCondutor.condutorId) return;
        if (this.isTransportadorLoading) return;
        if (!val) return;
        this.isTransportadorLoading = true
        this.transportadorService.AutoComplete(val, EnumPessoaNatureza.Transportador).then(
            res => {
                this.transportadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isTransportadorLoading = false;
        });
    }

    @Watch('item.veiculoId')
    watchVeiculo(){
        if(this.item.veiculoId){
            let veiculo = this.veiculos.find(x => x.id == this.item.veiculoId)!;
            this.item.veiculo.placa = veiculo.placa;
            this.item.veiculo.estadoId = veiculo.estadoId;
        }
    }

    @Watch('onSearchVeiculo')
    searchVeiculo (val: string) {
        if (this.item.veiculoId) return;
        if (this.isVeiculoLoading) return;
        if (!val) return;
        this.isVeiculoLoading = true
        this.veiculoService.AutoComplete(val).then(
            res => {
                this.veiculos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isVeiculoLoading = false));
    }

    AdicionarContratante(){
        if (this.$refs.formContratante.validate()) {
            if (!this.MDFeContratante.contratanteId) {
                AlertSimple("Aviso!", "Selecione um contratante!", "warning");
            }
            else if (!this.item.contratantes.find(x => x.contratanteId == this.MDFeContratante.contratanteId)){
                this.MDFeContratante.MDFeId = this.item.id;
                this.MDFeContratante.contratante = this.clientes.find(x => x.id == this.MDFeContratante.contratanteId)!;
                this.item.contratantes.push(this.MDFeContratante);
            }
            else{
                AlertSimple("Aviso!", "O item que selecionou já se encontra inserido!", "warning");
            }
            this.MDFeContratante = new MDFeContratante();
        }
    }

    ExcluirContratante(item: MDFeContratante){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.contratantes.indexOf(item)
                context.item.contratantes.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarCondutor(){
        if (this.$refs.formCondutor.validate()) {
            if (!this.MDFeCondutor.condutorId) {
                AlertSimple("Aviso!", "Selecione um condutor!", "warning");
            }
            else if (!this.item.condutores.find(x => x.condutorId == this.MDFeCondutor.condutorId)){
                this.MDFeCondutor.MDFeId = this.item.id;
                this.MDFeCondutor.condutor = this.transportadores.find(x => x.id == this.MDFeCondutor.condutorId)!;
                this.item.condutores.push(this.MDFeCondutor);
            }
            else{
                AlertSimple("Aviso!", "O item que selecionou já se encontra inserido!", "warning");
            }
            this.MDFeCondutor = new MDFeCondutor();
        }
    }

    ExcluirCondutor(item: MDFeCondutor){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.condutores.indexOf(item)
                context.item.condutores.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarCarregamento(){
        if (this.$refs.formCarregamento.validate()) {
            if (this.item.ufCarregamentoId == null) {
                AlertSimple("Aviso!", "Selecione um município!", "warning");
            }
            else if (!this.item.carregamentos.find(x => x.municipioId == this.carregamento.municipioId)){
                this.carregamento.MDFeId = this.item.id;
                this.carregamento.municipio = this.municipiosCarregamento.find(x => x.id == this.carregamento.municipioId)!;
                this.item.carregamentos.push(this.carregamento);
            }
            else{
                AlertSimple("Aviso!", "O item que selecionou já se encontra inserido!", "warning");
            }
            this.carregamento = new MDFeCarregamento();
        }
    }

    ExcluirCarregamento(item: MDFeCarregamento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.carregamentos.indexOf(item)
                context.item.carregamentos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarDescarregamento(){
        if (this.$refs.formDescarregamento.validate()) {
            if (this.item.ufDescarregamentoId == null) {
                AlertSimple("Aviso!", "Selecione um município", "warning");
            }
            else if (!this.item.descarregamentos.find(x => x.municipioId == this.descarregamento.municipioId)){
                this.descarregamento.MDFeId = this.item.id;
                this.descarregamento.municipio = this.municipiosDescarregamento.find(x => x.id == this.descarregamento.municipioId)!;
                this.item.descarregamentos.push(this.descarregamento);
            }
            else{
                AlertSimple("Aviso!", "O item que selecionou já se encontra inserido!", "warning");
            }
            this.descarregamento = new MDFeDescarregamento();
        }
    }

    ExcluirDescarregamento(item: MDFeDescarregamento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.descarregamentos.indexOf(item)
                context.item.descarregamentos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AdicionarSeguro(){
        if (this.$refs.formSeguro.validate()) {
            if (this.MDFeSeguro.responsavelId == 0 || this.MDFeSeguro.seguradoraId == 0) {
                AlertSimple("Aviso!", "Selecione um responsável e uma seguradora!", "warning")
                return;
            }
            if (this.item.seguros.find(x => x.responsavelId == this.MDFeSeguro.responsavelId)){
                AlertSimple("Aviso!", "O responsável que selecionou já se encontra inserido!", "warning");
                return;
                }
                this.MDFeSeguro.MDFeId = this.item.id;
                this.MDFeSeguro.responsavel = this.responsaveis.find(x => x.id == this.MDFeSeguro.responsavelId)!;
                this.MDFeSeguro.seguradora = this.seguradoras.find(x => x.id == this.MDFeSeguro.seguradoraId)!;
                this.item.seguros.push(this.MDFeSeguro);
                this.MDFeSeguro = new MDFeSeguro();  
        }
    }

    ExcluirSeguro(item: MDFeSeguro){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.seguros.indexOf(item)
                context.item.seguros.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }


    Carregar() {
        this.dfeService.GetTipoTransportador().then(
            res => {
                this.tipoTransportadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.dfeService.GetTipoEmitente().then(
            res => {
                this.tipoEmitentes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.dfeService.GetUnidadeMedida().then(
            res => {
                this.unidadesMedida = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.dfeService.GetResponsavelSeguro().then(
            res => {
                this.responsaveisSeguro = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.enderecoService.ObterEstados().then(
            res => this.estados = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    mounted() {

    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
