import { http, httpHeader } from "@/core/ApiConfig";
import { Parametro } from "@/core/models/estoque";

export class EstoqueService { 
    
    private _nomeControle: string = 'v1/estoque';
    
    //PARAMETRO
    public GetParametro() {
        return http.get(`${this._nomeControle}/parametro`, httpHeader);
    }

    public SaveParametro(model: Parametro) {
        return http.put(`${this._nomeControle}/parametro`, model);
    }
}