import { Funcao, Terno } from "../geral";
import { ModeloSelecao } from "./ModeloSelecao";
import { ModeloSelecaoTipo } from "./ModeloSelecaoTipo";
import { TipoDocumento } from "./TipoDocumento";

export class ModeloSelecaoPorto implements Shared.IEntity {

    id : number = 0;
    nome: string = "";
    entidadeId: number = 0;
    entidade: string = "";
    modeloSelecao!: ModeloSelecao;
    modeloSelecaoId?: string;
    terno!: Terno;
    ternoId: number = 0;
    tiposDocumento : ModeloSelecaoTipo[] = [];

    constructor (model? : ModeloSelecaoPorto) {
        if (!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.entidadeId = model.entidadeId;
        this.entidade = model.entidade;
        this.modeloSelecao = model.modeloSelecao;
        this.terno = model.terno;
        this.ternoId = model.ternoId;
        this.tiposDocumento = model.tiposDocumento;
    }
}