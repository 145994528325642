import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ContaCorrenteService extends Service {

    constructor() {
        super('v1/contaCorrente');
    }

    public AutoComplete(preCarregado: boolean, q?: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                preCarregado: preCarregado,
                q: q
            },
            headers: httpHeader.headers
        });
    }

    public Recalcular(contaCorrenteId: number) {
        return http.post(`${this.GetNomeControle()}/RecalcularSaldoAnterior/${contaCorrenteId}`);
    }

}