
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { OrdemServicoItem } from "@/core/models/engenhariaNaval";
import { OrdemServicoService } from "@/core/services/engenhariaNaval";
import { ListBase } from "@/core/models/shared";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";

@Component
export default class SelecionarOrdemServico extends ListBase{
    @Prop() private value!: boolean;
    @Prop() private selected!: OrdemServicoItem[];
    @Prop({required: true}) clienteId!: number;
    @Prop({required: true}) localOperacaoId!: number;
    @Prop({required: true}) embarcacaoId!: number;

    sheet: boolean = false;

    lista: OrdemServicoItem[] = [];
    localSelected: OrdemServicoItem[] = [];    
    service: OrdemServicoService = new OrdemServicoService();

    headers: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action', use: true },
        { text: '#', value:'ordemServico.numero', use: true },        
        { text: 'Cliente', value:'ordemServico.proposta.cliente.nome', use: true },
        { text: 'Data', value:'ordemServico.data', type:'date', use: true },
        { text: 'Situação', value:'ordemServico.situacao.nome', use: true },
        { text: 'Serviço', value:'propostaItem.produtoServico.codigoNome', use: true }
    ];

    filtro: any = {
        clienteId: null,
        localOperacaoId: null,
        embarcacaoId: null,
        ordemServicoId: null,
        servicoId: null,
        situacaoId: null,
        faturado: false
    }

    @Watch("value")
    Value(){
        if(this.value){
            this.localSelected = [];
            this.Carregar();
        }
    }

    Carregar(){
        this.filtro.clienteId = this.clienteId ? this.clienteId : null;
        this.filtro.localOperacaoId = this.localOperacaoId ? this.localOperacaoId : null;
        this.filtro.embarcacaoId = this.embarcacaoId ? this.embarcacaoId : null;

        this.service.ListarServicos(this.filtro.clienteId, this.filtro.ordemServicoId, this.filtro.servicoId, this.filtro.situacaoId, this.filtro.embarcacaoId, this.filtro.localOperacaoId, this.filtro.faturado).then(
            res => {
                this.lista = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Selecionar(){
        this.$emit('update:selected', this.localSelected);
        if (this.localSelected.length > 0){
            this.$emit('selecionou');
        }
        this.Close();
    }

    Close() {
        this.$emit("fechou");
    }
}
