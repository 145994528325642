import { ContratoItem } from "./";
import { Pedido } from "./Pedido";

export class PedidoItem implements Shared.IEntity {
    
    id: number = 0;
    pedido!: Pedido;
    contratoItemId: number = 0;
    contratoItem!: ContratoItem;
    quantidade: number = 0;
    entregue: number = 0;
    saldo: number = 0;
    
    constructor(model?: PedidoItem) {
        
        if (!model)
            return;

        this.id = model.id;
        this.contratoItemId = model.contratoItemId;
        this.pedido = model.pedido;
        this.contratoItem = model.contratoItem;
        this.quantidade = model.quantidade;
        this.entregue = model.entregue;
        this.saldo = model.saldo;
    }
}