
import { Component, Watch, Prop } from 'vue-property-decorator'
import { ModeloSelecaoFuncionarioModel, ModeloSelecaoModel, ModeloSelecaoVeiculoModel } from "@/core/models/documentos/model";
import { CrudBase } from "@/core/models/shared";
import { Pessoa } from '@/core/models/geral';
import { Veiculo } from '@/core/models/transporte';
import { ModeloSelecao } from '@/core/models/documentos';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { VeiculoService } from '@/core/services/transporte';
import { ModeloSelecaoService } from '@/core/services/documentos';
import { PessoaService } from '@/core/services/geral';

@Component
export default class CadastroPorModelo extends CrudBase{
    @Prop() private value!: string;

    item: ModeloSelecaoModel = new ModeloSelecaoModel();
    modeloSelecaoFuncionario: ModeloSelecaoFuncionarioModel = new ModeloSelecaoFuncionarioModel;
    modeloSelecaoVeiculo: ModeloSelecaoVeiculoModel = new ModeloSelecaoVeiculoModel;

    modeloSelecaoService: ModeloSelecaoService = new ModeloSelecaoService();

    headerFuncionario: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Nome Funcionário', value: 'funcionario.cpfCnpjNome', use: true },
        //{ text: 'Nome Seleção de Tipos', value: 'modeloSelecaoFuncionario.nome', use: true}
    ];
    headerVeiculo: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Nome Veículo', value: 'veiculo.placa', use: true },
        //{ text: 'Nome Seleção de Tipos', value: 'modeloSelecaoVeiculo.nome', use: true}
    ];

    funcionarios: Pessoa[] = [];
    onSearchFuncionario: any = null;
    isFuncionarioLoading: boolean = false;
    funcionarioService: PessoaService = new PessoaService();

    veiculos: Veiculo[] = [];
    onSearchVeiculo: any = null;
    isVeiculoLoading: boolean = false;
    veiculoService: VeiculoService = new VeiculoService();

    modelosSelecao: ModeloSelecao[] = [];
    modeloSelecaoId: string = "";
    modeloSelecao: ModeloSelecao = new ModeloSelecao();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.item = new ModeloSelecaoModel();
            this.modeloSelecao = new ModeloSelecao();
            this.modeloSelecaoId = "";
            this.$emit("fechou");
        }
    }

    mounted() {
        this.Carregar();
    }

    @Watch('item.modeloSelecaoId')
    adicionaModeloSelecao() {
        if (!!this.item.modeloSelecaoId) {
            this.modeloSelecao = this.modelosSelecao.find(x => x.id == this.item.modeloSelecaoId)!;
        }
    }

    @Watch('onSearchFuncionario')
    searchFuncionario (val: string) {
        if (this.modeloSelecaoFuncionario.funcionarioId) return;
        if (this.isFuncionarioLoading) return;
        if (!val) return;

        this.isFuncionarioLoading = true;
        this.funcionarioService.AutoComplete(val, EnumPessoaNatureza.Funcionario, ).then(
            res => {
                this.funcionarios = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isFuncionarioLoading = false));
    }

    @Watch('onSearchVeiculo')
    searchVeiculo (val: string) {
        if (this.modeloSelecaoVeiculo.veiculoId) return;
        if (this.isVeiculoLoading) return;
        if (!val) return;
        this.isVeiculoLoading = true
        this.veiculoService.AutoComplete(val).then(
            res => {
                this.veiculos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isVeiculoLoading = false));
    }

    AdicionarModeloSelecaoFuncionario() {
        if (this.modeloSelecaoFuncionario.funcionarioId == 0) {
            return AlertSimple("Aviso!", "Selecione um Funcionário!", "warning")
        }
        this.modeloSelecaoFuncionario.funcionario = this.funcionarios.find(x => x.id == this.modeloSelecaoFuncionario.funcionarioId)!;
        //this.modeloSelecaoFuncionario.modeloSelecaoFuncionario = this.modeloSelecao.selecoesFuncionario.find(x => x.id == this.modeloSelecaoFuncionario.modeloSelecaoFuncionarioId)!;
        this.item.funcionariosSelecionados.push(this.modeloSelecaoFuncionario);
        this.item.descricao = this.modeloSelecao.descricao;
        this.modeloSelecaoFuncionario = new ModeloSelecaoFuncionarioModel();
    }

    AdicionarModeloSelecaoVeiculo() {
        if (this.modeloSelecaoVeiculo.veiculoId == 0) {
            return AlertSimple("Aviso!", "Selecione um Veículo!", "warning")
        }
        this.modeloSelecaoVeiculo.veiculo = this.veiculos.find(x => x.id == this.modeloSelecaoVeiculo.veiculoId)!;
        //this.modeloSelecaoVeiculo.modeloSelecaoVeiculo = this.modeloSelecao.selecoesVeiculo.find(x => x.id == this.modeloSelecaoVeiculo.modeloSelecaoVeiculoId)!;
        this.item.veiculosSelecionados.push(this.modeloSelecaoVeiculo);
        this.item.descricao = this.modeloSelecao.descricao;
        this.modeloSelecaoVeiculo = new ModeloSelecaoVeiculoModel();
    }

    ExcluirFuncionarioSelecionado(item: any){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.funcionariosSelecionados.indexOf(item);
                context.item.funcionariosSelecionados.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    ExcluirVeiculoSelecionado(item: any){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.veiculosSelecionados.indexOf(item);
                context.item.veiculosSelecionados.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Carregar() {
        this.loading = true;
        this.modeloSelecaoService.ListarTudo("SelecoesFuncionario.TiposDocumentos.TipoDocumento, SelecoesFuncionario.Funcao, SelecoesVeiculo.TiposDocumentos.TipoDocumento, SelecoesVeiculo.CategoriaVeiculo").then(
            res => {
                this.modelosSelecao = res.data.items;
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        ).finally(() => this.loading = false)
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.item.modeloSelecao = this.modelosSelecao.find(x => x.id == this.item.modeloSelecaoId); 
            if (this.item.funcionariosSelecionados.length == 0 && this.item.veiculosSelecionados.length == 0) {
                return AlertSimple("Aviso!", "Selecione pelo menos um modelo!", "warning")
            }
            this.modeloSelecaoService.CriarSelecaoPorModelo(this.item).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.item = new ModeloSelecaoModel();
                    this.modeloSelecao = new ModeloSelecao();
                    this.modeloSelecaoId = "";
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou() {
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
}
