
import { Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { AgendamentoEquipamento } from '@/core/models/operacaoportuaria';
import { Produto } from '@/core/models/estoque';
import { ProdutoService } from '@/core/services/estoque';

@Component
export default class CadastroAgendamentoEquipamento extends CrudBase{
    @Prop() item!: AgendamentoEquipamento;
    @Prop() private value!: string;

    validDialog: boolean = true;

    equipamentos: Produto[] = [];
    equipamentoService: ProdutoService = new ProdutoService();

    $refs!: {
        form: HTMLFormElement
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.equipamento = this.equipamentos.find(x => x.id == this.item.equipamentoId)!;
            this.Salvou();
        }
    }
    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;

        if(this.dialog){
            this.Carregar();
        }
    }


    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Carregar(){
        // this.equipamentoService.AutoComplete(true, "Categoria").then(
        //     res => {
        //         this.equipamentos = res.data.items;
        //     },
        //     err => AlertSimpleErr("Aviso!", err)
        // )
    }
    
    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
