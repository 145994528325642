
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Funcao, Pessoa } from '@/core/models/geral';
import { FuncaoService, PessoaService } from '@/core/services/geral';
import { RegistroOperacaoEquipamento, RegistroOperacaoEquipamentoDocumento } from '@/core/models/operacaoportuaria';
import { RegistroOperacaoService } from '@/core/services/operacaoportuaria';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { DocumentoService } from '@/core/services/documento';

@Component
export default class CadastroControleOrdemServico extends CrudBase{
    @Prop() item!: RegistroOperacaoEquipamento;
    @Prop() private value!: string;

    itemOriginal!: RegistroOperacaoEquipamento;
    service: RegistroOperacaoService = new RegistroOperacaoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    fornecedorService: PessoaService = new PessoaService();
    fornecedores: Pessoa[] = [];
    isFornecedorLoading: boolean = false;
    onSearchFornecedor: any = null;

    documentos: RegistroOperacaoEquipamentoDocumento[] = [];
    dialogEquipamentoDocumento: boolean = false;
    equipamentoDocumento: RegistroOperacaoEquipamentoDocumento = new RegistroOperacaoEquipamentoDocumento()
    headerDocumentos: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Descrição', value: 'documento.identificacao' },
        { text: 'Arquivo', value: 'documento.arquivo.nome' }
    ]

    itemExcluido(item: any){
        return item.excluir ? 'itemExcluido' : ''
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            //this.ListarDocumentos(this.item.id);
        }
        else {
            this.$emit("fechou");
        }
        if(this.item.fornecedorId){
            this.fornecedores.push(this.item.fornecedor);
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('onSearchFornecedor')
    searchFornecedor (val: string) {
        if (this.item.fornecedorId) return;
        if (this.isFornecedorLoading) return;
        if (!val) return;
        this.isFornecedorLoading = true
        this.fornecedorService.AutoComplete(val, EnumPessoaNatureza.Fornecedor).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isFornecedorLoading = false));
    }

    VerificarDatas(){
        if(!this.item.dataHoraInicio || !this.item.dataHoraTermino)
            return  
        if(this.item.dataHoraTermino < this.item.dataHoraInicio){
            AlertSimple('Atenção!','A data de término do Equipamento/Serviço informada é menor que a data de início do Equipamento/Serviço','warning');
        }
    }

    ListarDocumentos(equipamentoId: number) {
        this.service.ListarEquipamentoDocumento(equipamentoId).then(
            res => {
                this.documentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogDocumento(item?: RegistroOperacaoEquipamentoDocumento){
        if(item){
            console.log("Não implementado")
        }
        else{
            this.equipamentoDocumento = new RegistroOperacaoEquipamentoDocumento();
            this.equipamentoDocumento.equipamentoId = this.item.id;
            //this.equipamentoDocumento.documento.empresaId = this.app.empresaId;
            this.dialogEquipamentoDocumento = true;
        }
    }
    AdicionarDocumento(){
        //this.equipamentoDocumento.documento.nome = this.equipamentoDocumento.documento.arquivo.nome;
        this.documentos.push(this.equipamentoDocumento);
        this.equipamentoDocumento = new RegistroOperacaoEquipamentoDocumento();
        this.dialogEquipamentoDocumento = false;
    }    
    BaixarDocumento(item: RegistroOperacaoEquipamentoDocumento){
        new DocumentoService().Ler(item.documentoId, item.documento.nome);
    }
    ExcluirDocumento(item: RegistroOperacaoEquipamentoDocumento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.documentos.indexOf(item);
                if(item.id > 0){
                    context.documentos[index].excluir = true;
                }
                else{
                    context.documentos.splice(index, 1);
                }
                context.dialog = false;
                context.dialog = true;
            });
        }
        AlertExcludeQuestion(excluir, true);
    }
    SalvarDocumentos(equipamentoId?: number){
        this.documentos.forEach(documento => {
            if(!(documento.id > 0)){
                return new Promise(async () => {
                    
                    if(equipamentoId)
                        documento.equipamentoId = equipamentoId;
                    
                    let arquivo = await this.LerArquivo(documento.documento.arquivo);
                    this.service.EnviarEquipamentoDocumento(documento, arquivo).then(
                        res => {},
                        err => AlertSimpleErr("Aviso!", err)
                    )
                });
            }
            else{
                if(documento.excluir){
                    this.service.DeletarEquipamentoDocumento(documento.id).then(
                        res => {},
                        err => AlertSimpleErr("Aviso!", err)
                    )
                }
            }
        });
        
        AlertSimple("Aviso", "Operação realizada com sucesso!", "success");
        this.$emit("salvou");
        this.Close();
    }
    LerArquivo(arquivo: any){
        return new Promise((resolve, reject) => {
            let fr = new FileReader();
        
            fr.onload = (arquivo) => {
                resolve(arquivo.target!.result);
            };
        
            fr.readAsArrayBuffer(arquivo);
        });
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            this.service.EquipamentoSalvar(this.item.id, patchModel).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Close(){
        this.dialog = false;
    }
}
