import { http } from '@/core/ApiConfig';

export class GeralEnumeradoService{

   private _nomeControle: string = 'v1/enumerado/geral';

    ListarTipoPagamento(){
        return http.get(`${this._nomeControle}/funcionario/tipoPagamento`);
    }

    ListarTipoCertidao(){
        return http.get(`${this._nomeControle}/funcionario/tipoCertidao`);
    }

    ListarGrauEscolaridade(){
        return http.get(`${this._nomeControle}/funcionario/grauEscolaridade`);
    }

    ListarDeficiencia(){
        return http.get(`${this._nomeControle}/funcionario/deficiencia`);
    }

    ListarNacionalidade(){
        return http.get(`${this._nomeControle}/funcionario/nacionalidade`);
    }

    ListarParentesco(){
        return http.get(`${this._nomeControle}/funcionario/parentesco`);
    }

    ListarEstadoCivil(){
        return http.get(`${this._nomeControle}/funcionario/estadoCivil`);
    }

    ListarRacaCor(){
        return http.get(`${this._nomeControle}/funcionario/racaCor`);
    }

}