import { ContaCorrente, Despesa, DespesaBaixa, SituacaoDespesaParcela } from ".";
import { Arquivo, Operacao } from "../shared";
import { Cobranca } from "./shared/Cobranca";

export class DespesaParcela implements Shared.IEntity {
    
    id: number = 0;
    despesaId: number = 0;
    despesa!: Despesa;
    parcela: number = 0;
    parcelaStr: string = '';
    parcelaTotal: number = 0;
    dataVencimento: string = '';
    valor: number = 0;
    codigoBarras: string = '';
    faltaBoleto: boolean = false;
    saldo: number = 0;
    valorPago: number = 0;
    situacaoId: number = 0;
    situacao!: SituacaoDespesaParcela;
    cancelamento!: Operacao;
    contaCorrenteId?: number;
    contaCorrente!: ContaCorrente;
    agrupadorId?: number;
    agrupador!: DespesaParcela;
    agrupado: boolean = false;
    cobranca!: Cobranca;
    observacao: string = "";

    baixas: DespesaBaixa[] = [];

    //USADO PARA REALIZAR A BAIXA DIRETO DO LANCAMENTO DA PARCELA
    gerarBaixa: boolean = false;
    baixaContaCorrenteId?: number;
    baixaFormaPagamentoId?: number;
    baixaDataPagamento?: string;
    baixaDesconto?: number;
    baixaMulta?: number;
    baixaJuros?: number;
    baixaComprovante?: Arquivo;
    baixaComprovanteDados?: any;

    constructor(model?: DespesaParcela) {
        
        if (!model)
            return;

        this.id = model.id;
        this.despesaId = model.despesaId;
        this.despesa = model.despesa;
        this.parcela = model.parcela;
        this.parcelaStr = model.parcelaStr;
        this.parcelaTotal = model.parcelaTotal;
        this.dataVencimento = model.dataVencimento;
        this.valor = model.valor;
        this.codigoBarras = model.codigoBarras;
        this.saldo = model.saldo;
        this.situacaoId = model.situacaoId;
        this.situacao = model.situacao;
        this.cancelamento = model.cancelamento;
        this.contaCorrenteId = model.contaCorrenteId;
        this.contaCorrente = model.contaCorrente;
        this.agrupadorId = model.agrupadorId;
        this.agrupador = model.agrupador;
        this.agrupado = model.agrupado;
        this.observacao = model.observacao;
        
        this.baixas = model.baixas;
    }
}