import { Venda } from ".";
import { RegraFiscal } from "../dfe";
import { Produto } from "../estoque";

export class VendaItem implements Shared.IEntity {
    
    id: number = 0;
    vendaId: number = 0;
    venda!: Venda;
    produtoId: number = 0;
    produto!: Produto;
    regraFiscalId?: number;
    regraFiscal!: RegraFiscal;
    quantidade: number = 1;
    quantidadeKg: number = 0;
    valorUnitario: number = 0;
    desconto: number = 0;
    frete: number = 0;
    seguro: number = 0;
    outrasDespesas: number = 0;
    valorTotal: number = 0;

    public SetFrete(valorFrete: number){
        this.frete = valorFrete * this.quantidade;
    }

    public SetValorTotal(){
        this.valorTotal = (this.quantidade * this.valorUnitario) - this.desconto + this.outrasDespesas + this.seguro + this.frete;
    }

    public GetValorTotal(){
        return (this.quantidade * this.valorUnitario) - this.desconto + this.outrasDespesas + this.seguro + this.frete;
    }
    
    public AtualizarQuantidade(pesoVazio: number){
        this.quantidade = (this.quantidadeKg - pesoVazio) / 1000;
    }

    constructor(model?: VendaItem) {
        
        if (!model)
            return;

        this.id = model.id;
        this.vendaId = model.vendaId;
        this.produtoId = model.produtoId;
        this.regraFiscalId = model.regraFiscalId;
        this.quantidade = model.quantidade;
        this.quantidadeKg = model.quantidadeKg;
        this.valorUnitario = model.valorUnitario;
        this.desconto = model.desconto;
        this.frete = model.frete;
        this.seguro = model.seguro;
        this.outrasDespesas = model.outrasDespesas;
    }
}