import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class FluxoBancarioService extends Service {
    
    constructor() {
        super('v1/fluxobancario');
    }

    private GetFiltro(parametros: string, filter: any){

        if (filter) {
            let paramTemp = '';

            if(filter.conciliado != null){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `conciliado eq ${filter.conciliado}`;
            }
            
            if(filter.contaCorrenteId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `contaCorrenteId eq ${filter.contaCorrenteId}`;
            }

            if(filter.historico){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `Contains(historico, '${filter.historico}')`;
            }

            if(filter.identificacao){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `Contains(identificacao, '${filter.identificacao}')`;
            }

            if(filter.periodoInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataCompetencia ge ${filter.periodoInicial}`;
            }

            if(filter.periodoFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataCompetencia le ${filter.periodoFinal}`;
            }

            if(filter.valorInicial){
                if(paramTemp){ paramTemp += ' and '; }
                paramTemp += `valor ge ${filter.valorInicial}`
            }

            if(filter.valorFinal){
                if(paramTemp){ paramTemp += ' and '}
                paramTemp += `valor le ${filter.valorFinal}`
            }

            if (paramTemp) {
                if(parametros){
                    parametros += '&';
                }
                else {
                    parametros += '?';
                }
                parametros += `$filter=${paramTemp}`;
            }
        }

        return parametros;  
    }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    Conciliar(id: number){
        return http.put(`${this.GetNomeControle()}/Conciliar/${id}`);
    }

    Debitar(contaCorrenteId: number, historico: string, dataCompetencia: string, valor: number, identificacao: string, baixaDespesaId?: number, baixaReceitaId?: number){
        return http.post(`${this.GetNomeControle()}/Debitar`, undefined, {
            params: {
                contaCorrenteId,
                historico,
                dataCompetencia,
                valor,
                identificacao,
                baixaDespesaId,
                baixaReceitaId
            },
            headers: httpHeader.headers
        });
    }

    Creditar(contaCorrenteId: number, historico: string, dataCompetencia: string, valor: number, identificacao: string, baixaDespesaId?: number, baixaReceitaId?: number){
        return http.post(`${this.GetNomeControle()}/Creditar`, undefined, {
            params: {
                contaCorrenteId,
                historico,
                dataCompetencia,
                valor,
                identificacao,
                baixaDespesaId,
                baixaReceitaId
            },
            headers: httpHeader.headers
        });
    }

    Transferir(contaCorrenteOrigemId: number, contaCorrenteDestinoId: number, historico: string, identificacaoBancaria: string, dataCompetencia: string, valor: number){
        return http.post(`${this.GetNomeControle()}/Transferir`, undefined, {
            params: {
                contaCorrenteOrigemId,
                contaCorrenteDestinoId,
                historico,
                identificacaoBancaria,
                dataCompetencia,
                valor
            },
            headers: httpHeader.headers
        });
    }

    Saldos(contaCorrenteId: number, dataInicial: string, dataFinal: string){
        return http.get(`${this.GetNomeControle()}/Saldos`, {
            params: {
                contaCorrenteId,
                dataInicial,
                dataFinal
            }
        });
    }

    GravarComprovante(arquivo: any, id: number){
        let bodyFormData = new FormData();
        bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/pdf' }), arquivo.name);
        return http.post(`${this.GetNomeControle()}/${id}/GravarComprovante`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        })
    }

    DownloadComprovante(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/DownloadComprovante`, {
            responseType: 'arraybuffer'
        });
    }

    ObterPorId(id: number, include?: string){
        return http.get(`${this.GetNomeControle()}/${id}`,this.GetHeader(include));
    }

    GetRecibo(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/GetRecibo`);
    }

}