import { Pedido } from ".";
import { Produto } from "../estoque";

export class PedidoItem implements Shared.IEntity {
    
    id: number = 0;
    pedidoId: number = 0;
    pedido!: Pedido;
    produtoId: number = 0;
    produto!: Produto;
    quantidade: number = 0;
    entregue: number = 0;
    valor: number = 0;

    constructor(model?: PedidoItem) {
        
        if (!model)
            return;

        this.id = model.id;
        this.pedidoId = model.pedidoId;
        this.pedido = model.pedido;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.quantidade = model.quantidade;
        this.entregue = model.entregue;
        this.valor = model.valor;
    }
}