
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CentroCustoService, ContaCorrenteService, ContaGerencialService, FormaPagamentoService, ReceitaService, TipoReceitaService } from '@/core/services/financeiro';
import { CentroCusto, ContaCorrente, ContaGerencial, FormaPagamento, Receita, ReceitaClassificacao, ReceitaDocumento, ReceitaParcela, SituacaoReceitaParcela, TipoReceita } from '@/core/models/financeiro';
import { Arquivo, CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Pessoa } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { ArquivoService } from '@/core/services/shared';

@Component
export default class CadastroReceita extends CrudBase{
    @Prop() item!: Receita;
    @Prop({default: false}) venda!: boolean
    @Prop() private value!: string;
    
    validDocumento: boolean = true;

    itemOriginal!: Receita;
    service: ReceitaService = new ReceitaService();
    $refs!: {
        form: HTMLFormElement,
        formDocumento: HTMLFormElement,
        formClassificacao: HTMLFormElement
    }

    parcelas: ReceitaParcela[] = [];
    qtdParcelas: number = 1;
    inicioParcela: number = 1;
    vencimentoParcela: string = new Date().toYYYYMMDD();
    digitavel: string = '';

    contasCorrentes: ContaCorrente[] = [];
    contaCorrenteService: ContaCorrenteService = new ContaCorrenteService();

    formasPagamentos: FormaPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();

    totalSerDividido: number = 0;
    valorClassificacao: number = 0;
    percentualClassificacao: number = 0;

    baixasExpand: any[] = [0];
    
    centroCustoId: number = 0;
    centroCustos: any[] = [];
    centroCustoService: CentroCustoService = new CentroCustoService();

    contaGerencialId: number = 0;
    contaGerenciais: any[] = [];
    contaGerencialService: ContaGerencialService = new ContaGerencialService();

    pessoas: Pessoa[] = [];
    pessoaService: PessoaService = new PessoaService();
    onSearchPessoa: any = null;
    isPessoaLoading: boolean = false;

    //DOCUMENTOS
    documento: any = null;
    tipoDocumentoId: number = 1;
    tipoDocumentos: any[] = [
        { id: 1, nome: "Link" },
        { id: 2, nome: "Documento" }
    ];
    receitaDocumento: ReceitaDocumento = new ReceitaDocumento();    
    receitaDocumentos: ReceitaDocumento[] = [];
    headerReceitaDocumentos: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Documento', value: 'dado', sortable:  false }
    ]

    tipoReceitasService = new TipoReceitaService();
    tipoReceitas:TipoReceita[]=[];
    dialogReceitaParcela = false;
    editaReceitaParcela = false;
    receitaParcela: ReceitaParcela = new ReceitaParcela();
    headerParcela: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Parcela', value: 'parcela', sortable:  false },
        { text: 'Valor', value: 'valor', sortable:  false },
        { text: 'Vencimento', value: 'dataVencimento', sortable:  false },
        { text: 'Situação', value: 'situacao.nome', sortable:  false },
    ]

    headerParcelaBaixa: any[] = [
        { text: 'Parcela', value: 'parcela', sortable:  false },
        { text: 'Valor', value: 'valor', sortable:  false },
        { text: 'Vencimento', value: 'dataVencimento', sortable:  false },
        { text: 'Código de Barras', value: 'codigoBarras', sortable: false }
    ]
    
    receitaClassificacao: ReceitaClassificacao = new ReceitaClassificacao();
    headerClassificacoes: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Centro Custo', value: 'centroCusto.nome', sortable: false },
        { text: 'Conta Gerencial', value: 'contaGerencial.nome', sortable: false },
        { text: 'Valor', value: 'valor', sortable: false },
        { text: 'Percentual', value: 'percentual', sortable: false },
    ]
    
    dialogCliente: boolean = false;
    editaCliente: boolean = false;
    clienteId: number = 0;

    dialogCentroCusto: boolean = false;
    dialogContaGerencial: boolean = false;

    itemExcluido(item: any){
        if(item.excluir)
            return 'itemExcluido'
        else
            return ''
    }

    WatchTabs(){
        this.CalcularRestanteDivisao();
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            if(this.venda){
                this.parcelas = this.item.parcelas;
            }
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);            
            this.parcelas = this.item.parcelas;
            this.ListarReceitaDocumento(this.item.id);
        }
        
        this.CalcularRestanteDivisao();
        
        if(this.item.pessoaId > 0)
            this.pessoas.push(this.item.pessoa);

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formClassificacao) {
            this.$refs.formClassificacao.resetValidation();
        }
    }

    @Watch('item.valor')
    @Watch('qtdParcelas')
    WatchParcelas(){
        if(this.qtdParcelas < 1)
            this.qtdParcelas = 1;
    }

    @Watch("valorClassificacao")
    CorrecaoValor(){
        if(this.valorClassificacao < 0)
            this.valorClassificacao = 0;
    }

    @Watch("percentualClassificacao")
    CorrecaoPorcentagem(){
        if(this.percentualClassificacao > 100)
            this.percentualClassificacao = 100;
        else if(this.percentualClassificacao < 0)
            this.percentualClassificacao = 0;
    }    

    @Watch('onSearchPessoa')
    searchPessoa (val: string) {
        
        if (this.item.pessoaId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val).then(
            res => {
                this.pessoas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    DistribuirDiferencaEmClassificacao() {
        if (this.totalSerDividido == 0) {
            return AlertSimple("Aviso!", "Não existe diferença de valor para distribuir entre as classificações.", "warning");
        } else if (this.item.classificacoes.length == 0) {
            return AlertSimple("Aviso!", "Não existem classificações para distribuir a diferença de valor.", "warning")
        }
        this.service.DistribuirEmClassificacoes(this.item.classificacoes, this.item.valor).then(
            res => {
                this.item.classificacoes = res.data;
                this.CalcularRestanteDivisao();
                if (this.totalSerDividido != 0) {
                    this.item.classificacoes[0].valor = this.item.classificacoes[0].valor + this.totalSerDividido;
                    this.CalcularRestanteDivisao();
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        
    }

    @Watch("dialogContaGerencial")
    WatchDialogContaGerencial(){
        if(!this.dialogContaGerencial)
            this.AtualizarContaGerencial();
    }

    @Watch("dialogCentroCusto")
    WatchDialogCentroCusto(){
        if(!this.dialogCentroCusto)
            this.AtualizarCentroCusto();
    }

    @Watch('dialogCentroCusto')
    @Watch('dialogContaGerencial')
    WatchValoresClassificacao(){
        this.percentualClassificacao = parseFloat((this.valorClassificacao * 100 / this.item.valor).toFixed(3));
    }

    WatchPercentualClassificacao(){
        this.valorClassificacao = parseFloat((this.percentualClassificacao * this.item.valor / 100).toFixed(2));
    }

    CalcularRestanteDivisao(){
        if(this.item.classificacoes.length > 0){
            this.totalSerDividido = parseFloat(((this.item.valor - this.item.classificacoes.reduce((a,b) => a + b.valor, 0)).toFixed(3)));
        } else {
            this.totalSerDividido = this.item.valor;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Somar(campo: string){
        let valor = 0;
        this.parcelas.forEach(x => {            
            valor += x[campo];
        });
        return valor;
    }

    SomarClassificacao(campo: string){
        let valor = this.item.classificacoes.reduce((a, b) => a + b.valor, 0);
        return valor;
    }

    MudancaValorParcela() {
        this.item.valor = this.Somar('valor');
        this.CalcularRestanteDivisao()
        
    }

    VisualizarParcelas(salvar: boolean = false){
        this.service.VisualizarParcelas(this.item.valor, this.qtdParcelas, this.inicioParcela, this.vencimentoParcela, this.item.parcelas, salvar, this.item.id > 0 ? this.item.id : undefined).then(
            res => {
                this.parcelas = res.data;
                this.parcelas.forEach(parcela => {
                    parcela.situacao = new SituacaoReceitaParcela();
                    parcela.situacao.id = 1;
                    parcela.situacao.nome = "Aberto";                    
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogPessoa(){
        this.clienteId = 0;
        this.dialogCliente = true;
    }

    SalvarPessoa(id: any){
        this.pessoaService.ObterPorId(id).then(
            res => {
                this.item.pessoaId = id;
                this.pessoas.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AtualizarContaGerencial(){
        this.contaGerencialService.ListagemSimples('R').then(
            res => {
                this.contaGerenciais = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        )
    }

    AtualizarCentroCusto(){
        this.centroCustoService.Listagem().then(
            res => {
                this.centroCustos = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        )
    }

    Carregar() {
        this.tipoReceitasService.ListarTudo().then(
            res => {
                this.tipoReceitas = res.data.items;
                this.item.tipoId = this.tipoReceitas[0].id;
            },
            err => AlertSimpleErr("Aviso", err)
        )
        this.contaCorrenteService.AutoComplete(true).then(
            res => {
                this.contasCorrentes = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        )
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formasPagamentos = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        )
        this.AtualizarCentroCusto();
        this.AtualizarContaGerencial();
    }

    VerificacoesSalvamento(){
        if(!(this.item.classificacoes.length > 0)){
            AlertSimple("Aviso!", "Não é possível salvar uma receita sem as Classificações!", "warning");
            return false;
        }
        if(!(this.item.parcelas.length > 0)){
            AlertSimple("Aviso!", "Não é possível salvar uma receita sem parcelas!", "warning");
            return false;
        }
        if (parseFloat(this.totalSerDividido.toFixed(3)) != 0) {
            AlertSimple("Aviso!", "O valor da classificação deve ser preenchido corretamente com 100% do percentual atribuído!", "warning");
            return false;
        }

        return true
    }

    RemoverDocumento(){
        this.documento = null;
        this.item.documento = undefined;
    }

    ListarReceitaDocumento(id: number){
        this.service.ListarDocumentos(id).then(
            res => {
                this.receitaDocumentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarReceitaDocumento(){
        if(this.$refs.formDocumento.validate()){
            if(this.receitaDocumento.link){
                if(this.receitaDocumentos.find(x => x.link == this.receitaDocumento.link)){
                    AlertSimple("Aviso!", "Link já se encontra inserido!", "warning");
                    this.receitaDocumento = new ReceitaDocumento();
                    return;
                }
                this.receitaDocumento[`tipo`] = "Link";
            }
            else{
                this.receitaDocumento.arquivo = new Arquivo();
                this.receitaDocumento.arquivo.nome = this.receitaDocumento[`dados`].name;
                this.receitaDocumento.arquivo.tamanho = this.receitaDocumento[`dados`].size;
                this.receitaDocumento[`tipo`] = "Documento";
            }
            this.receitaDocumento.receitaId = this.item.id;
            this.receitaDocumentos.push(this.receitaDocumento);
            this.receitaDocumento = new ReceitaDocumento();
        }
    }

    ExcluirReceitaDocumento(item: ReceitaDocumento){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){                
                if(item.id > 0){
                    item.excluir = true;
                    context.dialog = false;
                    context.dialog = true;
                }
                else{
                    const index = context.receitaDocumentos.indexOf(item);
                    context.receitaDocumentos.splice(index, 1);
                }
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    DownloadDocumentos(documentoId: number){
        this.loading = true;
        this.service.DownloadDocumentos(this.item.id, documentoId).then(
            res => {
                this.service.PreviewArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    AdicionarParcelaBaixa(item: ReceitaParcela){
        if(this.item.id == 0){
            item.gerarBaixa = true;
            this.dialog = false;
            this.dialog = true;
        }
    }

    RetirarParcelaBaixa(item: ReceitaParcela){
        item.gerarBaixa = false;
        item.baixaContaCorrenteId = undefined;
        item.baixaFormaPagamentoId = undefined;
        item.baixaDataPagamento = undefined;
        item.baixaComprovante = undefined;
        item.baixaComprovanteDados = undefined;
        this.dialog = false;
        this.dialog = true;
    }

    AbrirDialogReceitaParcela(receitaParcela?: ReceitaParcela){
        if(receitaParcela){
            this.receitaParcela = receitaParcela;
            this.editaReceitaParcela = true;
        }
        else{
            this.receitaParcela = new ReceitaParcela();
            this.editaReceitaParcela = false;
        }
        this.dialogReceitaParcela = true;
    }

    async VincularDocumentoBaixa(item: ReceitaParcela){
        item.baixaComprovante = new Arquivo();
        item.baixaComprovante.nome = item[`baixaArquivo`][`name`];
        item.baixaComprovante.tamanho = item[`baixaArquivo`][`size`]/1024;
        
        const arquivoService = new ArquivoService();
        const dados = await arquivoService.Ler(item[`baixaArquivo`]);
        item.baixaComprovanteDados = dados.replace(/^[^,]*,/, "");
    }

    AdicionarReceitaClassificacao(){
        if (this.$refs.formClassificacao.validate()) {

            if(this.totalSerDividido < this.valorClassificacao){
                AlertSimple("Aviso!", "O valor informado é maior que o restante a ser dividido!", "warning");
                return;
            }

            if (this.percentualClassificacao + this.item.classificacoes.reduce((a, b) => a + b.percentual, 0) > 100) {
                return AlertSimple("Aviso!", "A soma do percentual das classificações não deve ultrapassar 100%!", "warning");
            }

            var item = new ReceitaClassificacao();
            item.receitaId = this.item.id;
            
            item.centroCustoId = this.centroCustoId;
            item.centroCusto = new CentroCusto();
            item.centroCusto.id = this.centroCustos.find(x => x.key == this.centroCustoId)!.key;
            item.centroCusto.nome = this.centroCustos.find(x => x.key == this.centroCustoId)!.value;

            item.contaGerencialId = this.contaGerencialId;
            item.contaGerencial = new ContaGerencial();
            item.contaGerencial.id = this.contaGerenciais.find(x => x.key == this.contaGerencialId)!.key;
            item.contaGerencial.nome = this.contaGerenciais.find(x => x.key == this.contaGerencialId)!.value;

            item.valor = this.valorClassificacao;
            item.percentual = this.percentualClassificacao;
            
            this.item.classificacoes.push(item);

            this.centroCustoId = 0;
            this.contaGerencialId = 0;
            this.valorClassificacao = 0;
            this.percentualClassificacao = 0;

            if (this.$refs.formClassificacao) {
                this.$refs.formClassificacao.resetValidation();
            }
            
            this.CalcularRestanteDivisao();
        }
    }

    ExcluirReceitaClassificacao(classificacao: ReceitaClassificacao){        
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                var index = context.item.classificacoes.indexOf(classificacao);
                context.item.classificacoes.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir).finally(() => {
            this.CalcularRestanteDivisao();
        });
    }

    DownloadDocumento(id: number){
        this.loading = true;
        this.service.DownloadDocumento(id).then(
            res => {
                this.service.PreviewArquivo(res);
            },
            err => {
                AlertSimple('Aviso', this.service.ErroArquivo(err), 'error');
            }
        )
        .finally(() => this.loading = false);
    }

    AbrirLink(item: ReceitaDocumento){
        window.open(item.link, '_blank');
    }

    CloseCentroCusto(){
        this.dialogCentroCusto = false;
    }

    CloseContaGerencial() {
        this.dialogContaGerencial = false;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            
            if(this.item.id == 0)
                this.item.parcelas = this.parcelas;

            //Verificacoes            
            if(this.VerificacoesSalvamento()){
                if(this.venda){
                    this.$emit("salvou");
                    this.loading = false;
                    return;
                }

                this.loading = true;
                let patchModel = jiff.diff(this.itemOriginal, this.item, false);
                (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                    res => {

                        let salvamentoCorreto = true;
                        let id = res.data.id ? res.data.id : this.item.id;
                        let documentosGravar = this.receitaDocumentos.filter(x => x.id == 0);
                        let documentosExcluir = this.receitaDocumentos.filter(x => x.excluir).map(x => x.id).join(",");

                        let promise1 = new Promise((resolve, reject) => {
                            if(documentosGravar.length > 0){
                                let links = documentosGravar.filter(x => !!x.link).map(x => x.link);
                                let dados = documentosGravar.filter(x => x[`dados`]).map(x => x[`dados`]);
                                this.service.GravarDocumentos(id, links, dados).then(
                                    res => {},
                                    err => {
                                        salvamentoCorreto = false;
                                        AlertSimpleErr("Aviso!", err);
                                    }
                                ).finally(() => {
                                    resolve("Promise 1");
                                })
                            }
                            else{
                                resolve("Promise 1");
                            }
                        });

                        let promise2 = new Promise(async (resolve, reject) => {
                            if (this.documento){
                                this.service.GravarDocumento(this.documento, id).then(
                                    res => {},
                                    err => {
                                        salvamentoCorreto = false;
                                        AlertSimpleErr("Aviso!", err);
                                    }
                                ).finally(() => {
                                    resolve("Promise 2");
                                })
                            }
                            else{
                                resolve("Promise 2");
                            }
                        });

                        let promise3 = new Promise(async (resolve, reject) => {
                            if(documentosExcluir.length > 0){
                                this.service.ExcluirDocumentos(id, documentosExcluir).then(
                                    res => {},
                                    err => {
                                        salvamentoCorreto = false;
                                        AlertSimpleErr("Aviso!", err);
                                    }
                                ).finally(() => {
                                    resolve("Promise 3");    
                                })
                            }
                            else{
                                resolve("Promise 3");
                            }
                        });

                        Promise.all([promise1, promise2, promise3]).then(values => {
                            if(salvamentoCorreto){
                                AlertSimple("Aviso", "Operação Sucesso", "success");
                                this.$emit("salvou");
                                this.Close();
                            }
                        });
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    this.loading = false;
                })
            }
        }
    }

    Close(){
        this.Clear();
        this.dialog = false;
    }

    Clear(){
        this.documento = null;
        this.receitaDocumentos = [];
        this.receitaDocumento = new ReceitaDocumento();
        this.parcelas = [];
        this.qtdParcelas = 1;
        this.inicioParcela = 1;
        this.vencimentoParcela = new Date().toYYYYMMDD();
        this.digitavel = '';
        this.totalSerDividido = 0;
        this.valorClassificacao = 0;
        this.percentualClassificacao = 0;
    }
}
