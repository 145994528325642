
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Municipio } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { ModalidadeFrete } from '@/core/models/vendas';
import { ModalidadeFreteService } from '@/core/services/vendas';
import { Cfop, FinalidadeNFe, NFe, NFeInformacaoPagamento, NFeItem, NFeReferenciada, NFeTransporteVolume, TipoReferenciada } from '@/core/models/dfe';
import { CfopService, DFeService, FinalidadeNFeService, NFeService, TipoReferenciadaService } from '@/core/services/dfe';
import { Pessoa, PessoaEndereco } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { Veiculo } from '@/core/models/transporte';
import { VeiculoService } from '@/core/services/transporte';
import { EnderecoService } from '@/core/services/finder';
import { NfeCobrancaDuplicata, NFeTransporteReboque } from '@/core/models/dfe/NFe';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';

@Component
export default class CadastroNFe extends CrudBase{
    @Prop() item!: NFe;
    @Prop() private value!: string;

    itemOriginal!: NFe;
    service: NFeService = new NFeService();
    
    validReferenciados: boolean = true;
    validVeiculo: boolean = true;
    validReboque: boolean = true;
    validCobranca: boolean = true;

    $refs!: {
        form: HTMLFormElement,
        formReferenciados: HTMLFormElement,
        formVeiculo: HTMLFormElement,
        formReboque: HTMLFormElement
    }

    tabTransporte: any = [];

    onSearchDestinatario: any = null;
    pessoaService: PessoaService = new PessoaService();
    destinatarios: Pessoa[] = [];
    pessoaEndereco: PessoaEndereco[] = [];
    isPessoaLoading: boolean = false;

    modalidadeFretes: ModalidadeFrete[] = [];
    modalidadeFretesService: ModalidadeFreteService = new ModalidadeFreteService();

    finalidadesNFes: FinalidadeNFe[] = [];
    finalidadeNFeService: FinalidadeNFeService = new FinalidadeNFeService();

    transportadores: Pessoa[] = [];
    onSearchTransportador: any = null;

    veiculos: Veiculo[] = [];
    isVeiculoLoading: boolean = false;
    onSearchVeiculo: any = null;
    reboques: Veiculo[] = [];
    isReboqueLoading: boolean = false;
    onSearchReboque: any = null;
    veiculoService: VeiculoService = new VeiculoService();

    nfeItem: NFeItem = new NFeItem();
    dialogNFeItem: boolean = false;
    editaItens: boolean = false;

    nfeInformacaoPagamento: NFeInformacaoPagamento = new NFeInformacaoPagamento();
    dialogInformacaoPagamento: boolean = false;
    editaInformacaoPagamento: boolean = false;

    nfeTransporteReboque: NFeTransporteReboque = new NFeTransporteReboque();

    dialogNfeTransporteVolume: boolean = false;
    nfeTransporteVolume: NFeTransporteVolume = new NFeTransporteVolume();
    editaNfeTransporteVolume: boolean = false;

    estadoId: number = 0;

    nfeReferenciadaService: TipoReferenciadaService = new TipoReferenciadaService();
    nfeReferenciadas: TipoReferenciada[] = [];

    nfeReferenciada: NFeReferenciada = new NFeReferenciada();

    nfeCobrancaDuplicata: NfeCobrancaDuplicata = new NfeCobrancaDuplicata();
    dialogNFeCobranca: boolean = false;
    editarNfeCobrancaDuplicata: boolean = false;

    enderecoService: EnderecoService = new EnderecoService();
    municipios: Municipio[] = [];

    cfops: Cfop[] = [];
    cfopService: CfopService = new CfopService();

    icmsRetido: number = 0;

    enumTransporteVeiculo: any[] = [];
    enumDestinoOperacoes: any[] = [];

    headerNotas: any[]= [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Tipo de Nota', value: 'tipoReferenciada.nome', sortable:  false },
        { text: 'Chave Acesso', value: 'chaveAcesso', sortable:  false },
    ];

    headerPagamento: any[]= [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Meio de Pagamento', value: 'meioPagamento.nome', sortable:  false },
        { text: 'Valor do Pagamento', value: 'valor', sortable:  false },
        { text: 'Indicador da Forma de Pagamento', value: 'indicadorFormaPagamento.nome', sortable:  false },
        { text: 'Descrição', value: 'descricaoMeioPagamento', sortable:  false },
    ];

    headerItens: any[]= [
        { text: '', value: 'actions', sortable: false },
        { text: 'Produto', value: 'produto.codigoNome', sortable: false },
        { text: 'R.F.', value: 'regraFiscal.numeroNome', sortable: false },
        { text: 'Valor Unitário', value: 'unitario', sortable: false },
        { text: 'Frete', value: 'frete', sortable: false },
        { text: 'Quantidade', value: 'quantidade', sortable: false },
        { text: 'Seguro', value: 'seguro', sortable: false },
        { text: 'Desconto', value: 'desconto', sortable: false },
        { text: 'Outras', value: 'outras', sortable: false },
        { text: 'Total', value: 'valorTotal', sortable: false },
    ];

    headerReboques: any[]= [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Reboque', value: 'veiculo.codigoPlaca'},
        { text: 'RNTC', value: 'rntc'}
    ];

    headerVolumes: any[]= [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Quantidade', value: 'quantidade'},
        { text: 'Espécie', value: 'especie'},
        { text: 'Marca', value: 'marca'},
        { text: 'Numeração', value: 'numeracao'},
        { text: 'Peso Liquido', value: 'pesoLiquido'},
        { text: 'Peso Bruto', value: 'pesoBruto'}
    ];

    headerCobranças: any[]= [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Número', value: 'numero', sortable:  false },
        { text: 'Data Vencimento', value: 'dataVencimento', sortable:  false },
        { text: 'Valor', value: 'valor', sortable:  false },
    ];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            
            this.destinatarios.push(this.item.pessoa);
            
            if(this.item.transporte.transportadorId)
                this.transportadores.push(this.item.transporte.transportador);
            
            if(this.item.transporte.veiculo.veiculoReboque.veiculoId)
                this.veiculos.push(this.item.transporte.veiculo.veiculoReboque.veiculo);
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formReferenciados) {
            this.$refs.formReferenciados.resetValidation();
        }
        if (this.$refs.formVeiculo) {
            this.$refs.formVeiculo.resetValidation();
        }
        if (this.$refs.formReboque) {
            this.$refs.formReboque.resetValidation();
        }
    }

    @Watch('item.pessoaId')
    watchPessoa(){
        if(this.item.pessoaId){
            this.item.pessoa = this.destinatarios.find(x => x.id == this.item.pessoaId)!
        }
    }

    @Watch('item.transporte.veiculo.veiculoReboque.veiculoId')
    watchVeiculo(){
        if(this.item.transporte.veiculo.veiculoReboque.veiculoId){
            let veiculo = this.veiculos.find(x => x.id == this.item.transporte.veiculo.veiculoReboque.veiculoId)!;
            this.item.transporte.veiculo.veiculoReboque.placa = veiculo.placa;
            this.item.transporte.veiculo.veiculoReboque.estadoId = veiculo.estadoId;
            this.item.transporte.veiculo.veiculoReboque.rntc = veiculo.rntc;
        }
    }

    @Watch('onSearchDestinatario')
    searchDestinatario (val: string) {
        if (this.item.pessoaId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        if(val.length >= 3){
            this.isPessoaLoading = true;
            this.pessoaService.AutoComplete(val).then(
                res => {
                    this.destinatarios = res.data;
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => (this.isPessoaLoading = false));
        }
    }

    @Watch('onSearchTransportador')
    searchTransportador (val: string) {
        if (this.item.transporte.transportadorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        this.isPessoaLoading = true;
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Transportador).then(
            res => {
                this.transportadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVeiculo')
    searchVeiculo (val: string) {
        if (this.item.transporte.veiculo.veiculoReboque.veiculoId) return;
        if (this.isVeiculoLoading) return;
        if (!val) return;
        this.isVeiculoLoading = true
        this.veiculoService.AutoComplete(val).then(
            res => {
                this.veiculos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isVeiculoLoading = false));
    }

    @Watch('onSearchReboque')
    searchReboque (val: string) {
        if (this.nfeTransporteReboque.veiculoId) return;
        if (this.isReboqueLoading) return;
        if (!val) return;
        this.isReboqueLoading = true
        this.veiculoService.AutoComplete(val).then(
            res => {
                this.reboques = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isReboqueLoading = false));
    }

    @Watch("item.transporte.retencaoIcms.baseCalculo")
    @Watch("item.transporte.retencaoIcms.aliquota")
    WatchICMSRetido(){
        this.icmsRetido = Math.fround(this.item.transporte.retencaoIcms.baseCalculo * Math.fround(this.item.transporte.retencaoIcms.aliquota / 100));
    }

    Carregar() {
        this.modalidadeFretesService.ListarTudo().then(
            res => this.modalidadeFretes = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        );
        this.finalidadeNFeService.ListarTudo().then(
            res => this.finalidadesNFes = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        );
        this.nfeReferenciadaService.ListarTudo().then(
            res => this.nfeReferenciadas = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        );
        this.enderecoService.ObterEstados().then(
            res => this.municipios = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        );
        this.cfopService.ListarTudo().then(
            res => this.cfops = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        new DFeService().GetTipoIdentificacaoEvento().then(
            res => this.enumTransporteVeiculo = res.data
        )

        new DFeService().GetDestinoOperacao().then(
            res => this.enumDestinoOperacoes = res.data
        )
    }

    GetEstadoId(){
        return this.item.pessoa && this.item.pessoa.endereco ? this.item.pessoa.endereco.municipio.estadoId : 0;
    }
    
    //ITEM
    AbrirDialogNFeItem(item?: NFeItem){
        if(item) {
            this.editaItens = true;
            this.nfeItem = item;
        }
        else {
            this.editaItens = false;
            this.nfeItem = new NFeItem();
        }
        this.dialogNFeItem = true;
    }
    SalvarItem(){
        if(!this.editaItens) {
            this.item.itens.push(this.nfeItem);
        }
        this.AtualizarTotais();
        this.nfeItem = new NFeItem();
    }
    ExcluirItens(item: NFeItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    //NOTA_REFERENCIADA
    AdicionarNotaReferenciada(){
        if (this.$refs.formReferenciados.validate()) {
            this.nfeReferenciada.tipoReferenciada = this.nfeReferenciadas.find(x => x.id == this.nfeReferenciada.tipoReferenciadaId)!;
            this.item.referenciadas.push(this.nfeReferenciada)
            this.nfeReferenciada = new NFeReferenciada();
            if (this.$refs.formReferenciados) {
                this.$refs.formReferenciados.resetValidation();
            }
        }
    }
    ExcluirNotaReferenciada(item: NFeReferenciada) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.referenciadas.indexOf(item);
                context.item.referenciadas.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    //INFORMACAO_PAGAMENTO
    AbrirDialogInformacaoPagamento(item?: NFeInformacaoPagamento) {
        if(item) {
            this.editaInformacaoPagamento = true;
            this.nfeInformacaoPagamento = item;
        }
        else {
            this.editaInformacaoPagamento = false;
            this.nfeInformacaoPagamento = new NFeInformacaoPagamento();
        }
        this.dialogInformacaoPagamento = true;
    }
    SalvarInformacaoPagamento(){
        if(!this.editaInformacaoPagamento) {
            this.item.informacaoPagamentos.push(this.nfeInformacaoPagamento);
        }
        this.nfeInformacaoPagamento = new NFeInformacaoPagamento();
    }
    ExcluirInformacaoPagamento(item: NFeInformacaoPagamento) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.informacaoPagamentos.indexOf(item);
                context.item.informacaoPagamentos.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    //VEICULO_REBOQUE
    AdicionarVeiculoReboque(){
        if (this.$refs.formReboque.validate()) {
            if(this.item.transporte.veiculo.veiculoReboque.reboques.find(x => x.veiculoId == this.nfeTransporteReboque.veiculoId)){
                AlertSimple("Aviso!", "O reboque já se encontra inserido.", "warning");
                this.nfeTransporteReboque = new NFeTransporteReboque();
                if (this.$refs.formReboque) {
                    this.$refs.formReboque.resetValidation();
                }
                return;
            }
            let reboque = this.reboques.find(x => x.id == this.nfeTransporteReboque.veiculoId)!;
            this.nfeTransporteReboque.veiculo = reboque;
            this.nfeTransporteReboque.placa = reboque.placa;
            this.nfeTransporteReboque.estadoId = reboque.estadoId;
            this.nfeTransporteReboque.rntc = reboque.rntc;
            this.item.transporte.veiculo.veiculoReboque.reboques.push(this.nfeTransporteReboque);
            this.nfeTransporteReboque = new NFeTransporteReboque();
            if (this.$refs.formReboque) {
                this.$refs.formReboque.resetValidation();
            }
        }
    }
    ExcluirVeiculoReboque(item: NFeTransporteReboque){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.transporte.veiculo.veiculoReboque.reboques.indexOf(item);
                context.item.transporte.veiculo.veiculoReboque.reboques.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    //TRANSPORTE VOLUME
    AbrirDialogTransporteVolume(item?: NFeTransporteVolume){
        if(item){
            this.editaNfeTransporteVolume = true;
            this.nfeTransporteVolume = item;
        }
        else{
            this.editaNfeTransporteVolume = false;
            this.nfeTransporteVolume = new NFeTransporteVolume();
        }
        this.dialogNfeTransporteVolume = true;
    }
    SalvarTransporteVolume(){
        if(!this.editaNfeTransporteVolume){
            this.item.transporte.volumes.push(this.nfeTransporteVolume);
        }
        this.nfeTransporteVolume = new NFeTransporteVolume();
    }
    ExcluirTransporteVolume(item: NFeTransporteVolume) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.transporte.volumes.indexOf(item);
                context.item.transporte.volumes.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    //TOTAIS
    AtualizarTotais(){
        this.service.AtualizarTotais(this.item.itens).then(
            res => {
                this.item.totais = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    //NFE_COBRANCA
    AbrirDialogNFeCobranca(item?: NfeCobrancaDuplicata){
        if(item) {
            this.editarNfeCobrancaDuplicata = true;
            this.nfeCobrancaDuplicata = item;
            this.nfeCobrancaDuplicata.dataVencimento = this.nfeCobrancaDuplicata.dataVencimento ? this.nfeCobrancaDuplicata.dataVencimento.toDateYYYYMMDD() : "";
        }
        else {
            this.editarNfeCobrancaDuplicata = false;
            this.nfeCobrancaDuplicata = new NfeCobrancaDuplicata();
        }
        this.dialogNFeCobranca = true;
    }
    SalvarCobrancaDuplicata(){
        if(!this.editarNfeCobrancaDuplicata){
            this.item.cobranca.duplicatas.push(this.nfeCobrancaDuplicata);
        }
        this.nfeCobrancaDuplicata = new NfeCobrancaDuplicata();
    }
    ExcluirCobrancaDuplicata(item: NfeCobrancaDuplicata) {
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.cobranca.duplicatas.indexOf(item);
                context.item.cobranca.duplicatas.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);

            patchModel = patchModel.filter(str => str.path.indexOf('/pessoa/') === -1 );

            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.item.id = -1
        this.dialog = false;
    }
}
