import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ContaGerencialService extends Service {
    
    constructor() {
        super('v1/contaGerencial');
    }

    private GetFiltro(parametros: string, filter: any){
  
        if (filter) {
            let paramTemp = '';
    
            if(filter.id){
              if(paramTemp){paramTemp += ' and '; }
              paramTemp += `id eq ${filter.id}`;
            } 
    
            if (paramTemp) {
    
              if(parametros){
                parametros += '&';
              }
              else {
                parametros += '?';
              }
    
              parametros += `$filter=${paramTemp}`;
            }
        }
     
        return parametros;  
    }

    TreeView(tipo: string){
        return http.get(`${this.GetNomeControle()}/treeview`, {
            params:{
                tipo
            }
        });
    }

    Listagem(tipo?: string){
        return http.get(`${this.GetNomeControle()}/listagem`, {
            params:{
                tipo
            }
        });
    }

    ListagemSimples(tipo?: string){
        return http.get(`${this.GetNomeControle()}/listagemsimples`, {
            params:{
                tipo
            }
        });
    }
    
    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    } 

    public TransferirNivel(id: number, contaGerencialPaiId: number) {
        return http.post(`${this.GetNomeControle()}/TransferirNivel`, undefined, {
            params: {
                id,
                contaGerencialPaiId,
            }
        });
    }

    public Recodificar(paiId: number) {
        return http.post(`${this.GetNomeControle()}/Recodificar`, undefined, {
            params: {
                paiId
            }
        });
    }
}