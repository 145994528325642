import { Pessoa } from ".";
import { Endereco } from "../shared";

export class PessoaEndereco implements Shared.IEntity{

    id: number = 0;
    pessoaId: number = 0;
    pessoa!: Pessoa;
    endereco: Endereco = new Endereco();
    entrega: boolean = false;
    cobranca: boolean = false;
    descricao: string = '';
    km: number = 0;

    constructor(model?: PessoaEndereco){

        if(!model)
            return;

        this.id = model.id;
        this.pessoaId = model.pessoaId;
        this.pessoa = model.pessoa;
        this.endereco = model.endereco;
        this.entrega = model.entrega;
        this.cobranca = model.cobranca;
        this.descricao = model.descricao;
        this.km = model.km;
    }
}