import Vue from 'vue';

//BASE PAGINAS
import MasterBase from "./shared/MasterBase.vue";
import MasterPage from "./shared/MasterPage.vue";
import CadastroPage from "./shared/CadastroPage.vue";
import MasterGeral from "./geral/shared/Master.vue";

import MasterFinanceiro from "./financeiro/shared/Master.vue";
import ContaGerencialFinanceiro from "./financeiro/shared/ContaGerencialFinanceiro.vue";
import CentroCustoFinanceiro from "./financeiro/shared/CentroCustoFinanceiro.vue"
import MasterTransporte from "./transporte/shared/Master.vue";
import MasterCompras from "./compras/shared/Master.vue";
import MasterEstoque from "./estoque/shared/Master.vue";
import MasterVendas from "./vendas/shared/Master.vue";
import MasterEngenhariaNaval from "./engenharianaval/shared/Master.vue";
import MasterManutencao from "./manutencao/shared/Master.vue";
import MasterOperacaoPortuaria from "./operacaoportuaria/shared/Master.vue";
import MasterDocumentos from './documentos/shared/Master.vue';
// import MasterControleResiduos from './controleresiduos/shared/Master.vue';

Vue.component("master-base", MasterBase);
Vue.component("master-page", MasterPage);
Vue.component("cadastro-page", CadastroPage);
Vue.component("master-geral", MasterGeral);

Vue.component("master-financeiro", MasterFinanceiro);
Vue.component("financeiro-contaGerencial", ContaGerencialFinanceiro);
Vue.component("financeiro-centroCusto", CentroCustoFinanceiro);
Vue.component("master-transporte", MasterTransporte);
Vue.component("master-compras", MasterCompras);
Vue.component("master-estoque", MasterEstoque);
Vue.component("master-vendas", MasterVendas);
Vue.component("master-engenharianaval", MasterEngenhariaNaval);
Vue.component("master-manutencao", MasterManutencao);
Vue.component("master-operacaoportuaria", MasterOperacaoPortuaria);
Vue.component("master-documentos", MasterDocumentos)
// Vue.component("master-controleresiduos", MasterControleResiduos);

//

//RECURSOS
import ModuloCard from "./shared/ModuloCard.vue";
import TextSearch from "./shared/TextSearch.vue";
import AlterarEmpresa from './shared/AlterarEmpresa.vue';
import AlterarSenha from './shared/AlterarSenha.vue';
import Avatar from "./shared/Avatar.vue";
import BreadCrumb from "./shared/BreadCrumb.vue";
import BreadCrumbNoRoute from './shared/BreadCrumbNoRoute.vue';
import ListaFooter from "./shared/ListaFooter.vue";
import ListaHeader from './shared/ListaHeader.vue';
import RelatorioPage from './shared/RelatorioPage.vue';
import RelatorioPageAnonymous from './shared/RelatorioPageAnonymous.vue';
import StatusChip from "./shared/StatusChip.vue";
import BarChart from "./shared/chartJs/BarChart.vue";
import BarChart2 from "./shared/chartJs/BarChart2.vue";
import BarChart3 from "./shared/chartJs/BarChart3.vue";
import DoughnutChart from "./shared//chartJs/DoughnutChart.vue";
import FunelChart from "./shared/chartJs/FunelChart.vue";
import PieChart from "./shared/chartJs/PieChart.vue";
import wysiwyg from "vue-wysiwyg";

Vue.component("modulo-card", ModuloCard);
Vue.component("text-search", TextSearch);
Vue.component("alterar-empresa", AlterarEmpresa);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("avatar-component", Avatar);
Vue.component("breadCrumb-component", BreadCrumb);
Vue.component("breadCrumbNoRoute-component", BreadCrumbNoRoute);
Vue.component("lista-footer", ListaFooter);
Vue.component("lista-header", ListaHeader);
Vue.component("relatorio-page", RelatorioPage);
Vue.component("relatorio-page-anonymous", RelatorioPageAnonymous);
Vue.component("status-chip", StatusChip);
Vue.component("bar-chart", BarChart);
Vue.component("bar-chart2", BarChart2);
Vue.component("bar-chart3", BarChart3);
Vue.component("doughnut-chart", DoughnutChart);
Vue.component("funel-chart", FunelChart);
Vue.component("pie-chart", PieChart);
Vue.use(wysiwyg, {
    hideModules: {
        "image": true,
        "table": true
    }
});
//

//GERAL
import CadastroCliente from "./geral/cliente/Cadastro.vue";
import CadastroFornecedor from "./geral/fornecedor/Cadastro.vue";
import CadastroTransportador from "./geral/transportador/Cadastro.vue";
import CadastroVendedor from "./geral/vendedor/Cadastro.vue";
import CadastroContato from "./geral/shared/contato/Cadastro.vue";
import CadastroEndereco from "./geral/shared/endereco/Cadastro.vue";
import CadastroFabricante from "./geral/fabricante/Cadastro.vue";
import CadastroSegmento from "./geral/segmento/Cadastro.vue";
import CadastroFaina from "./geral/faina/Cadastro.vue";
import CadastroFuncao from "./geral/funcao/Cadastro.vue";
import CadastroPorto from "./geral/porto/Cadastro.vue";
import CadastroPortoLocalOperacao from "./geral/porto/CadastroPortoLocalOperacao.vue";
import CadastroTerno from "./geral/terno/Cadastro.vue";
import CadastroFuncionario from "./geral/funcionario/Cadastro.vue";
import CadastroDepartamento from "./geral/departamento/Cadastro.vue";
import CadastroSindicato from "./geral/sindicato/Cadastro.vue";

Vue.component("cadastro-cliente", CadastroCliente);
Vue.component("cadastro-fornecedor", CadastroFornecedor);
Vue.component("cadastro-transportador", CadastroTransportador);
Vue.component("cadastro-vendedor", CadastroVendedor);
Vue.component("cadastro-pessoa-contato", CadastroContato);
Vue.component("cadastro-pessoa-endereco", CadastroEndereco);
Vue.component("cadastro-fabricante", CadastroFabricante);
Vue.component("cadastro-segmento", CadastroSegmento);
Vue.component("cadastro-faina", CadastroFaina);
Vue.component("cadastro-funcao", CadastroFuncao);
Vue.component("cadastro-porto", CadastroPorto);
Vue.component("cadastro-porto-localOperacao", CadastroPortoLocalOperacao);
Vue.component("cadastro-terno", CadastroTerno);
Vue.component("cadastro-funcionario", CadastroFuncionario);
Vue.component("cadastro-departamento", CadastroDepartamento);
Vue.component("cadastro-sindicato", CadastroSindicato);
//

//MANUTENCAO
import CadastroEquipamento from "./manutencao/equipamento/Cadastro.vue"
import CadastroOcorrencia from "./manutencao/ocorrencia/Cadastro.vue"
import CadastroManutencao from "./manutencao/ordemServico/Cadastro.vue"


Vue.component("cadastro-equipamento", CadastroEquipamento);
Vue.component("cadastro-ocorrencia", CadastroOcorrencia);
Vue.component("cadastro-OrdemServico-Manutencao", CadastroManutencao)

//COMPRAS
import CadastroCotacao from "./compras/cotacao/Cadastro.vue"
import CadastroCotacaoFornecedor from "./compras/cotacao/CotacaoFornecedor.vue"

import CadastroPedidoCompra from "./compras/pedido/Cadastro.vue"

Vue.component("cadastro-cotacao", CadastroCotacao);
Vue.component("cadastro-cotacao-fornecedor", CadastroCotacaoFornecedor);

Vue.component("cadastro-pedido-compra", CadastroPedidoCompra);
//

//FINANCEIRO
import CadastroCentroCusto from "./financeiro/centroCusto/Cadastro.vue"
import ListaCentroCusto from "./financeiro/centroCusto/Lista.vue"
// import CadastroCentroCustoTransferir from './financeiro/centroCusto/Transferir.vue';
import CadastroContaCorrente from "./financeiro/contaCorrente/Cadastro.vue"
import CadastroContaGerencial from "./financeiro/contaGerencial/Cadastro.vue"
import ListaContaGerencial from "./financeiro/contaGerencial/Lista.vue";
import CadastroTransferir from "./financeiro/contaGerencial/Transferir.vue"
import CadastroFormaPagamento from "./financeiro/formaPagamento/Cadastro.vue"

import CadastroCondicaoPagamento from "./financeiro/condicaoPagamento/Cadastro.vue"
import CadastroCondicaoPagamentoDia from "./financeiro/condicaoPagamento/CadastroDia.vue"

import CadastroReceita from "./financeiro/receita/Cadastro.vue"
import CadastroReceitaParcela from "./financeiro/receita/CadastroParcela.vue"
import DetalharReceitaParcela from "./financeiro/receita/DetalharParcela.vue"
import CadastroReceitaParcelaBaixa from "./financeiro/receita/CadastroParcelaBaixa.vue"
import SelecionaReceitaBaixa from "./financeiro/receita/SelecionaBaixa.vue"

import CadastroFluxoBancarioDebitoCredito from "./financeiro/fluxoBancario/CadastroDebitoCredito.vue"
import CadastroFluxoBancarioTransferencia from "./financeiro/fluxoBancario/CadastroTransferencia.vue"

import CadastroPrevisaoOrcamentaria from "./financeiro/orcamento/Cadastro.vue"

import CadastroPatrimonio from "./financeiro/patrimonio/Cadastro.vue"

Vue.component("lista-centroCusto", ListaCentroCusto);
Vue.component("cadastro-centroCusto", CadastroCentroCusto);
// Vue.component("cadastro-centroCusto-transferir", CadastroCentroCustoTransferir)

Vue.component("cadastro-contaCorrente", CadastroContaCorrente);
Vue.component("cadastro-contaGerencial", CadastroContaGerencial);
Vue.component("lista-contaGerencial", ListaContaGerencial);
Vue.component("cadastro-contaGerencial-transferir", CadastroTransferir);
Vue.component("cadastro-formaPagamento", CadastroFormaPagamento);

Vue.component("cadastro-condicaoPagamento", CadastroCondicaoPagamento);
Vue.component("cadastro-condicaoPagamento-dia", CadastroCondicaoPagamentoDia);

Vue.component("cadastro-receita", CadastroReceita);
Vue.component("cadastro-receita-parcela", CadastroReceitaParcela);
Vue.component("detalhar-receita-parcela", DetalharReceitaParcela);
Vue.component("cadastro-receita-parcela-baixa", CadastroReceitaParcelaBaixa);
Vue.component("seleciona-receita-baixa", SelecionaReceitaBaixa);

Vue.component("cadastro-fluxoBancario", CadastroFluxoBancarioDebitoCredito);
Vue.component("cadastro-fluxoBancario-transferencia", CadastroFluxoBancarioTransferencia);

Vue.component("cadastro-previsao-orcamentaria", CadastroPrevisaoOrcamentaria);

Vue.component("cadastro-patrimonio", CadastroPatrimonio)


import CadastroDespesa from "./financeiro/despesa/Cadastro.vue"
import CadastroRetornoBancario from "./financeiro/despesa/CadastroRetornoBancario.vue";
import CadastroDespesaParcela from "./financeiro/despesa/CadastroParcela.vue"
import DetalharDespesaParcela from "./financeiro/despesa/DetalharParcela.vue"
import CadastroDespesaParcelaBaixa from "./financeiro/despesa/CadastroParcelaBaixa.vue"
import SelecionaDespesaBaixa from "./financeiro/despesa/SelecionaBaixa.vue"

Vue.component("cadastro-despesa", CadastroDespesa);
Vue.component("cadastro-despesa-retorno-bancario", CadastroRetornoBancario);
Vue.component("cadastro-despesa-parcela", CadastroDespesaParcela);
Vue.component("detalhar-despesa-parcela", DetalharDespesaParcela);
Vue.component("cadastro-despesa-parcela-baixa", CadastroDespesaParcelaBaixa);
Vue.component("seleciona-despesa-baixa", SelecionaDespesaBaixa);
//

//VENDAS
import CadastroContrato from "./vendas/contrato/Cadastro.vue"
import CadastroPedido from "./vendas/pedido/Cadastro.vue"
import CadastroContratoItem from "./vendas/contrato/CadastroItem.vue"
import CadastroVenda from "./vendas/venda/Cadastro.vue"
import CadastroVendaItem from "./vendas/venda/CadastroItem.vue"
import CadastroVendaServico from "./vendas/venda/CadastroPorServico.vue"
import CadastroVendaServicoItem from "./vendas/venda/CadastroPorServicoItem.vue"
import CadastroVendaPeso from "./vendas/venda/CadastroPorPeso.vue"
import CadastroVendaPesoItem from "./vendas/venda/CadastroPorPesoItem.vue"
import AjustarVenda from './vendas/venda/AjustarVenda.vue'
import CadastroTabelaPreco from './vendas/tabelapreco/Cadastro.vue'

Vue.component("cadastro-contrato", CadastroContrato);
Vue.component("cadastro-pedido", CadastroPedido);
Vue.component("cadastro-contrato-item", CadastroContratoItem);
Vue.component("cadastro-venda", CadastroVenda);
Vue.component("cadastro-venda-item", CadastroVendaItem);
Vue.component("cadastro-venda-servico", CadastroVendaServico);
Vue.component("cadastro-venda-servico-item", CadastroVendaServicoItem);
Vue.component("cadastro-venda-peso", CadastroVendaPeso);
Vue.component("cadastro-venda-peso-item", CadastroVendaPesoItem);
Vue.component("ajustes-venda", AjustarVenda);
Vue.component("cadastro-tabelapreco", CadastroTabelaPreco);
//

// DFE
import CadastroNFe from "./dfe/nfe/Cadastro.vue";
import CadastroInformacaoPagamento from "./dfe/nfe/CadastroInformacaoPagamento.vue";
import CadastroDeInutilizados from "./dfe/nfe/CadastroInutilizados.vue";
import CadastroItemNFe from "./dfe/nfe/CadastroItem.vue";
import CadastroCobranca from "./dfe/nfe/CadastroCobranca.vue"
import CadastroVolume from "./dfe/nfe/CadastroVolume.vue"
import CadastroMDFe from "./dfe/mdfe/Cadastro.vue"

import CadastroImportacao from "./dfe/nfe/CadastroImportacao.vue";

import CadastroNFSe from "./dfe/nfse/Cadastro.vue"

Vue.component("cadastro-nfe", CadastroNFe);
Vue.component("cadastro-nfe-informacao-pagamento", CadastroInformacaoPagamento);
Vue.component("cadastro-nfe-inutilizados", CadastroDeInutilizados);
Vue.component("cadastro-nfe-item", CadastroItemNFe);
Vue.component("cadastro-nfe-cobranca", CadastroCobranca);
Vue.component("cadastro-nfe-volume", CadastroVolume);
Vue.component("cadastro-mdfe", CadastroMDFe);

Vue.component("cadastro-importacao", CadastroImportacao);

Vue.component("cadastro-nfse", CadastroNFSe);

//TRANSPORTE
import CadastroVeiculo from "./transporte/veiculo/Cadastro.vue";
import CadastroTara from "./transporte/tara/Cadastro.vue";
import CadastroEmbarcacaoOperacaoPortuaria from "./transporte/embarcacao/Cadastro.vue";
import CadastroTipoEmbarcacao from "./transporte/tipoEmbarcacao/Cadastro.vue";
import CadastroCategoriaVeiculo from "./transporte/categoriaVeiculo/Cadastro.vue";

Vue.component("cadastro-veiculo", CadastroVeiculo);
Vue.component("cadastro-tara", CadastroTara);
Vue.component("cadastro-operacaoportuaria-embarcacao", CadastroEmbarcacaoOperacaoPortuaria);
Vue.component("cadastro-tipoembarcacao", CadastroTipoEmbarcacao);
Vue.component("cadastro-categoriaVeiculo", CadastroCategoriaVeiculo);
//

//ESTOQUE
import CadastroAlmoxarifado from "./estoque/almoxarifado/Cadastro.vue"
import CadastroCategoriaProduto from "./estoque/categoriaProduto/Cadastro.vue"
import CadastroEntradaNotaFiscal from "./estoque/entradaNotaFiscal/Cadastro.vue"
import CadastroEntradaNotaFiscalItem from "./estoque/entradaNotaFiscal/CadastroItem.vue"
import CadastroMovimentacao from "./estoque/movimentacao/Cadastro.vue"
import CadastroMovimentacaoTransferencia from "./estoque/movimentacao/CadastroTransferencia.vue"
import CadastroProduto from "./estoque/produto/Cadastro.vue"
import CadastroServico from "./estoque/servico/Cadastro.vue"
import CadastroRegraFiscal from "./dfe/regraFiscal/Cadastro.vue"
import CadastroTipoDocumentoEntradaNotaFiscal from "./estoque/tipoDocumentoEntradaNotaFiscal/Cadastro.vue"
import CadastroUnidadeMedida from "./estoque/unidadeMedida/Cadastro.vue"
import CadastroRequisicao from './estoque/requisicao/Cadastro.vue';

Vue.component("cadastro-almoxarifado", CadastroAlmoxarifado);
Vue.component("cadastro-categoriaProduto", CadastroCategoriaProduto);
Vue.component("cadastro-entradaNotaFiscal", CadastroEntradaNotaFiscal);
Vue.component("cadastro-entradaNotaFiscalItem", CadastroEntradaNotaFiscalItem);
Vue.component("cadastro-movimentacao", CadastroMovimentacao);
Vue.component("cadastro-movimentacao-transferencia", CadastroMovimentacaoTransferencia);
Vue.component("cadastro-produto", CadastroProduto);
Vue.component("cadastro-servico", CadastroServico);
Vue.component("cadastro-regraFiscal", CadastroRegraFiscal);
Vue.component("cadastro-tipoDocumentoEntradaNotaFiscal", CadastroTipoDocumentoEntradaNotaFiscal);
Vue.component("cadastro-unidadeMedida", CadastroUnidadeMedida);
Vue.component("cadastro-requisicao", CadastroRequisicao);
//

// ENGENHARIA NAVAL
import CadastroEmbarcacao from "./engenharianaval/embarcacao/Cadastro.vue"
import CadastroLocalOperacao from "./engenharianaval/localOperacao/Cadastro.vue"
import CadastroMedicao from "./engenharianaval/medicao/Cadastro.vue"
import CadastroOrdemServico from "./engenharianaval/ordemServico/Cadastro.vue"
import SelecionarOrdemServicoServico from "./engenharianaval/ordemServico/SelecionarServico.vue"
import CadastroProposta from "./engenharianaval/proposta/Cadastro.vue"

Vue.component("cadastro-embarcacao", CadastroEmbarcacao);
Vue.component("cadastro-localOperacao", CadastroLocalOperacao);
Vue.component("cadastro-medicao", CadastroMedicao);
Vue.component("cadastro-ordemServico", CadastroOrdemServico);
Vue.component("selecionar-ordemServicoServico", SelecionarOrdemServicoServico);
Vue.component("cadastro-proposta", CadastroProposta);
//

// OPERACAO PORTUARIA
import CadastroAgendamento from "./operacaoportuaria/agendamento/Cadastro.vue";
import CadastroAgendamentoEquipamento from "./operacaoportuaria/agendamento/CadastroAgendamentoEquipamentoServico.vue";

import CadastroBoletimMedicao from "./operacaoportuaria/boletimMedicao/Cadastro.vue"

import CadastroPropostaOperacaoPortuaria from "./operacaoportuaria/proposta/Cadastro.vue";
import CadastroPropostaEquipamento from "./operacaoportuaria/proposta/CadastroPropostaEquipamento.vue";

import CadastroRegistroOperacao from "./operacaoportuaria/registroOperacao/Cadastro.vue";
import CadastroRegistroOperacaoEquipamento from "./operacaoportuaria/registroOperacao/CadastroRegistroOperacaoEquipamento.vue";
import CadastroRegistroOperacaoFaina from "./operacaoportuaria/registroOperacao/CadastroRegistroOperacaoFaina.vue";
import CadastroRegistroOperacaoTerno from "./operacaoportuaria/registroOperacao/CadastroRegistroOperacaoTerno.vue";

import SelecionaRegistroOperacaoEquipamento from "./operacaoportuaria/registroOperacao/SelecionarEquipamentoServico.vue";

import CadastroControleOrdemServico from "./operacaoportuaria/controleOrdemServico/Cadastro.vue";

import CadastroAtribuicao from "./operacaoportuaria/atribuicaoFuncionario/Cadastro.vue";
import CadastroRelatorioMedicao from "./operacaoportuaria/relatorioMedicao/Cadastro.vue";
import CadastroTipoProposta from './operacaoportuaria/TipoProposta/Cadastro.vue';

Vue.component("cadastro-agendamento", CadastroAgendamento);
Vue.component("cadastro-agendamento-equipamento", CadastroAgendamentoEquipamento);

Vue.component("cadastro-boletimMedicao", CadastroBoletimMedicao);

Vue.component("cadastro-proposta-operacao-portuaria", CadastroPropostaOperacaoPortuaria);
Vue.component("cadastro-proposta-equipamento", CadastroPropostaEquipamento);

Vue.component("cadastro-registroOperacao", CadastroRegistroOperacao);
Vue.component("cadastro-registroOperacao-equipamento", CadastroRegistroOperacaoEquipamento);
Vue.component("cadastro-registroOperacao-faina", CadastroRegistroOperacaoFaina);
Vue.component("cadastro-registroOperacao-terno", CadastroRegistroOperacaoTerno);
Vue.component("seleciona-registroOperacao-equipamento", SelecionaRegistroOperacaoEquipamento);

Vue.component("cadastro-atribuicao", CadastroAtribuicao);
Vue.component("cadastro-controleOrdemServico", CadastroControleOrdemServico);
Vue.component("cadastro-relatorioMedicao", CadastroRelatorioMedicao);
Vue.component("cadastro-tipoProposta", CadastroTipoProposta)
//

// DOCUMENTO
import CadastroDocumento from "./documentos/documento/Cadastro.vue";
import CadastroTipoDocumento from './documentos/tipoDocumento/Cadastro.vue';
import CadastroSelecaoDocumentos from './documentos/selecao/Cadastro.vue';

import CadastroModeloSelecao from './documentos/modeloSelecao/Cadastro.vue';
import CadastroPorModelo from './documentos/selecao/CadastroPorModelo.vue';

Vue.component("cadastro-documento", CadastroDocumento);
Vue.component("cadastro-tipoDocumento", CadastroTipoDocumento);
Vue.component("cadastro-selecaoDocumentos", CadastroSelecaoDocumentos);
Vue.component("cadastro-modeloSelecao", CadastroModeloSelecao);
Vue.component("cadastro-selecaoPorModelo", CadastroPorModelo);
