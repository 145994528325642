
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Fabricante } from '@/core/models/geral';
import { FabricanteService } from '@/core/services/geral';

@Component
export default class CadastroFabricante extends CrudBase{
    @Prop() item!: Fabricante;
    @Prop() private value!: string;

    itemOriginal!: Fabricante;
    service: FabricanteService = new FabricanteService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    let id = this.item.id > 0 ? this.item.id : res.data.id;
                    this.Salvou(id);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(id? : number) {
        this.$emit("salvou", id);
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
