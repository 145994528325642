import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { RegistroOperacaoEquipamentoDocumento } from "@/core/models/operacaoportuaria";

export class RegistroOperacaoService extends Service {

  constructor() {
    super('v1/registroOperacao')
  }

  public AutoComplete(q: string) {
    return http.get(`${this.GetNomeControle()}/AutoComplete`, {
      params: {
        q: q
      },
      headers: httpHeader.headers
    });
  }

  private GetFiltro(parametros: string, filter: any) {
    if (filter) {
      let paramTemp = '';

      if (filter.numero) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `numero eq ${filter.numero}`;
      }

      if (filter.clienteId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `proposta/clienteId eq ${filter.clienteId}`;
      }

      if (filter.propostaId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `proposta/id eq ${filter.propostaId}`;
      }

      if (filter.osNumero) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `equipamentos/any(o: o/sequencia eq ${filter.osNumero})`;
      }

      if (filter.embarcacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `embarcacaoId eq ${filter.embarcacaoId}`;
      }

      if (filter.situacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `situacaoId eq ${filter.situacaoId}`;
      }

      if (filter.responsavelId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `proposta/responsavelId eq ${filter.responsavelId}`;
      }

      if (filter.portoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `portoId eq ${filter.portoId}`;
      }

      if (filter.localOperacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/localOperacaoId eq ${filter.localOperacaoId}`;
      }

      if (filter.atracacao != null) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `hasAtracacao eq ${filter.atracacao}`;
      }

      if (filter.dataDocumentoInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataDocumento ge ${filter.dataDocumentoInicial}`;
      }

      if (filter.dataDocumentoFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `dataDocumento le ${filter.dataDocumentoFinal}`;
      }

      if (filter.atracacaoEntradaInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraEntrada ge ${filter.atracacaoEntradaInicial}`;
      }

      if (filter.atracacaoEntradaFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraEntrada le ${filter.atracacaoEntradaFinal}`;
      }

      if (filter.atracacaoSaidaInicial) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraSaida ge ${filter.atracacaoSaidaInicial}`;
      }

      if (filter.atracacaoSaidaFinal) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `atracacao/dataHoraSaida le ${filter.atracacaoSaidaFinal}`;
      }

      if (paramTemp) {

        if (parametros) {
          parametros += '&';
        }
        else {
          parametros += '?';
        }

        parametros += `$filter=${paramTemp}`;
      }

    }

    return parametros;
  }

  ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);

    parametros = this.GetFiltro(parametros, filter);

    return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
  }

  public AgendaFuncionario(roNumero?: number, funcionarioId?: number, clienteId?: number, embarcacaoId?: number){
    return http.get(`${this.GetNomeControle()}/AgendaFuncionario`, {
      params: {
        roNumero,
        funcionarioId,
        clienteId,
        embarcacaoId
      }
    });
  }
  
  public ExcelAtracacoes(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, dataDocumentoInicial?: string, dataDocumentoFinal?: string, dataAtendimentoEntradaInicial?: string, dataAtendimentoEntradaFinal?: string, dataAtendimentoSaidaInicial?: string, dataAtendimentoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelAtracacao`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        dataDocumentoInicial,
        dataDocumentoFinal,
        dataAtendimentoEntradaInicial,
        dataAtendimentoEntradaFinal,
        dataAtendimentoSaidaInicial,
        dataAtendimentoSaidaFinal
      }
    })
  }

  public ExcelTernos(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, dataDocumentoInicial?: string, dataDocumentoFinal?: string, dataAtendimentoEntradaInicial?: string, dataAtendimentoEntradaFinal?: string, dataAtendimentoSaidaInicial?: string, dataAtendimentoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelTerno`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        dataDocumentoInicial,
        dataDocumentoFinal,
        dataAtendimentoEntradaInicial,
        dataAtendimentoEntradaFinal,
        dataAtendimentoSaidaInicial,
        dataAtendimentoSaidaFinal
      }
    })
  }

  public ExcelFainas(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, dataDocumentoInicial?: string, dataDocumentoFinal?: string, dataAtendimentoEntradaInicial?: string, dataAtendimentoEntradaFinal?: string, dataAtendimentoSaidaInicial?: string, dataAtendimentoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelFaina`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        dataDocumentoInicial,
        dataDocumentoFinal,
        dataAtendimentoEntradaInicial,
        dataAtendimentoEntradaFinal,
        dataAtendimentoSaidaInicial,
        dataAtendimentoSaidaFinal
      }
    })
  }

  public ExcelEquipamento(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, dataDocumentoInicial?: string, dataDocumentoFinal?: string, dataAtendimentoEntradaInicial?: string, dataAtendimentoEntradaFinal?: string, dataAtendimentoSaidaInicial?: string, dataAtendimentoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelEquipamento`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        dataDocumentoInicial,
        dataDocumentoFinal,
        dataAtendimentoEntradaInicial,
        dataAtendimentoEntradaFinal,
        dataAtendimentoSaidaInicial,
        dataAtendimentoSaidaFinal
      }
    })
  }

  public ExcelRO(numero?: number, propostaId?: number, situacaoId?: number, atracacao?: boolean, clienteId?: number, responsavelId?: number, embarcacaoId?: number, portoId?: number, localOperacaoId?: number, dataDocumentoInicial?: string, dataDocumentoFinal?: string, dataAtendimentoEntradaInicial?: string, dataAtendimentoEntradaFinal?: string, dataAtendimentoSaidaInicial?: string, dataAtendimentoSaidaFinal?: string) {
    return http.get(`${this.GetNomeControle()}/ExcelRO`, {
      params: {
        numero,
        propostaId,
        situacaoId,
        atracacao,
        clienteId,
        responsavelId,
        embarcacaoId,
        portoId,
        localOperacaoId,
        dataDocumentoInicial,
        dataDocumentoFinal,
        dataAtendimentoEntradaInicial,
        dataAtendimentoEntradaFinal,
        dataAtendimentoSaidaInicial,
        dataAtendimentoSaidaFinal
      }
    })
  }

  public ListarEquipamentosSemPO(id?: number, fornecedorId?: number, equipamentoId?: number){
    return http.get(`${this.GetNomeControle()}/ListarEquipamentosSemPO`, {
      params: {
        id,
        fornecedorId,
        equipamentoId
      }
    });
  }

  ConcluirManualmente(id: number){
    return http.post(`${this.GetNomeControle()}/${id}/ConcluirManualmente`);
  }

  // CONTROLE OS
  private GetFiltroOS(parametros: string, filter: any) {
    if (filter) {
      let paramTemp = '';

      if (filter.numeroOs) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `sequencia eq ${filter.numeroOs}`;
      }

      if (filter.numeroRo) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `registroOperacao/numero eq ${filter.numeroRo}`;
      }

      if (filter.portoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `registroOperacao/proposta/portoId eq ${filter.portoId}`;
      }

      if (filter.localOperacaoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `registroOperacao/proposta/localOperacaoId eq ${filter.localOperacaoId}`;
      }

      if (filter.equipamentoId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `equipamentoId eq ${filter.equipamentoId}`;
      }

      if (filter.fornecedorId) {
        if (paramTemp) { paramTemp += ' and '; }
        paramTemp += `fornecedorId eq ${filter.fornecedorId}`;
      }

      if (paramTemp) {
        if (parametros)
          parametros += '&';        
        else
          parametros += '?';

        parametros += `$filter=${paramTemp}`;
      }
    }
    return parametros;
  }

  public Equipamentos(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any) {
    let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);    
    parametros = this.GetFiltroOS(parametros, filter);
    return http.get(`${this.GetNomeControle()}/Equipamentos${parametros}`);
  }

  public Equipamento(id: number){
    return http.get(`${this.GetNomeControle()}/Equipamento/${id}`)
  }

  public EquipamentoSalvar(id:number, model: any){
    return http.patch(`${this.GetNomeControle()}/Equipamento/${id}`, model, this.GetHeader());
  }

  //DOCUMENTOS - EQUIPAMENTO
  ListarEquipamentoDocumento(equipamentoId: number){
    return http.get(`${this.GetNomeControle()}/equipamentoDocumento/${equipamentoId}`);
  }

  EnviarEquipamentoDocumento(model: RegistroOperacaoEquipamentoDocumento, arquivo: any){
    let bodyFormData = new FormData();
    bodyFormData.append("model", JSON.stringify(model));
    bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/pdf' }), model.documento.arquivo.nome);

    return http.post(`${this.GetNomeControle()}/equipamentoDocumento`, bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    });
  }

  DeletarEquipamentoDocumento(documentoId: number){
    return http.delete(`${this.GetNomeControle()}/equipamentoDocumento/${documentoId}`);
  }
}