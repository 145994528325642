
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { NFeDeclaracaoImportacao, TipoImportacao, TipoViaTransporteInternacional } from '@/core/models/dfe';
import { EnderecoService } from '@/core/services/finder';
import { TipoImportacaoService, TipoViaTransporteInternacionalService } from '@/core/services/dfe';

@Component
export default class CadastroNFeImportacao extends CrudBase {    
  @Prop() item!: NFeDeclaracaoImportacao;
  @Prop() private value!: string;

  enderecoService: EnderecoService = new EnderecoService();
  estados: Estado[] = [];

  tipoImportacaoService: TipoImportacaoService = new TipoImportacaoService();
  tipoImportacoes: TipoImportacao[] = [];

  tipoViaTransporteInternacionalService: TipoViaTransporteInternacionalService = new TipoViaTransporteInternacionalService();
  tipoViaTransporteInternacionais: TipoViaTransporteInternacional[] = [];

  $refs!: {
    form: HTMLFormElement
  }

  @Watch('value')
  Value(){
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.Carregar();
    }
    else{
      this.$emit("fechou");
    }
    if(this.$refs.form)
      this.$refs.form.resetValidation();
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  Carregar(){
    this.enderecoService.ObterEstados().then(
      res => this.estados = res.data.items,
      err => AlertSimpleErr("Aviso!", err)
    );
    this.tipoImportacaoService.ListarTudo().then(
      res => this.tipoImportacoes = res.data.items,
      err => AlertSimpleErr("Aviso!", err)
    );
    this.tipoViaTransporteInternacionalService.ListarTudo().then(
      res => this.tipoViaTransporteInternacionais = res.data.items,
      err => AlertSimpleErr("Aviso!", err)
    );
  }

  Salvar(){
    if(this.$refs.form.validate()){
      this.item.tipoImportacao = this.tipoImportacoes.find(x => x.id == this.item.tipoImportacaoId)!;
      this.item.tipoViaTransporteInternacional = this.tipoViaTransporteInternacionais.find(x => x.id == this.item.tipoViaTransporteInternacionalId)!;
      this.item.desenbaracoAduaneiro.estado = this.estados.find(x => x.id == this.item.desenbaracoAduaneiro.estadoId)!;
      this.Salvou();
    }
  }

  Salvou(){
    this.$emit("salvou");
    this.Close();
  }

  Close(){
    this.$emit("fechou");
    this.dialog = false;
  }
}
