
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Proposta, PropostaItem } from '@/core/models/engenhariaNaval';
import { PropostaService } from '@/core/services/engenhariaNaval';
import { PessoaService } from '@/core/services/geral';
import { Pessoa } from '@/core/models/geral';
import { Produto, UnidadeMedida } from '@/core/models/estoque';
import { ProdutoService, UnidadeMedidaService } from '@/core/services/estoque';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';

@Component
export default class CadastroProposta extends CrudBase{
    @Prop() item!: Proposta;
    @Prop() private value!: string;

    itemOriginal!: Proposta;
    service: PropostaService = new PropostaService();
    $refs!: {
        form: HTMLFormElement,
        formItens: HTMLFormElement
    }

    validItens: boolean = true;

    dialogCliente: boolean = false;    
    clienteId: number = -1;

    clienteService: PessoaService = new PessoaService();
    onSearchCliente: any = null;
    clientes: Pessoa[] = [];
    isClienteLoading: boolean = false;

    produtoServicoService: ProdutoService = new ProdutoService();
    onSearchProdutoServico: any = null;
    servicos: Produto[] = [];
    isProdutoLoading: boolean = false;

    unidadesMedida: UnidadeMedida[] = [];
    unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();

    propostaItem: PropostaItem = new PropostaItem();

    propostaItemHeaders: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action'},
        { text: 'Serviço', value:'produtoServico.nome'},
        { text: 'Unidade Medida', value:'unidadeMedida.siglaNome'},
        { text: 'Valor', value:'valor'},
        { text: 'Valor Hora Extra', value:'valorHoraExtra'}
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.clientes.push(this.item.cliente);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }        
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.item.clienteId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isClienteLoading = false;
        });
    }

    @Watch('onSearchProdutoServico')
    searchProduto (val: string) {
        if (this.propostaItem.produtoServicoId > 0) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoServicoService.AutoComplete(val).then(
            res => {
                this.servicos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        });
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.unidadeMedidaService.AutoComplete(true).then(
            res => {
                this.unidadesMedida = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    SelecionouServico(){
        if(this.propostaItem.produtoServicoId > 0){
            let servico = this.servicos.find(x => x.id == this.propostaItem.produtoServicoId);
            this.propostaItem.unidadeMedidaId = servico ? servico.unidadeMedidaId : 0;
            this.propostaItem.valor = servico ? servico.precoVenda : 0;
        }
    }

    AdicionarPropostaItem(){
        if (this.$refs.formItens.validate()) {
            if(this.item.itens.find(x => x.produtoServicoId == this.propostaItem.produtoServicoId && x.unidadeMedidaId == this.propostaItem.unidadeMedidaId)){
                AlertSimple("Aviso!", "Um item com a mesma unidade de medida já se encontra inserido!", "warning");                
            }
            else{
                this.propostaItem.produtoServico = this.servicos.find(x => x.id == this.propostaItem.produtoServicoId)!;
                this.propostaItem.unidadeMedida = this.unidadesMedida.find(x => x.id == this.propostaItem.unidadeMedidaId)!;
                this.item.itens.push(this.propostaItem);
                this.propostaItem = new PropostaItem();
                if (this.$refs.formItens)
                    this.$refs.formItens.resetValidation();                
            }
        }
    }

    ExcluirPropostaItem(item: PropostaItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    AbrirDialogPessoa(){
        this.clienteId = 0;
        this.dialogCliente = true;
    }

    SalvarPessoa(id: any){
        this.clienteService.ObterPorId(id).then(
            res => {
                this.item.clienteId = id;
                this.clientes.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {                    
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
