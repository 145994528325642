import { Pessoa } from "../geral";
import { MDFe } from "./MDFe";

export class MDFeCondutor implements Shared.IEntity {
    id: number = 0;
    MDFeId: number = 0;
    MDFe!: MDFe;
    condutorId: number = 0;
    condutor!: Pessoa;

    constructor(model?: MDFeCondutor) {
        if (!model)
            return;

            this.id = model.id;
            this.MDFeId = model.MDFeId;
            this.MDFe = model.MDFe;
            this.condutorId = model.condutorId;
            this.condutor = model.condutor;
    }
}