import { Encargo, InformacaoBancaria } from "./shared";
import { CobrancaConta } from "./shared/Cobranca";

export class ContaCorrente implements Shared.IEntity {
    
    id: number = 0;
    nome: string = '';
    informacaoBancaria: InformacaoBancaria = new InformacaoBancaria();
    cobranca: CobrancaConta = new CobrancaConta();
    encargoFinanceiro: Encargo = new Encargo();
    saldo: number = 0;
    interna: boolean = false;
    tipoId: number = 0;
    ativo: boolean = true;
    
    constructor(model?: ContaCorrente) {
        
        if (!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.informacaoBancaria = model.informacaoBancaria;
        this.cobranca = model.cobranca;
        this.encargoFinanceiro = model.encargoFinanceiro;
        this.saldo = model.saldo;
        this.interna = model.interna;
        this.tipoId = model.tipoId;
        this.ativo = model.ativo;
    }
}