
  import { Vue, Component, Prop, Watch } from "vue-property-decorator";
  import jiff from 'jiff';
  import { TipoEmbarcacao } from "@/core/models/transporte";
  import { TipoEmbarcacaoService } from "@/core/services/transporte";
  import { AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { CrudBase } from "@/core/models/shared";
  
  
  @Component
  export default class CadastroTipoEmbarcacao extends CrudBase {
    @Prop() item!: TipoEmbarcacao;
    @Prop() private value!: string;
  
    itemOriginal!: TipoEmbarcacao;
  
    loading: boolean = false;
    service = new TipoEmbarcacaoService();
    $refs!: {
      form: HTMLFormElement
    }
  
    @Watch('item')
    Item() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    }
  
    @Watch("value")
    Value() {
      this.dialog = this.value ? true : false;
  
      if (this.dialog){
        this.itemOriginal = jiff.clone(this.item);
      }
    }
  
    @Watch("dialog")
    Dialog() {
      if (!this.dialog) {
        this.$emit("fechou");
      }
    }
  
    beforeUpdate(){
      if (!this.dialog){
        this.$emit('fechou');
      }
    }
  
    Salvar() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
        (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
          res => {
            AlertSimpleRes("Aviso!", res);
            this.$emit("salvou");
            this.Close();
          },
          err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
          this.loading = false;
        });
      }
    }
  
    Close() {
      this.dialog = false;
    }
  }
  