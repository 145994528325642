import { render, staticRenderFns } from "./MasterBase.vue?vue&type=template&id=443fabc0&"
import script from "./MasterBase.vue?vue&type=script&lang=ts&"
export * from "./MasterBase.vue?vue&type=script&lang=ts&"
import style0 from "./MasterBase.vue?vue&type=style&index=0&id=443fabc0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports