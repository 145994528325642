import { OrcamentoContaGerencial, CentroCusto } from "./";

class Orcamento implements Shared.IEntity {
    
   id: number = 0;
   ano: number = 0;
   despesa: boolean = true;
   centroCustoId?: number;
   centroCusto?: CentroCusto;
   valor: OrcamentoValor = new OrcamentoValor();
   contasGerenciais: OrcamentoContaGerencial[] = [];

   constructor(model?: Orcamento) {

       if (!model)
           return;

       this.id = model.id;
       this.ano = model.ano;
       this.despesa = model.despesa;
       this.centroCustoId = model.centroCustoId;
       this.centroCusto = model.centroCusto;
       this.valor = new OrcamentoValor(model.valor);
       this.contasGerenciais = model.contasGerenciais.map(item => new OrcamentoContaGerencial(item));
   }

   private AtualizarTotalFilho(pai: OrcamentoContaGerencial){

    let filhos = this.contasGerenciais.filter(x => x.contaGerencial.contaGerencialPaiId == pai.contaGerencialId);

    filhos.forEach(filho => {

        if (!filho.check)
            filho.valor.ZerarValores();

        this.AtualizarTotalFilho(filho);

        pai.valor.janeiro += filho.valor.janeiro;
        pai.valor.fevereiro += filho.valor.fevereiro;
        pai.valor.marco += filho.valor.marco;
        pai.valor.abril += filho.valor.abril;
        pai.valor.maio += filho.valor.maio;
        pai.valor.junho += filho.valor.junho;
        pai.valor.julho += filho.valor.julho;
        pai.valor.agosto += filho.valor.agosto;
        pai.valor.setembro += filho.valor.setembro;
        pai.valor.outubro += filho.valor.outubro;
        pai.valor.novembro += filho.valor.novembro;
        pai.valor.dezembro += filho.valor.dezembro;
        pai.valor.AtualizarTotal();

    });

   }

   public AtualizarTotal(){
        
        this.valor = new OrcamentoValor();

        let raizes = this.contasGerenciais.filter(x => x.contaGerencial.contaGerencialPaiId == undefined);

        raizes.forEach(raiz => {
            
            raiz.valor.ZerarValores();

            this.AtualizarTotalFilho(raiz);

            this.valor.janeiro += raiz.valor.janeiro;
            this.valor.fevereiro += raiz.valor.fevereiro;
            this.valor.marco += raiz.valor.marco;
            this.valor.abril += raiz.valor.abril;
            this.valor.maio += raiz.valor.maio;
            this.valor.junho += raiz.valor.junho;
            this.valor.julho += raiz.valor.julho;
            this.valor.agosto += raiz.valor.agosto;
            this.valor.setembro += raiz.valor.setembro;
            this.valor.outubro += raiz.valor.outubro;
            this.valor.novembro += raiz.valor.novembro;
            this.valor.dezembro += raiz.valor.dezembro;
        });

        this.valor.AtualizarTotal();

   }
}

class OrcamentoValor {
    janeiro: number = 0;
    fevereiro: number = 0;
    marco: number = 0;
    abril: number = 0;
    maio: number = 0;
    junho: number = 0;
    julho: number = 0;
    agosto: number = 0;
    setembro: number = 0;
    outubro: number = 0;
    novembro: number = 0;
    dezembro: number = 0;
    total: number = 0;

    constructor(model?: OrcamentoValor){
        
        if (!model)
            return;
        
        this.janeiro = model.janeiro;
        this.fevereiro = model.fevereiro;
        this.marco = model.marco;
        this.abril = model.abril;
        this.maio = model.maio;
        this.junho = model.junho;
        this.julho = model.julho;
        this.agosto = model.agosto;
        this.setembro = model.setembro;
        this.outubro = model.outubro;
        this.novembro = model.novembro;
        this.dezembro = model.dezembro;
        this.total = model.total;

    }

    public AtualizarTotal(){
        this.total = this.janeiro + this.fevereiro + this.marco + this.abril + this.maio + this.junho + this.julho + this.agosto + this.setembro + this.outubro + this.novembro + this.dezembro;
    }

    public ZerarValores(){
        
        this.janeiro = 0;
        this.fevereiro = 0;
        this.marco = 0;
        this.abril = 0;
        this.maio = 0;
        this.junho = 0;
        this.julho = 0;
        this.agosto = 0;
        this.setembro = 0;
        this.outubro = 0;
        this.novembro = 0;
        this.dezembro = 0;

        this.AtualizarTotal();
    }
}

export { Orcamento, OrcamentoValor }