export class OperacaoBase {
    
    dataHora: string = new Date().toYYYYMMDD();
    usuarioId!: string;
    usuario: string = "";

    constructor(model?: OperacaoBase){

        if(!model)
            return;

        this.dataHora = model.dataHora ? model.dataHora.toDateTimeDDMMYYYY() : '';
        this.usuarioId = model.usuarioId;
        this.usuario = model.usuario;
    }
}