import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

export class RelatorioMedicaoService extends Service {

    constructor() {
        super('v1/relatorioMedicao')
    }

    private GetFiltro(parametros: string, filter: any) {

        if (filter) {
          let paramTemp = '';
    
          if (filter.numero) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `numero eq ${filter.numero}`;
          }
    
          if (filter.numeroRo) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `registroOperacao/numero eq ${filter.numeroRo}`;
          }

          if (filter.dataReferencia) {
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `dataReferencia eq ${filter.dataReferencia}`;
          }

          if(filter.clienteId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `itens/any(o: o/registroOperacaoEquipamento/registroOperacao/proposta/clienteId eq ${filter.clienteId})`;
          }

          if(filter.embarcacaoId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `itens/any(o: o/registroOperacaoEquipamento/registroOperacao/embarcacaoId eq ${filter.embarcacaoId})`;
          }

          if(filter.portoId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `itens/any(o: o/registroOperacaoEquipamento/registroOperacao/portoId eq ${filter.portoId})`;
          }

          if(filter.localOperacaoId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `itens/any(o: o/registroOperacaoEquipamento/registroOperacao/atracacao/localOperacaoId eq ${filter.localOperacaoId})`;
          }

          if(filter.usuarioId){
            if (paramTemp) { paramTemp += ' and '; }
            paramTemp += `aprovadorId eq ${filter.usuarioId}`;
          }
    
          if (paramTemp) {
    
            if (parametros) {
              parametros += '&';
            }
            else {
              parametros += '?';
            }
    
            parametros += `$filter=${paramTemp}`;
          }
    
        }
    
        return parametros;
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
    
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
      }

    public Gerar(dataReferencia: string) {
        return http.post(`${this.GetNomeControle()}/Gerar`, undefined, {
            params: {
              dataReferencia
            }
        });
    }

    public Aprovar(id: number) {
        return http.post(`${this.GetNomeControle()}/${id}/Aprovar`);
    }
}