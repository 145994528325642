import { TipoDocumento } from "./TipoDocumento";

export class ModeloSelecaoTipo implements Shared.IEntity {

    id : number = 0;
    tipoDocumento?: TipoDocumento;
    tipoDocumentoId: number = 0;

    constructor (model? : ModeloSelecaoTipo) {
        if (!model)
            return;

        this.id = model.id
        this.tipoDocumento = model.tipoDocumento
        this.tipoDocumentoId = model.tipoDocumentoId
    }
}