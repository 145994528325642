import { Receita } from ".";
import { Arquivo } from "../shared";

export class ReceitaDocumento implements Shared.IEntity{

    id: number = 0;
    receitaId: number = 0;
    receita!: Receita;
    link?: string;
    arquivo?: Arquivo;

    dados?: any;
    excluir: boolean = false;

    constructor(model?: ReceitaDocumento){

        if(!model)
            return;

        this.id = model.id;
        this.receitaId = model.receitaId;
        this.receita = model.receita;
        this.link = model.link;
        this.arquivo = model.arquivo;        
    }
}