export class NFeInformacaoAdicional {
    
  contribuinte: string = "";
  fisco: string = "";
  
  constructor(model?: NFeInformacaoAdicional) {
    
    if (!model)
      return;

    this.contribuinte = model.contribuinte;
    this.fisco = model.fisco;
  }
}