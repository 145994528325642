import { Pessoa } from "../../geral";
import { Veiculo } from "../../transporte";
import { ModeloSelecao } from "../ModeloSelecao";
import { ModeloSelecaoFuncionario } from "../ModeloSelecaoFuncionario";
import { ModeloSelecaoVeiculo } from "../ModeloSelecaoVeiculo";

class ModeloSelecaoModel {
    nome: string = "";
    descricao: string = "";
    modeloSelecao?: ModeloSelecao;
    modeloSelecaoId!: string;

    funcionariosSelecionados: ModeloSelecaoFuncionarioModel[] = [];
    veiculosSelecionados: ModeloSelecaoVeiculoModel[] = [];
}

class ModeloSelecaoFuncionarioModel {
    funcionarioId: number = 0;
    funcionario?: Pessoa;
}

class ModeloSelecaoVeiculoModel {
    veiculoId: number = 0;
    veiculo?: Veiculo;
}

export { ModeloSelecaoModel, ModeloSelecaoFuncionarioModel, ModeloSelecaoVeiculoModel }