export class Arquivo{

    nome: string = "";
    tamanho: number = 0;

    constructor(model?: Arquivo){

        if(!model)
            return;

        this.nome = model.nome;
        this.tamanho = model.tamanho;
    }
}