import { http } from "@/core/ApiConfig";
import { Service } from "../shared";
import { BoletimMedicaoAlteracoesModel } from "@/core/models/operacaoportuaria/model/BoletimMedicaoAlteracoesModel";

export class BoletimMedicaoService extends Service {

    constructor() {
        super('v1/boletimMedicao')
    }

    private GetFiltro(parametros: string, filter: any) {
        if (filter) {
            let paramTemp = '';
        
            if (filter.numero) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `numero eq ${filter.numero}`;
            }
        
            if (filter.clienteId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `proposta/clienteId eq ${filter.clienteId}`;
            }
        
            if (filter.propostaId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `proposta/id eq ${filter.propostaId}`;
            }
        
            if (filter.osNumero) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `equipamentos/any(o: o/sequencia eq ${filter.osNumero})`;
            }
        
            if (filter.embarcacaoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `embarcacaoId eq ${filter.embarcacaoId}`;
            }
        
            if (filter.situacaoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.situacaoId}`;
            }
        
            if (filter.responsavelId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `proposta/responsavelId eq ${filter.responsavelId}`;
            }
        
            if (filter.portoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `portoId eq ${filter.portoId}`;
            }
        
            if (filter.localOperacaoId) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `atracacao/localOperacaoId eq ${filter.localOperacaoId}`;
            }
        
            if (filter.atracacao != null) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `hasAtracacao eq ${filter.atracacao}`;
            }
        
            if (paramTemp) {
        
                if (parametros) {
                parametros += '&';
                }
                else {
                parametros += '?';
                }
        
                parametros += `$filter=${paramTemp}`;
            }
    
        }
    
        return parametros;
    }

    ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?: string) {

        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
    
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }

    public Criar(registroOperacaoId: number) {
        return http.post(`${this.GetNomeControle()}/Criar/${registroOperacaoId}`);
    }
    
    public Atualizacao(id: number, model: BoletimMedicaoAlteracoesModel) {
        return http.post(`${this.GetNomeControle()}/${id}/Atualizacao`, model);
    }

    public AtualizarProposta(id: number) {
        return http.post(`${this.GetNomeControle()}/${id}/AtualizarProposta`);
    }

    public Detalhar(id: number) {
        return http.get(`${this.GetNomeControle()}/${id}/Detalhar`);
    }

}