export class MeioPagamento implements Shared.IEntity{

  id: number = 0;
  nome: string = '';

  constructor(model?: MeioPagamento){

    if(!model)
      return;

    this.id = model.id;
    this.nome = model.nome;
  }
}