import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { NFeItem } from "@/core/models/dfe";

export class NFeService extends Service {

    constructor() {
        super('v1/nfe')
    }

    public AutoComplete(q: string, isInterno?: boolean, isComponetizado?: boolean) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q,
                isInterno: isInterno,
                isComponetizado: isComponetizado
            },
            headers: httpHeader.headers
        });
    }

    public CalcularTributos(valor: number, desconto:number, regraFiscalId: number) {
        return http.get(`${this.GetNomeControle()}/CalcularTributos`, {
            params: {
                valor: valor,
                desconto: desconto,
                regraFiscalId: regraFiscalId
            },
            headers: httpHeader.headers
        });
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';

            if(filter.dataEmissaoInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHoraEmissao ge ${filter.dataEmissaoInicial}`;
            }

            if(filter.dataEmissaoFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataHoraEmissao le ${filter.dataEmissaoFinal}`;
            }

            if(filter.numeroInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numero ge ${filter.numeroInicial}`;
            }

            if(filter.numeroFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numero le ${filter.numeroFinal}`;
            }

            if(filter.destinatarioId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `pessoaId eq ${filter.destinatarioId}`
            }

            if(filter.transportadorId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `transporte/transportadorId eq ${filter.transportadorId}`;
            }

            if(filter.veiculoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `transporte/veiculo/veiculoReboque/veiculoId eq ${filter.veiculoId}`;
            }

            if(filter.chaveAcesso){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `chaveAcesso eq ${filter.chaveAcesso}`;
            }

            if(filter.saida != null){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `saida eq ${filter.saida}`;
            }

            if(filter.situacaoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.situacaoId}`;
            }

            if (paramTemp) {

            if(parametros){
                parametros += '&';
            }
            else {
                parametros += '?';
            }

            parametros += `$filter=${paramTemp}`;
            }
        }
        
        return parametros;  
    }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);

        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    } 

    public AtualizarItem(item: NFeItem, estadoId: number){
        return http.post(`${this.GetNomeControle()}/AtualizarItem`, item, 
        {
            params: {
                estadoId
            },
            headers: httpHeader.headers
        });
    }

    public AtualizarTotais(itens: NFeItem[]){
        return http.post(`${this.GetNomeControle()}/AtualizarTotais`, itens, this.GetHeader());
    }

    public Enviar(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/Enviar`, null, 
        {
            headers: httpHeader.headers
        });
    }

    public Atualizar(id: number){
        return http.post(`${this.GetNomeControle()}/Atualizar`, null, 
        {
            params: {
                id
            },
            headers: httpHeader.headers
        });
    }

    public Cancelar(id: number, motivo: string){
        return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, null, 
        {
            params: {
                motivo
            }
        });
    }

    public Corrigir(id: number, motivo: string){
        return http.post(`${this.GetNomeControle()}/${id}/Corrigir`, null, 
        {
            params: {
                motivo
            }
        });
    }

    DanfePdf(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Danfe`, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf',
            }
        })
    }

    DanfeEventoPdf(id: number){
        return http.get(`${this.GetNomeControle()}/Evento/${id}/Danfe`, {
            responseType: 'arraybuffer',
            headers: {
                'Accept': 'application/pdf',
            }
        })
    }

    DownloadXml(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Xml`, {
            responseType: 'blob'
        })
    }

    DownloadEventoXml(id: number){
        return http.get(`${this.GetNomeControle()}/Evento/${id}/Xml`, {
            responseType: 'blob'
        })
    }

    public DownloadXmlMultiplo(ids: string[]){

        let model =  {
            ids: ids.join(',')
        };

        return http.post(`${this.GetNomeControle()}/xml/zipado2`,  model, {
            responseType: 'blob'
        });
    }

    public DownloadDanfeMultiplo(ids: string[]){

        let model =  {
            ids: ids.join(',')
        };

        return http.post(`${this.GetNomeControle()}/danfe/zipado`,  model, {
            responseType: 'blob'
        });
    }

    public RelatorioImprimir(chaveAcesso: string, numeroInicial?: number, numeroFinal?: number, dataEmissaoInicial?: string, dataEmissaoFinal?: string, destinatarioId?: number, transportadorId?: number, veiculoId?: number, saida?: boolean, situacaoId?: number) {
        return http.get(`${this.GetNomeControle()}/Relatorio/Imprimir`, {
            params: {
                numeroInicial,
                numeroFinal,
                dataEmissaoInicial,
                dataEmissaoFinal,
                destinatarioId,
                transportadorId,
                veiculoId,
                chaveAcesso,
                saida,
                situacaoId,
            },
            headers: httpHeader.headers
        });
    }
}