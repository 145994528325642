import { MedicaoItem, OrdemServico, OrdemServicoItem } from ".";

export class MedicaoItemEtapa implements Shared.IEntity {

    id: number = 0;
    medicaoItemId: number = 0;
    medicaoItem!: MedicaoItem;
    descricao: string = "";
    sequencia: number = 0;
    inicio: string = "";
    final?: string;

    vincular: boolean = true;

    //TELA
    paiId: number = 0;

    constructor(model?: MedicaoItemEtapa) {

        if (!model)
            return;

        this.id = model.id;
        this.medicaoItemId = model.medicaoItemId;
        this.medicaoItem = model.medicaoItem;
        this.descricao = model.descricao;
        this.sequencia = model.sequencia;
        this.inicio = model.inicio;
        this.final = model.final;

        this.vincular = true;
    }
}