import { Veiculo } from ".";

export class Tara implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    veiculoId: number = 0;
    veiculo!: Veiculo;
    peso: number = 0;
    
    constructor(model?: Tara) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.veiculoId = model.veiculoId;
        this.veiculo = model.veiculo;
        this.peso = model.peso;
    }
}