import { http } from '@/core/ApiConfig';

export class EnumeradoService{

    private _nomeControle: string = 'v1/enumerado/vendas';

    ListarLayoutImpressao(){
        return http.get(`${this._nomeControle}/LayoutImpressao`);
    }

}