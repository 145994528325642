import { http, httpHeader } from "@/core/ApiConfig";

export class RelatorioService {

    private _nomeControle: string = 'v1/financeiro/relatorio';
    
    public SaldoContaCorrente(competencia?: string, contaCorrenteId?: number, tipoId?: number) {
        return http.get(`${this._nomeControle}/saldoContaCorrente`, {
            params: {
                competencia,
                contaCorrenteId,
                tipoId
            }
        });
    }

    public SaldoContaCorrenteDashboard(competencia?: string, contaCorrenteId?: number, tipoId?: number) {
        return http.get(`${this._nomeControle}/saldoContaCorrente/dashboard`, {
            params: {
                competencia,
                contaCorrenteId,
                tipoId
            }
        });
    }

    public ResumoDiario(competencia: string) {
        return http.get(`${this._nomeControle}/ResumoDiario`, {
            params: {
                competencia
            }
        });
    }

    public ReceitaDespesa(visualizar: number, somenteValor: boolean, emissaoInicio?: string, emissaoFim?: string, vencimentoInicio?: string, vencimentoFim?: string, pagamentoInicio?: string, pagamentoFim?: string, centroCustoId?: number, contaGerencialId?: number, statusReceitaId?: number, statusDespesaId?: number) {
        return http.get(`${this._nomeControle}/ReceitaDespesa`, {
            params: {
                visualizar,
                somenteValor,
                emissaoInicio,
                emissaoFim,
                vencimentoInicio,
                vencimentoFim,
                pagamentoInicio,
                pagamentoFim,
                centroCustoId,
                contaGerencialId,
                statusReceitaId,
                statusDespesaId,
            }
        });
    }

    public ReceitaVenda(emissaoInicio?: string, emissaoFim?: string, recebimentoInicio?: string, recebimentoFim?: string, numeroIncio?: string, numeroFim?: string, 
        clienteId?: number) {
        return http.get(`${this._nomeControle}/ReceitaVenda`, {
            params: {
                emissaoInicio,
                emissaoFim,
                recebimentoInicio,
                recebimentoFim,
                numeroIncio,
                numeroFim,
                clienteId                
            }
        });
    }

    public RelatorioEspelho(emissaoInicio?: string, emissaoFim?: string, numInicio?: number, numFim?: number, clienteId?: number, contaGerencialId?: number, layoutId?: number) {
        return http.get(`${this._nomeControle}/RelatorioEspelho`, {
            params: {
                emissaoInicio,
                emissaoFim,
                numInicio,
                numFim,
                clienteId,
                contaGerencialId,
                layoutId
            }
        });
    }

    public OrcadoRealizado(ano: number, despesa: boolean, centroCustoId?: number) {
        return http.get(`${this._nomeControle}/OrcadoRealizado`, {
            params: {
                ano,
                despesa,
                centroCustoId
            }
        });
    }

    public OrcadoRealizadoDetalhado(ano: number, despesa: boolean, somenteAgrupador: boolean, somenteValor: boolean, centroCustoId?: number) {
        return http.get(`${this._nomeControle}/OrcadoRealizadoDetalhado`, {
            params: {
                ano,
                despesa,
                centroCustoId,
                somenteAgrupador,
                somenteValor
            }
        });
    }

    public Comissao(vendedorId?: number, dataInicial?: string, dataFinal?: string) {
        return http.get(`${this._nomeControle}/Comissao`, {
            params: {
                vendedorId,
                dataInicial,
                dataFinal
            }
        });
    }

}