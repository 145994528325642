
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { EnumTipoRegraFiscal, NFSe, NFSeTributo, RegraFiscal, SituacaoNFSe } from '@/core/models/dfe';
import { NFSeService, RegraFiscalService } from '@/core/services/dfe';
import { Pessoa } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { EnderecoService } from '@/core/services/finder';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';

@Component
export default class CadastroNFSe extends CrudBase{
    @Prop() item!: NFSe;
    @Prop() private value!: string;

    panel = [0];

    tomadorService: PessoaService = new PessoaService();
    onSearchCliente: any = null;
    tomador: Pessoa[] = [];
    isClienteLoading: boolean = false;

    regraFiscalService: RegraFiscalService = new RegraFiscalService();
    regrasFiscais: RegraFiscal[] = [];

    nfseTributo: NFSeTributo = new NFSeTributo();
    edita: boolean = false;
    dialogTributo: boolean = false;

    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    estados: Estado[] = [];
    municipios: Municipio[] = [];

    itemOriginal!: NFSe;
    service: NFSeService = new NFSeService();
    $refs!: {
        form: HTMLFormElement
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.tomador.push(this.item.tomador);
            this.estadoId = this.item.municipioPrestacao.estadoId;
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        if (this.item.tomadorId) return;
        if (this.isClienteLoading) return;
        if (!val) return;
        this.isClienteLoading = true
        this.tomadorService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.tomador = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isClienteLoading = false));
    }

    CarregarMunicipio(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipios = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    CalcularTributos(){

        this.service.CalcularTributos(this.item.valor, this.item.valorDesconto, this.item.regraFiscalId).then(
            res => {
                this.item.tributo = res.data.tributo;
                this.item.valorLiquido = res.data.valorLiquido;
            },
            err => AlertSimpleErr("Aviso!", err)
        );

    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {

    }

    Carregar() {
        this.enderecoService.ObterEstados().then(
            res => this.estados = res.data.items,
            err => AlertSimpleErr("Aviso!", err)

        );
        this.regraFiscalService.ListarPorTipo(EnumTipoRegraFiscal.NFSe).then(
            res => this.regrasFiscais = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.item.id = -1
        this.dialog = false;
    }
}
