
import { Component, Watch, Prop } from 'vue-property-decorator';
import { CrudBase } from '@/core/models/shared';
import { RegistroOperacao, RegistroOperacaoFuncionario} from '@/core/models/operacaoportuaria/';
import { RegistroOperacaoService } from '@/core/services/operacaoportuaria';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import jiff from 'jiff';
import { PessoaAutoCompleteModel } from '@/core/models/geral/models';
import { FuncaoService, PessoaService } from '@/core/services/geral';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { Funcao } from '@/core/models/geral';

@Component
export default class CadastroRegistroOperacaoFuncionario extends CrudBase{
@Prop() item!: RegistroOperacao;
@Prop() private value!: string;

itemOriginal!: RegistroOperacao;

service = new RegistroOperacaoService;
$refs!: {
    form: HTMLFormElement
}

registroOperacaoFuncionario: RegistroOperacaoFuncionario = new RegistroOperacaoFuncionario();

funcoes: Funcao[] = [];
funcaoService: FuncaoService = new FuncaoService();

onSearchFuncionario: any = null;
funcionarios: PessoaAutoCompleteModel[] = [];
isPessoaLoading: boolean = false;
pessoaService: PessoaService = new PessoaService();

headerFuncionario: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Funcionário', value: 'funcionario.cpfCnpjNome' },
    { text: 'Função', value:'funcao.nome'}
];

@Watch('item')
Item() {
    if (this.$refs.form) {
        this.$refs.form.resetValidation();
    }
}

@Watch("value")
Value() {
    this.dialog = this.value ? true : false;
    if (this.dialog){
        this.itemOriginal = jiff.clone(this.item);
    }
}

@Watch("dialog")
Dialog() {
    if (!this.dialog) {
        this.$emit("fechou");
    }
}

@Watch('onSearchFuncionario')
    SearchResponsavel (val: string) {
    if (this.isPessoaLoading) return;
    if (!val) return;
    if (this.registroOperacaoFuncionario.funcionarioId) return;
    this.isPessoaLoading = true;
    this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Funcionario, false, false).then(
        res => {
            this.funcionarios = res.data;
        },
        err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isPessoaLoading = false));
    }

beforeUpdate(){
    if (!this.dialog){
    this.$emit('fechou');
    }
}

mounted() {
    this.Carregar();
}

Carregar(){
    this.funcaoService.ListarTudo().then(
        res => this.funcoes = res.data.items,
        err => AlertSimpleErr("Aviso!", err)
    )
}

AdicionarFuncionario(){
    if (this.$refs.form.validate()) {

        if(this.item.funcionarios.find(x => x.funcionarioId == this.registroOperacaoFuncionario.funcionarioId)){
            AlertSimple("Aviso!", "Esse funcionário já foi incluído!", "warning");
            return;
        }

        this.registroOperacaoFuncionario.registroOperacaoId = this.item.id;
        
        this.registroOperacaoFuncionario.funcionario = this.funcionarios.find(x => x.id == this.registroOperacaoFuncionario.funcionarioId)!;
        this.registroOperacaoFuncionario.funcao = this.funcoes.find(x => x.id == this.registroOperacaoFuncionario.funcaoId)!;
        
        this.item.funcionarios.push(this.registroOperacaoFuncionario);
        
        this.registroOperacaoFuncionario = new RegistroOperacaoFuncionario();
        this.$refs.form.resetValidation();
    }
}

ExcluirFuncionario(registroOperacaoFuncionario: RegistroOperacaoFuncionario){
    const context = this;
    const excluir = function () {
    return new Promise( async function (resolve, reject){
        const index = context.item.funcionarios.indexOf(registroOperacaoFuncionario);
        context.item.funcionarios.splice(index, 1);
    });
    }
    AlertExcludeQuestion(excluir, true);
}

Salvar() {
    let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
    (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
        res => {
            AlertSimpleRes("Aviso", res);
            this.$emit("salvou");
            this.Close();
        },
        err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
        this.loading = false;
    });
}

Close() {
    this.dialog = false;
}
}
