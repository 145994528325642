
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { BoletimMedicao } from '@/core/models/operacaoportuaria';
import { BoletimMedicaoService } from '@/core/services/operacaoportuaria';
import { BoletimMedicaoAlteracoesModel, BoletimMedicaoEquipamentoAlteracoesModel, BoletimMedicaoFainaAlteracoesModel, BoletimMedicaoTernoAlteracoesModel } from '@/core/models/operacaoportuaria/model/BoletimMedicaoAlteracoesModel';
import { AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class CadastroBoletimMedicao extends CrudBase{
    @Prop() boletimMedicao!: BoletimMedicao;
    @Prop() private value!: string;

    service: BoletimMedicaoService = new BoletimMedicaoService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    ternoHeaders: any[] = [
        { text: 'Data', value: 'data' },
        { text: 'Terno', value: 'terno' },
        { text: 'Porão', value: 'porao' },
        { text: 'Desconto', value: 'desconto'}
    ]

    fainaHeaders: any[] = [
        { text: 'Nome', value: 'faina.nome' },
        { text: 'Quantidade', value: 'quantidade' },
        { text: 'Volume', value: 'volume' },
        { text: 'Unidade Medida', value: 'unidadeMedida.nome' },
        { text: 'Data/Hora Inicio', value: 'dataHoraInicio' },
        { text: 'Data/Hora Término', value: 'dataHoraTermino' },
        { text: 'Desconto', value: 'desconto' }
    ]

    equipamentoHeaders: any[] = [
        { text: '#', value: 'sequencia' },
        { text: 'Equipamento/Serviço', value: 'equipamento.nome' },
        { text: 'Fornecedor', value: 'fornecedor.cpfCnpjNome' },
        { text: 'Quantidade', value: 'quantidade' },
        { text: 'Data/Hora Inicio', value: 'dataHoraInicio' },
        { text: 'Data/Hora Término', value: 'dataHoraTermino' },
        { text: 'Valor Unitário', value: 'valorUnitario' },
        { text: 'Desconto', value: 'desconto' }
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('boletimMedicao.descontoGeral')
    WatchDescontoGeral()
    {
        if(this.boletimMedicao.descontoGeral < 0)
            this.boletimMedicao.descontoGeral = 0;
        if (this.boletimMedicao.descontoGeral > 100)
            this.boletimMedicao.descontoGeral = 100;
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    VerificarCadastro(){
        if( this.boletimMedicao.descontoGeral > 0 && 
        ( this.boletimMedicao.registroOperacao.ternos.some(x => x.desconto > 0) || 
            this.boletimMedicao.registroOperacao.fainas.some(x => x.desconto > 0) || 
            this.boletimMedicao.registroOperacao.equipamentos.some(x => x.desconto > 0))){
                AlertSimple("Aviso!", "Não é possível ter cadastrado 'Desconto Geral' e descontos unitários.", "warning");
                return true;
            }
        
        return false;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;

            //CASO RETORNE VERDADEIRO EXISTE ALGUMA VERIFICACAO NAO CUMPRIDA
            if(this.VerificarCadastro())
            {
                this.loading = false;
                return;
            }

            //MODEL
            var model = new BoletimMedicaoAlteracoesModel();
            model.detalharImposto = this.boletimMedicao.detalharImposto;
            model.descontoGeral = this.boletimMedicao.descontoGeral;
            model.descontoAtracacao = this.boletimMedicao.descontoAtracacao;
            model.descontoAmarracao = this.boletimMedicao.descontoAmarracao;
            
            //TERNOS
            model.ternos = [];
            this.boletimMedicao.registroOperacao.ternos.forEach(terno => {
                var _terno = new BoletimMedicaoTernoAlteracoesModel();
                _terno.id = terno.id;
                _terno.desconto = terno.desconto;
                model.ternos.push(_terno);
            });

            //FAINAS
            model.fainas = [];
            this.boletimMedicao.registroOperacao.fainas.forEach(faina => {
                var _faina = new BoletimMedicaoFainaAlteracoesModel();
                _faina.id = faina.id;
                _faina.desconto = faina.desconto;
                model.fainas.push(_faina);
            });

            //EQUIPAMENTOS
            model.equipamentos = [];
            this.boletimMedicao.registroOperacao.equipamentos.forEach(equipamento => {
                var _equipamento = new BoletimMedicaoEquipamentoAlteracoesModel();
                _equipamento.id = equipamento.id;
                _equipamento.desconto = equipamento.desconto;
                _equipamento.valor = equipamento.valorUnitario;
                model.equipamentos.push(_equipamento);
            });

            this.service.Atualizacao(this.boletimMedicao.id, model).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }
    
    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
