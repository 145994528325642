
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase, PageBase } from '@/core/models/shared';
import { EntradaNotaFiscalItem, Produto } from '@/core/models/estoque';
import { ProdutoService } from '@/core/services/estoque';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class CadastroEntradaNotaFiscalItem extends CrudBase {
    @Prop() item!: EntradaNotaFiscalItem;
    @Prop() private value!: string;    
    
    $refs!: {
        form: HTMLFormElement
    }

    produtoService: ProdutoService = new ProdutoService();
    isProdutoLoading: boolean = false;
    produtos: Produto[] = [];
    onSearchProduto: any = null;

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {
        
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if(this.item.produtoId){
            this.produtos.push(this.item.produto);
        }
    }

    @Watch('onSearchProduto')
    searchProduto (val: string) {
        if (this.item.produtoId) return;
        if (this.isProdutoLoading) return;
        if (!val) return;
        this.isProdutoLoading = true
        this.produtoService.AutoComplete(val).then(
            res => {
                this.produtos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.isProdutoLoading = false
        });
    }

    @Watch('item.produtoId')
    WatchProduto(){
        if(this.item.produtoId > 0){
            this.item.valorUnitario = this.produtos.find(x => x.id == this.item.produtoId)!.precoVenda;
        }
    }
    
    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.produto = this.produtos.find(x => x.id == this.item.produtoId)!;
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
}
