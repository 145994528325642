import { Municipio } from "../shared";
import { MDFe } from "./MDFe";

export class MDFeCarregamento implements Shared.IEntity {
    id: number = 0;
    MDFeId: number = 0;
    MDFe!: MDFe;
    municipioId: number = 0;
    municipio!: Municipio;

    constructor(model?: MDFeCarregamento){
        if (!model)
            return;

        this.id = model.id;
        this.MDFeId = model.MDFeId;
        this.MDFe = model.MDFe;
        this.municipioId = model.municipioId;
        this.municipio = model.municipio;
    }
}