export class PedidoAutoCompleteModel{

    id: number = 0;
    numero: number = 0;
    dataHora: string = new Date().toYYYYMMDD();
    fornecedor: string = '';

    numeroDataHora: string = `${this.numero} - ${this.dataHora}`;
    numeroFornecedor: string = `${this.numero} - ${this.fornecedor}`;
    numeroDataHoraFornecedor: string = `${this.numero} - ${this.dataHora} - ${this.fornecedor}`;

    constructor(model?: PedidoAutoCompleteModel){

        if(!model)
            return;

        this.id = model.id;
        this.numero = model.numero;        
        this.dataHora = model.dataHora;
        this.fornecedor = model.fornecedor

        this.numeroDataHora = model.numeroDataHora;
        this.numeroFornecedor = model.numeroFornecedor;
        this.numeroDataHoraFornecedor = model.numeroDataHoraFornecedor;
        
    }

    SetCalculados(){
    this.numeroDataHora = `${this.numero} - ${this.dataHora}`;
    this.numeroFornecedor = `${this.numero} - ${this.fornecedor}`;
    this.numeroDataHoraFornecedor = `${this.numero} - ${this.dataHora} - ${this.fornecedor}`;
    }
}