
import { PageBase } from '@/core/models/shared';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MasterPageCompras extends PageBase{
  @Prop({default: 'mdi-cart-arrow-down'}) icone!: string;
  @Prop({default: 'Compras'}) titulo!: string;
  @Prop({default: false}) public overlay!: boolean;
  
  menuOpts: any[] = [];

  created() {
    this.menuOpts = this.app.sistema.modulos.filter(x => x.termo == 'compras')[0].menuParents;
  }

  mounted() {
    
  }
}
