
export class CategoriaProduto implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    parentId?: number;
    parent!: CategoriaProduto;
    codigo: string = "";
    nome: string = "";
    nomeIngles?: string
    ativo: boolean = true;

    children: CategoriaProduto[] = [];

    constructor(model?: CategoriaProduto) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.parentId = model.parentId;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.nomeIngles = model.nomeIngles;
        this.ativo = model.ativo;

        this.children = model.children;        
    }
}