import { NFSeTributo, SituacaoNFSe, RegraFiscal,  } from ".";
import { Pessoa } from "../geral";
import { Operacao, Municipio } from "../shared";

export class NFSe implements Shared.IEntity{

  id: number = 0;
  empresaId!: string;
  numero: number = 0;
  numeroRps: number = 0;
  serieRps: number = 0;
  tomadorId: number = 0;
  tomador!: Pessoa;
  dataEmissao: string = new Date().toYYYYMMDD();
  regraFiscalId: number = 0;
  regraFiscal!: RegraFiscal;
  discriminacao: string = "";
  valor: number = 0;
  valorDesconto: number = 0;
  valorLiquido: number = 0;
  tributo: NFSeTributo = new NFSeTributo();
  municipioPrestacaoId?: number;
  municipioPrestacao!: Municipio;
  situacaoId: number = 0;
  situacao!: SituacaoNFSe;
  retornoConsulta: string = "";
  codigoVerificacao: string = "";
  protocolo: string = "";
  urlVisualizacao: string = "";
  xml: string = "";
  cancelamento: Operacao = new Operacao();

  constructor(model?: NFSe){

      this.situacaoId = 1;

      if(!model)
          return;

      this.id = model.id;
      this.empresaId = model.empresaId;
      this.numero = model.numero;
      this.numeroRps = model.numeroRps;
      this.serieRps = model.serieRps;
      this.tomadorId = model.tomadorId;
      this.tomador = model.tomador;
      this.regraFiscalId = model.regraFiscalId;
      this.regraFiscal = model.regraFiscal;
      this.dataEmissao = new Date(model.dataEmissao).toYYYYMMDD();
      this.discriminacao = model.discriminacao;
      this.valor = model.valor;
      this.valorDesconto = model.valorDesconto;
      this.valorLiquido = model.valorLiquido;
      this.situacaoId = model.situacaoId;
      this.situacao = model.situacao;
      this.retornoConsulta = model.retornoConsulta;
      this.codigoVerificacao = model.codigoVerificacao;
      this.protocolo = model.protocolo;
      this.urlVisualizacao = model.urlVisualizacao;
      this.xml = model.xml;
      this.tributo = model.tributo;
      this.municipioPrestacaoId = model.municipioPrestacaoId;
      this.municipioPrestacao = model.municipioPrestacao;
      this.cancelamento = model.cancelamento;
  }
}