
export class ContaGerencial implements Shared.IEntity {
    
    id: number = 0;
    codigo: string = '';
    nome: string = '';
    tipo: string = '';
    contaGerencialPaiId?: number;
    contaGerencialPai!: ContaGerencial;
    ativo: boolean = true;
    agrupador: boolean = false;
    codigoDescricao: string = '';
    
    children: ContaGerencial[] = [];

    constructor(model?: ContaGerencial) {
        
        if (!model)
            return;

        this.id = model.id;
        this.codigo = model.codigo;
        this.nome = model.nome;
        this.tipo = model.tipo;
        this.contaGerencialPaiId = model.contaGerencialPaiId;
        this.contaGerencialPai = model.contaGerencialPai;
        this.ativo = model.ativo;
        
        this.codigoDescricao = model.codigoDescricao;
        this.children = model.children;
        this.agrupador = model.agrupador;
    }
}