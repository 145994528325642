
export class Cfop implements Shared.IEntity{

    id: number = 0;
    nome: string = '';
    aplicacao: string = '';

    constructor(model?: Cfop){

        if(!model)
            return;

        this.id = model.id;
        this.nome = model.nome;
        this.aplicacao = model.aplicacao;
    }
}