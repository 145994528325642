
export class Parametro implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    
    cadastroCompartilhado: ParametroCadastroCompartilhado = new ParametroCadastroCompartilhado();

    constructor(model?: Parametro) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;

        this.cadastroCompartilhado = model.cadastroCompartilhado;
    }
}

export class ParametroCadastroCompartilhado{

    pessoa: boolean = false;
    segmento: boolean = false;
    regraFiscal: boolean = false;
    centroCusto: boolean = false;
    contaGerencial: boolean = false;
    produto: boolean = false;
    categoriaProduto: boolean = false;
    unidadeMedida: boolean = false;
    formaPagamento: boolean = false;
    condicaoPagamento: boolean = false;

    constructor(model?: ParametroCadastroCompartilhado){

        if(!model)
            return;

        this.pessoa = model.pessoa;
        this.segmento = model.segmento;
        this.regraFiscal = model.regraFiscal;
        this.centroCusto = model.centroCusto;
        this.contaGerencial = model.contaGerencial;
        this.produto = model.produto;
        this.categoriaProduto = model.categoriaProduto;
        this.unidadeMedida = model.unidadeMedida;
        this.formaPagamento = model.formaPagamento;
        this.condicaoPagamento = model.condicaoPagamento;
    }
}