export class Pais implements Shared.IEntity{

   id: number = 0;
   nome: string = '';

   constructor(model?: Pais){

       if(!model)
           return;

       this.id = model.id;
       this.nome = model.nome;
   }
}