import { Produto } from "../estoque";
import { Ocorrencia } from "./Ocorrencia";

export class OcorrenciaItem implements Shared.IEntity {
    id: number = 0;
    ocorrenciaId: number = 0;
    ocorrencia?: Ocorrencia;
    produtoServicoId: number = 0;
    produtoServico?: Produto;
    quantidade: number = 0;

    constructor(model? : OcorrenciaItem) {
        if (!model)
            return;

        this.id = model.id;
        this.ocorrenciaId = model.ocorrenciaId;
        this.ocorrencia = model.ocorrencia;
        this.produtoServicoId = model.produtoServicoId;
        this.produtoServico = model.produtoServico;
        this.quantidade = model.quantidade;
    }
}