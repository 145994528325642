

import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { ListBase } from '@/core/models/shared';
import { CentroCusto } from '@/core/models/financeiro';
import { AlertExcludeQuestion, AlertSimpleErr } from '@/core/services/shared/AlertService';
import { CentroCustoService } from '@/core/services/financeiro';

@Component
export default class ListaCentroCusto extends ListBase{
    @Prop({default: 'R'}) tipo!: string;
    
    lista: any[] = [];
    item: CentroCusto = new CentroCusto();
    service: CentroCustoService = new CentroCustoService();

    tipoDespesaReceita: boolean = false;

    tipos: any = [
        {id: 'R', nome: 'Receita'},
        {id: 'D', nome: 'Despesa'}
    ]

    dialogTransferir: boolean = false;

    @Watch("tipo")
    WatchTipo(){
        this.Listar();
    }

    mounted() {
        this.Listar();
    }

    Listar(){
        this.lista = [];
        this.loading = true;
        this.service.TreeView().then(
            res => {
                let treeAux = [{ id: -1,codigoDescricao: 'RAIZ',children: []}];
                treeAux[0].children = res.data;
                this.lista = treeAux;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
    }

    AbrirDialogCadastro(item?: CentroCusto){
        if(item){
            this.service.ObterPorId(item.id).then(
                res => {
                    this.item = new CentroCusto(res.data);
                },
                err => AlertSimpleErr("Aviso", err)
            )
        }
        else{
            this.item = new CentroCusto();
        }
        this.dialogCadastro = true;
    }

    AbrirDialogTransferir(item?: CentroCusto){
        if(item){
            this.service.ObterPorId(item.id).then(
                res => {
                    this.item = new CentroCusto(res.data);
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            )
        }
        else{
            this.item = new CentroCusto();
        }
        this.dialogTransferir = true;
    }

    AdicionarItem(item: CentroCusto){
        this.item = new CentroCusto();
        this.item.centroCustoPaiId = item.id;
        this.dialogCadastro = true;
    }

    Excluir(item: any){
        const service = this.service;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return res.data;
                        }
                    },
                    err => AlertSimpleErr("Aviso", err)
                )
            });
        }
        AlertExcludeQuestion(excluir).finally(() => {
            this.Listar();
        });
    }

    Imprimir() {
        let routeLink = this.$router.resolve(
            { 
                name: 'centroCustoImprimir',
            });

        window.open(routeLink.href, '_blank');
    }
}
