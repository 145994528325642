
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class StatusChip extends Vue {
    @Prop({required: true}) label!: string;
    @Prop({required: true}) fontColor!: string;
    @Prop({required: true}) fontSize!: string;
    @Prop({required: true}) backgroundColor!: string;    
}

