
import { RegistroOperacaoEquipamento } from "@/core/models/operacaoportuaria";
import { RegistroOperacaoService } from "@/core/services/operacaoportuaria";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class SelecionarRegistroOperacaoEquipamento extends Vue {
    @Prop() private selected!: RegistroOperacaoEquipamento;
    @Prop() private value!: boolean;    
    @Prop() registroOperacaoId!: number;
    @Prop() fornecedorId!: number;
    @Prop() equipamentoId!: number;

    dialog: boolean = false;
    loading: boolean = false;
    lista: RegistroOperacaoEquipamento[] = [];
    service: RegistroOperacaoService = new RegistroOperacaoService();

    localSelected: RegistroOperacaoEquipamento[] = [];
    headers: any[] = [
        { text: "", sortable: false },
        { text: '#RO', value: 'registroOperacional.numero' },
        { text: '#OS', value: 'sequencia' },
        { text: 'Equipamento/Fornecedor', value: 'equipamentoFornecedor' },
        { text: 'Data/Hora Solicitação', value: 'dataHoraInicio', type:'date' },
        { text: 'Data/Hora Liberação', value: 'dataHoraTermino', type:'date' },
        { text: 'Quantidade', value: 'quantidade', type:'number' }
    ];

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;        
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.localSelected.length = 0;        
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.loading = true;
        this.service.ListarEquipamentosSemPO(this.registroOperacaoId, this.fornecedorId, this.equipamentoId).then(
            res => {
                this.lista = res.data;
            },
            err=> AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
    }

    Selecionar() {
        this.$emit('update:selected', this.localSelected);
        if (this.localSelected.length > 0)
            this.$emit('selecionou');        
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
}
