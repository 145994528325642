
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Contrato, ContratoItem, ContratoPessoaEndereco, ModalidadeFrete, Pedido, PedidoItem, SituacaoPedido } from '@/core/models/vendas';
import { ContratoService, ModalidadeFreteService, PedidoItemService, PedidoService, SituacaoPedidoService } from '@/core/services/vendas';
import { CondicaoPagamento, FormaPagamento } from '@/core/models/financeiro';
import { CondicaoPagamentoService, FormaPagamentoService } from '@/core/services/financeiro';

@Component
export default class CadastroPedido extends CrudBase{
    @Prop() item!: Pedido;
    @Prop() private value!: string;

    panel = [0];

    itemOriginal!: Pedido;
    service: PedidoService = new PedidoService();
    validItem: boolean = true;
    $refs!: {
        form: HTMLFormElement,
        formItem: HTMLFormElement
    }

    pedidoItem: PedidoItem = new PedidoItem();
    contratoItem: ContratoItem = new ContratoItem();
    pedidoItemService: PedidoItemService = new PedidoItemService();

    contrato: Contrato = new Contrato();
    contratoService: ContratoService = new ContratoService();
    contratos: Contrato[] = [];
    contratoEnderecoEntregas: ContratoPessoaEndereco[] = [];

    contratoItens: ContratoItem[] = [];
    dialogContratoItem: boolean = false;

    isPessoaLoading: boolean = false;
    onSearchContrato: any = null;

    situacoes: SituacaoPedido[] = [];
    situacaoService: SituacaoPedidoService = new SituacaoPedidoService();

    formaPagamentos: FormaPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();

    condicaoPagamentos: CondicaoPagamento[] = [];
    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();
    
    modalidadeFretes: ModalidadeFrete[] = [];
    modalidadeFretesService: ModalidadeFreteService = new ModalidadeFreteService();

    header: any[] = [
        { text: '',value:'actions' ,sortable: false, class: 'action', use: true },
        { text: 'Produto', value: 'contratoItem.item.codigoNome', use: true },
        { text: 'Quantidade', value: 'quantidade', use: true },
        { text: 'Entregue', value: 'contratoItem.entregue', use: true },
    ];
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item.contratoId')
    watchContrato() {
        if(this.item.contratoId > 0) {
            this.loading = true;
            this.contratoService.ObterPorId(this.item.contratoId, 'EnderecoEntregas.PessoaEndereco.Endereco.Municipio.Estado, Itens.Item').then(
                res => {
                    this.contrato = new Contrato(res.data);
                    this.item.formaPagamentoId = this.contrato.formaPagamentoId;
                    this.item.condicaoPagamentoId = this.contrato.condicaoPagamentoId;
                    this.item.modalidadeFreteId = this.contrato.modalidadeFreteId;
                    this.contratoEnderecoEntregas = this.contrato.enderecoEntregas;
                    this.item.observacao = this.contrato.observacao;
                    this.item.observacaoDFe = this.contrato.observacaoDFe;
                    this.contratoItens = this.contrato.itens;               
                },
                err => AlertSimpleErr("Aviso", err)
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    @Watch('onSearchContrato')
    searchContrato (val: string) {
        if (this.item.contratoId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        this.isPessoaLoading = true
        this.contratoService.AutoComplete(val, '1').then(
            res => {
                this.contratos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }


    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.contratos.push(this.item.contrato);        
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formItem) {
            this.$refs.formItem.resetValidation();
        }
    }

    @Watch('pedidoItem.contratoItemId')
    contratoItemm(){
        let contrato = new Contrato();
        this.contratoService.ObterPorId(this.item.contratoId).then(
            res => {
                contrato = res.data;
                this.pedidoItem.contratoItem = this.contratoItens.find(x => x.id == this.pedidoItem.contratoItemId)!
                if (contrato.isPorQuantidade){
                    this.pedidoItem.quantidade = this.pedidoItem.contratoItem.saldo;
                }
            },
            err => {
                AlertSimpleErr("Aviso!", err)
            }
        )
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }


    Carregar() {
        this.situacaoService.ListarTudo().then(
            res => this.situacoes = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formaPagamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err) 
        )
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => this.condicaoPagamentos = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.modalidadeFretesService.ListarTudo().then(
            res => {
                this.modalidadeFretes = res.data.items,
                this.modalidadeFretes.pop();
                },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarPedidoItens() {
        if(this.$refs.formItem.validate()){

            if(this.item.itens.find(x => x.contratoItemId == this.pedidoItem.contratoItemId)){
                AlertSimple("Aviso!", "O item já se encontra inserido", "warning");
                this.pedidoItem = new PedidoItem();
                if (this.$refs.formItem) {
                    this.$refs.formItem.resetValidation();
                }    
                return;
            }

            this.pedidoItem.contratoItem = this.contratoItens.find(x => x.id == this.pedidoItem.contratoItemId)!
            this.item.itens.push(this.pedidoItem);
            this.pedidoItem = new PedidoItem();
            if (this.$refs.formItem) {
                this.$refs.formItem.resetValidation();
            }
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    ExcluirItens(item: PedidoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Close(){
        this.item.contratoId = -1
        this.contratos = []
        this.dialog = false;
    }
}
