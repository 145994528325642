import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class ContratoService extends Service {
    
    constructor() {
        super('v1/vendas/contrato');
    }

    public AutoComplete(q: string, situacoes?: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q,
                situacoes: situacoes
            },
            headers: httpHeader.headers
        });
    }

    AlterarQuantidadeItem(model: any){
        return http.put(`${this.GetNomeControle()}/AlterarQuantidadeItem/${model.contratoItemId}`, undefined, {
            params: {
                quantidade: model.quantidade
            },
            headers: httpHeader.headers
        });
    }

    ConcluirManualmente(id: number){
        return http.post(`${this.GetNomeControle()}/${id}/ConcluirManualmente`);
    }

    private GetFiltro(parametros: string, filter: any) {
        if (filter) {
            let paramTemp = '';
            if (!!filter.clienteId) {
                if (paramTemp) { paramTemp += ' and '}
                paramTemp += `clienteId eq ${filter.clienteId}`
            }

            if (!!filter.vendedorId) {
                if (paramTemp) { paramTemp += ' and '}
                paramTemp += `vendedorId eq ${filter.vendedorId}`
            }

            if (!!filter.descricao) {
                if (paramTemp) { paramTemp += ' and '}
                paramTemp += `Contains(descricao , '${filter.descricao}')`
            }

            if (filter.dataInicial) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora ge ${filter.dataInicial}`
            }
            if (filter.dataFinal) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora le ${filter.dataFinal}`
            }

            if (filter.ativo != null) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.ativo}`;
            }

            if (filter.numeroInicial > 0) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `numero ge ${filter.numeroInicial}`;
            }

            if (filter.numeroFinal > 0) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `numero le ${filter.numeroFinal}`;
            }

            if (paramTemp) {        
                if (parametros) {
                    parametros += '&';
                }
                else {
                    parametros += '?';
                }        
                parametros += `$filter=${paramTemp}`;
            }
        }
    
        return parametros;
    }
    
    public ListarComFiltro(page: number, pageSize: number, sortBy: any[], desc: any[], search: any, columns: any[], filter: any, include?) {
        let parametros = this.GetParamentroPaginacaoOrdenacao(page, pageSize, sortBy, desc, search, columns);
        parametros = this.GetFiltro(parametros, filter);
    
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    }
}