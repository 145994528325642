import { Cotacao } from ".";
import { Pessoa } from "../geral";
import { Pedido } from "../vendas";

export class CotacaoFornecedor implements Shared.IEntity {
    
    id: number = 0;
    cotacaoId: number = 0;
    cotacao!: Cotacao;
    fornecedorId: number = 0;
    fornecedor!: Pessoa;
    formaPagamento: string = "";
    observacao?: string;
    
    emails: string = "";
    token!: string;
    informado: boolean = false;
    vencedor: boolean = false;

    numeroFornecedor: string = "";

    constructor(model?: CotacaoFornecedor) {
        
        if (!model)
            return;

        this.id = model.id;
        this.cotacaoId = model.cotacaoId;
        this.cotacao = model.cotacao;
        this.fornecedorId = model.fornecedorId;
        this.fornecedor = model.fornecedor;
        this.formaPagamento = model.formaPagamento;
        this.observacao = model.observacao;
        
        this.emails = model.emails;
        this.token = model.token;
        this.informado = model.informado;
        this.vencedor = model.vencedor;

        this.numeroFornecedor = model.numeroFornecedor;
    }
}