
    import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
    import { CrudBase } from '@/core/models/shared';
    import { NFeTransporteVolume } from '@/core/models/dfe';
    
    @Component
    export default class CadastroNFeVolume extends CrudBase{
        @Prop() item!: NFeTransporteVolume;
        @Prop() private value!: string;
        
        $refs!: {
            form: HTMLFormElement
        }
        
        @Watch('value')
        Value(){
            this.dialog = this.value ? true : false;
        }
    
        @Watch("dialog")
        Dialog() {
            if (!this.dialog) {
                this.$emit("fechou");
            }
        }
    
        @Watch('item')
        ItemWatch(){
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        }
    
        beforeUpdate(){
            if (!this.dialog){
                this.$emit('fechou');
            }
        }
    
        Salvar(){
            this.$emit("salvou");
            this.Close();
        }
        
        Close(){
            this.$emit("fechou");
            this.dialog = false;
        }
    }
    