
export class CondicaoPagamento implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    nome: string = '';
    ativo: boolean = false;

    itens : CondicaoPagamentoItem[] = [];

    constructor(model?: CondicaoPagamento) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.nome = model.nome;
        this.ativo = model.ativo;

        this.itens = model.itens;
    }
}

export class CondicaoPagamentoItem implements Shared.IEntity {
    
    id: number = 0;
    dias: number = 0;

    constructor(model?: CondicaoPagamentoItem){

        if(!model)
            return;

        this.id = model.id;
        this.dias = model.dias;
    }
}