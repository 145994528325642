

import { TableToExcel } from "@/assets/scripts/helper";
import { AutenticadorService, EmpresaService } from "@/core/services/auth";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { VSkeletonLoader } from "vuetify/lib";

@Component
export default class RelatorioPage extends Vue {
    @Prop() titulo!: string;
    @Prop() subTitulo!: string;
    @Prop() listaSubTitulo!: string[];
    @Prop() marcaDagua?: string;
    @Prop({default: true}) fonteVariavel!: boolean;
    @Prop({default: true}) impressao!: boolean;
    @Prop() paisagem!: boolean;
    @Prop({default: true}) overlay!: boolean;
    @Prop({default: true}) hasCabecalho!: boolean;
    @Prop() exportarExcel!: boolean;
    @Prop({default: 100}) fonteInicial!: number;
    @Prop({ required: true, default: null }) empresaGuid!: any;

    root: any = document.documentElement;
    empresa: any = {
        empresaFoto: null,
        nome: '',
        foto: '',
        endereco: {
            logradouro: '',
            complemento: '',
            bairro: '',
            municipio: '',
            estado: ''
        }
    }

    created(){
        
    }

    @Watch('empresaGuid')
    WatchEmpresaGuid(){
        
        if(!this.empresaGuid)
            return;
        this.overlay = true;
        new EmpresaService().DadosEmpresa(this.empresaGuid).then(
            res => {
                this.empresa = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }

    mounted(){
        this.root = document.body;
        if(this.paisagem){
            this.root.style.setProperty("--widthPage", 29.7 + 'cm');
            this.root.style.setProperty("--min-heightPage", 19 + 'cm');
            this.setPageSize('landscape')
        }      
        else{
            this.root.style.setProperty("--widthPage", 21 + 'cm');
            this.root.style.setProperty("--min-heightPage", 26 + 'cm');
            this.setPageSize('portrait')
        }
        // INICIALIZAR FONT SIZE
        document.documentElement.style.setProperty("--fontSize", this.fonteInicial + '%');
    }

    Carregar(){
        
    }

    setPageSize(size){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    AumentarFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor += 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    DiminuirFonte(){
        var raiz = getComputedStyle(this.root);
        var valor = parseFloat(raiz.getPropertyValue('--fontSize').replace("%", ""));
        valor -= 5;
        document.documentElement.style.setProperty("--fontSize", valor + '%');
    }

    Imprimir(){
        window.print();
    }

    ExportarExcel(tabela: string, worksheet?: string){
        TableToExcel(tabela, worksheet);
    }

    Fechar(){
        window.close();
    }
    
}
