export class NFeItemInformacaoAdicional {

  texto: string = "";
  contribuinteIdentificacao: string = "";
  contribuinteConteudo: string = "";
  fiscoIdentificacao: string = "";
  fiscoConteudo: string = "";
  
  constructor(model?: NFeItemInformacaoAdicional) {

    if (!model)
      return;

    this.texto = model.texto;
    this.contribuinteIdentificacao = model.contribuinteIdentificacao;
    this.contribuinteConteudo = model.contribuinteConteudo;
    this.fiscoIdentificacao = model.fiscoIdentificacao;
    this.fiscoConteudo = model.fiscoConteudo;
  }
}