import { CertificadoDigital } from "../geral";

export class Parametro implements Shared.IEntity{

    id: number = 0;
    empresaId!: string;
    certificado?: CertificadoDigital;
    nFe: ParametroNFe = new ParametroNFe();
    nfSe: ParametroNFSe = new ParametroNFSe();
    cTe: ParametroCTe = new ParametroCTe();
    mdFe: ParametroMDFe = new ParametroMDFe();
    icms: ParametroIcms = new ParametroIcms();

    constructor(model?: Parametro){

        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.certificado = model.certificado;
        this.nFe = model.nFe;
        this.nfSe = model.nfSe;
        this.cTe = model.cTe;
        this.mdFe = model.mdFe;
        this.icms = model.icms;
    }
}

export class ParametroBaseDFe {

    serie: number = 0;
    numero: number = 0;
    ambienteProducao: boolean = true;
    observacao?: string;

    constructor(model?: ParametroBaseDFe){

        if(!model)
            return;

        this.serie = model.serie;
        this.numero = model.numero;
        this.ambienteProducao = model.ambienteProducao;
        this.observacao = model.observacao;
    }
}

export class ParametroNFe extends ParametroBaseDFe{

    constructor(model?: ParametroNFe){

        super(model);

        if(!model)
            return;
    }
}

export class ParametroNFSe extends ParametroBaseDFe{

    serieRps: number = 0;
    numeroRps: number = 0;

    constructor(model?: ParametroNFSe){

        super(model);

        if(!model)
            return;

        this.serieRps = model.serieRps;
        this.numeroRps = model.numeroRps;
    }
}

export class ParametroCTe extends ParametroBaseDFe{

    constructor(model?: ParametroCTe){

        super(model);

        if(!model)
            return;
    }
}

export class ParametroMDFe extends ParametroBaseDFe{

    constructor(model?: ParametroMDFe){

        super(model);

        if(!model)
            return;
    }
}

export class ParametroIcms {

    acre: number = 0;
    alagoas: number = 0;
    amapa: number = 0;
    amazonas: number = 0;
    bahia: number = 0;
    ceara: number = 0;
    espiritoSanto: number = 0;
    goias: number = 0;
    maranhao: number = 0;
    matoGrosso: number = 0;
    matoGrossoDoSul: number = 0;
    minasGerais: number = 0;
    para: number = 0;
    paraiba: number = 0;
    parana: number = 0;
    pernambuco: number = 0;
    piaui: number = 0;
    rioDeJaneiro: number = 0;
    rioGrandeDoNorte: number = 0;
    rioGrandeDoSul: number = 0;
    rondonia: number = 0;
    roraima: number = 0;
    santaCatarina: number = 0;
    saoPaulo: number = 0;
    sergipe: number = 0;
    tocantins: number = 0;
    distritoFederal: number = 0;

    acreFcp: number = 0;
    alagoasFcp: number = 0;
    amapaFcp: number = 0;
    amazonasFcp: number = 0;
    bahiaFcp: number = 0;
    cearaFcp: number = 0;
    espiritoSantoFcp: number = 0;
    goiasFcp: number = 0;
    maranhaoFcp: number = 0;
    matoGrossoFcp: number = 0;
    matoGrossoDoSulFcp: number = 0;
    minasGeraisFcp: number = 0;
    paraFcp: number = 0;
    paraibaFcp: number = 0;
    paranaFcp: number = 0;
    pernambucoFcp: number = 0;
    piauiFcp: number = 0;
    rioDeJaneiroFcp: number = 0;
    rioGrandeDoNorteFcp: number = 0;
    rioGrandeDoSulFcp: number = 0;
    rondoniaFcp: number = 0;
    roraimaFcp: number = 0;
    santaCatarinaFcp: number = 0;
    saoPauloFcp: number = 0;
    sergipeFcp: number = 0;
    tocantinsFcp: number = 0;
    distritoFederalFcp: number = 0;

    constructor(model?: ParametroIcms){

        if(!model)
            return;

        this.acre = model.acre;
        this.alagoas = model.alagoas;
        this.amapa = model.amapa;
        this.amazonas = model.amazonas;
        this.bahia = model.bahia;
        this.ceara = model.ceara;
        this.espiritoSanto = model.espiritoSanto;
        this.goias = model.goias;
        this.maranhao = model.maranhao;
        this.matoGrosso = model.matoGrosso;
        this.matoGrossoDoSul = model.matoGrossoDoSul;
        this.minasGerais = model.minasGerais;
        this.para = model.para;
        this.paraiba = model.paraiba;
        this.parana = model.parana;
        this.pernambuco = model.pernambuco;
        this.piaui = model.piaui;
        this.rioDeJaneiro = model.rioDeJaneiro;
        this.rioGrandeDoNorte = model.rioGrandeDoNorte;
        this.rioGrandeDoSul = model.rioGrandeDoSul;
        this.rondonia = model.rondonia;
        this.roraima = model.roraima;
        this.santaCatarina = model.santaCatarina;
        this.saoPaulo = model.saoPaulo;
        this.sergipe = model.sergipe;
        this.tocantins = model.tocantins;
        this.distritoFederal = model.distritoFederal;

        this.acreFcp = model.acreFcp;
        this.alagoasFcp = model.alagoasFcp;
        this.amapaFcp = model.amapaFcp;
        this.amazonasFcp = model.amazonasFcp;
        this.bahiaFcp = model.bahiaFcp;
        this.cearaFcp = model.cearaFcp;
        this.espiritoSantoFcp = model.espiritoSantoFcp;
        this.goiasFcp = model.goiasFcp;
        this.maranhaoFcp = model.maranhaoFcp;
        this.matoGrossoFcp = model.matoGrossoFcp;
        this.matoGrossoDoSulFcp = model.matoGrossoDoSulFcp;
        this.minasGeraisFcp = model.minasGeraisFcp;
        this.paraFcp = model.paraFcp;
        this.paraibaFcp = model.paraibaFcp;
        this.paranaFcp = model.paranaFcp;
        this.pernambucoFcp = model.pernambucoFcp;
        this.piauiFcp = model.piauiFcp;
        this.rioDeJaneiroFcp = model.rioDeJaneiroFcp;
        this.rioGrandeDoNorteFcp = model.rioGrandeDoNorteFcp;
        this.rioGrandeDoSulFcp = model.rioGrandeDoSulFcp;
        this.rondoniaFcp = model.rondoniaFcp;
        this.roraimaFcp = model.roraimaFcp;
        this.santaCatarinaFcp = model.santaCatarinaFcp;
        this.saoPauloFcp = model.saoPauloFcp;
        this.sergipeFcp = model.sergipeFcp;
        this.tocantinsFcp = model.tocantinsFcp;
        this.distritoFederalFcp = model.distritoFederalFcp;
    }
}