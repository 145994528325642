
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { PessoaService } from '@/core/services/geral';
import { Pessoa } from '@/core/models/geral';
import { Almoxarifado, EntradaNotaFiscal, EntradaNotaFiscalItem, TipoDocumentoEntradaNotaFiscal } from '@/core/models/estoque';
import { AlmoxarifadoService, EntradaNotaFiscalService, TipoDocumentoEntradaNotaFiscalService } from '@/core/services/estoque';
import { CentroCusto, CondicaoPagamento, ContaGerencial, Despesa, DespesaParcela, FormaPagamento, Receita, SituacaoDespesaParcela } from '@/core/models/financeiro';
import { CentroCustoService, CondicaoPagamentoService, ContaGerencialService, DespesaService, FormaPagamentoService } from '@/core/services/financeiro';
import { PedidoService } from '@/core/services/compras';
import { Pedido } from '@/core/models/compras';
import { AddDays } from '@/assets/scripts/helper';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { PedidoAutoCompleteModel } from '@/core/models/compras/model/PedidoAutoCompleteModel';

@Component
export default class CadastroEntradaNotaFiscal extends CrudBase{
    @Prop() item!: EntradaNotaFiscal;
    @Prop() value!: string;
    @Prop() visualizar!: boolean;

    itemOriginal!: EntradaNotaFiscal;
    service: EntradaNotaFiscalService = new EntradaNotaFiscalService();
    $refs!: {
        form: HTMLFormElement
    }

    entradaSelect: any = [ 
        {id: 1, nome: "Pedido"}, 
        {id: 2, nome: "Fornecedor"}
    ];

    tipoEntrada: number = 1;

    pessoaService: PessoaService = new PessoaService();
    isPessoaLoading: boolean = false;
    fornecedores: Pessoa[] = [];
    onSearchFornecedor: any = null;

    pedidoService: PedidoService = new PedidoService();
    isPedidoLoading: boolean = false;
    pedidos: any[] = [];
    onSearchPedido: any = null;
    editarProduto: boolean = false;

    almoxarifados: Almoxarifado[] = [];
    almoxarifadoService: AlmoxarifadoService = new AlmoxarifadoService();

    tipoDocumento: TipoDocumentoEntradaNotaFiscal = new TipoDocumentoEntradaNotaFiscal();
    dialogTipoDocumento: boolean = false;
    tipoDocumentos: TipoDocumentoEntradaNotaFiscal[] = [];
    tipoDocumentoService: TipoDocumentoEntradaNotaFiscalService = new TipoDocumentoEntradaNotaFiscalService();

    centroCustos: CentroCusto[] = [];
    centroCustoService: CentroCustoService = new CentroCustoService();

    contaGerenciais: ContaGerencial[] = [];
    contaGerencialService: ContaGerencialService = new ContaGerencialService();

    formaPagamentos: FormaPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();

    condicaoPagamentos: CondicaoPagamento[] = [];
    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();

    entradaNotaFiscalItem: EntradaNotaFiscalItem = new EntradaNotaFiscalItem();
    dialogCadastroItem: boolean = false;
    produtoHeaders: any[] = [
        { text: '', value: 'actions' },
        { text: 'Produto', value: 'produto.codigoNome' },
        { text: 'Unitário (R$)', value: 'valorUnitario' },
        { text: 'Quantidade', value: 'quantidade' },
        { text: 'Desconto', value: 'desconto' },
        { text: 'Frete', value: 'frete' },
        { text: 'Seguro', value: 'seguro' },
        { text: 'Outras', value: 'outrasDespesas' },
        { text: 'Total', value: 'valorTotal' }
    ];

    parcelaHeaders: any[] = [
        { text: 'Parcela', value: 'parcela', sortable:  false },
        { text: 'Valor', value: 'valor', sortable:  false },
        { text: 'Vencimento', value: 'dataVencimento', sortable:  false },
        { text: 'Situação', value: 'situacao.nome', sortable:  false }
    ];
    classificacaoHeaders: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Centro Custo', value: 'centroCusto.nome', sortable: false },
        { text: 'Conta Gerencial', value: 'contaGerencial.nome', sortable: false },
        { text: 'Valor', value: 'valor', sortable: false },
        { text: 'Percentual', value: 'percentual', sortable: false }
    ];
    
    gerarDespesa: boolean = false;
    despesaGerada: boolean = false;
    despesa: Despesa = new Despesa();
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.fornecedores.push(this.item.fornecedor!);
            if(this.item.pedidoId)
                this.pedidos.push(this.item.pedido);
        }
        else {
            this.despesa = new Despesa();
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if(this.item.pedidoId != null) {
            this.tipoEntrada = 1;
        }
        else {
            this.tipoEntrada = 2;
        }

        if(this.item.pedidoId != null) {
            this.pedidos = [];
            this.pedidoService.ObterPorId(this.item.pedidoId, "Itens.Produto, CotacaoFornecedor.Fornecedor, Fornecedor").then(
                res => {
                    let pedido = new Pedido(res.data);
                    let pedidoModel = new PedidoAutoCompleteModel();
                    pedidoModel.id = pedido.id;
                    pedidoModel.numero = pedido.numero;
                    pedidoModel.dataHora = pedido.dataHora;
                    pedidoModel.fornecedor = pedido.cotacaoFornecedorId ? pedido.cotacaoFornecedor.fornecedor.cpfCnpjNome : pedido.fornecedor.cpfCnpjNome;
                    pedidoModel.SetCalculados();
                    this.pedidos.push(pedidoModel);
                }
            )
        }
    }

    @Watch('onSearchFornecedor')
    searchFornecedor (val: string) {
        if (this.item.fornecedorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Fornecedor).then(
            res => {
                this.fornecedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }
    
    @Watch('onSearchPedido')
    searchPedidos (val: string) {
        if (this.item.pedidoId) return;
        if (this.isPedidoLoading) return;
        if (!val) return;
        this.isPedidoLoading = true
        this.pedidoService.AutoComplete(val, true).then(
            res => {
                this.pedidos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPedidoLoading = false));
    }

    @Watch('item.pedidoId')
    WatchPedido(){
        if(this.item.id == 0 && this.item.pedidoId){
            this.item.itens = [];
            this.pedidoService.ObterPorId(this.item.pedidoId, "Itens.Produto, Fornecedor, CotacaoFornecedor.Fornecedor").then(
                res => {
                    let pedido = new Pedido(res.data);
                    if (pedido.fornecedorId) {
                        this.item.fornecedorId = pedido.fornecedorId;
                        this.fornecedores.push(pedido.fornecedor);
                    }
                    else {
                        this.item.fornecedorId = pedido.cotacaoFornecedor.fornecedorId;
                        this.fornecedores.push(pedido.cotacaoFornecedor.fornecedor);
                    }
                    
                    if(pedido.condicaoPagamentoId)
                        this.item.condicaoPagamentoId = pedido.condicaoPagamentoId;

                    if(pedido.formaPagamentoId)
                        this.item.formaPagamentoId = pedido.formaPagamentoId;

                    pedido.itens.forEach(item => {
                        let entradaItem = new EntradaNotaFiscalItem();
                        entradaItem.produtoId = item.produtoId;
                        entradaItem.produto = item.produto;
                        entradaItem.quantidade = item[`saldo`];
                        entradaItem.valorUnitario = item.valor;
                        this.item.itens.push(entradaItem);
                    });
                },
                err => AlertSimpleErr("Aviso!", err)
            )

        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    AbrirDialogTipoDocumento(){
        this.tipoDocumento = new TipoDocumentoEntradaNotaFiscal();
        this.dialogTipoDocumento = true;
    }

    SalvarTipoDocumento() {
        this.tipoDocumentoService.ListarTudo().then(
            res => this.tipoDocumentos = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Carregar(){
        this.almoxarifadoService.ListarTudo().then(
            res => this.almoxarifados = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.tipoDocumentoService.ListarTudo().then(
            res => this.tipoDocumentos = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.centroCustoService.Listagem().then(
            res => this.centroCustos = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.contaGerencialService.ListagemSimples("D").then(
            res => this.contaGerenciais = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.formaPagamentoService.AutoComplete(true).then(
            res => this.formaPagamentos = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => this.condicaoPagamentos = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AdicionarProduto(item?: EntradaNotaFiscalItem){
        if(item){
            this.entradaNotaFiscalItem = item;
            this.entradaNotaFiscalItem.produto = item.produto;
            this.dialogCadastroItem = true;
            this.editarProduto = true;
        }
        else{
            this.entradaNotaFiscalItem = new EntradaNotaFiscalItem();
            this.entradaNotaFiscalItem.vendaId = this.item.id;
            this.dialogCadastroItem = true;
            this.editarProduto = false;
        }
    }

    SalvarProduto(){
        if(!this.editarProduto)
            this.item.itens.push(this.entradaNotaFiscalItem);
    }

    ExcluirProduto(item: any){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    GerarDespesa(){
        this.despesa = new Despesa();
        this.despesa.descricao = `Gerado via entrada ${this.tipoDocumentos.find(x => x.id == this.item.tipoDocumentoId)!.nome}`;
        this.despesa.numero = parseInt(this.item.numero);
        this.despesa.dataEmissao = this.item.dataEmissao;
        this.despesa.pessoaId = this.item.fornecedorId;
        this.despesa.pessoa = this.fornecedores.find(x => x.id == this.despesa.pessoaId)!;
        this.despesa.valor = this.item.itens.reduce((a, b) => a + b.CalcularValor(), 0);
        this.despesa.parcelas = [];
        let condicao = this.condicaoPagamentos.find(x => x.id == this.item.condicaoPagamentoId)!;
        let qtdParcelas = condicao.itens.length;
        new DespesaService().VisualizarParcelas(this.despesa.valor, qtdParcelas, 1, AddDays(this.item.dataEmissao, condicao.itens[0].dias).toYYYYMMDD(), this.despesa.parcelas, false).then(
            res => {
                this.despesa.parcelas = res.data;
                this.despesa.parcelas.forEach(parcela => {
                    parcela.situacao = new SituacaoDespesaParcela();
                    parcela.situacao.id = 1;
                    parcela.situacao.nome = "Aberto";
                });
                this.gerarDespesa = true;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    EditarDespesa(){
        this.despesa = new Despesa(this.item.despesa);
        this.gerarDespesa = true;
    }

    CarregarDespesa()
    {
        this.gerarDespesa = false;
        this.despesaGerada = true;
        this.item.despesa = this.despesa;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            if(this.item.itens.length > 0){
                this.loading = true;
                let patchModel = jiff.diff(this.itemOriginal, this.item, false);
                (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                    res => {
                        AlertSimpleRes("Aviso", res);
                        this.$emit("salvou");
                        this.Close();
                    },
                    err => AlertSimpleErr("Aviso", err)
                ).finally(() => {
                    this.loading = false;
                })
            }
            else {
                AlertSimple("Aviso!", "Nenhum produto foi adicionado", "warning");
            }
        }
    }
    
    Close(){
        this.visualizar = false;
        this.dialog = false;
    }
}
