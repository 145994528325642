
import { CrudBase } from '@/core/models/shared';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { AutenticadorService, EmpresaService } from '@/core/services/auth';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class AlterarEmpresa extends CrudBase{
    @Prop() private value!: string;    
    
    service: AutenticadorService = new AutenticadorService();
    empresaService: EmpresaService = new EmpresaService();

    usuarioEmpresasFilter: any[] = [];
    usuarioEmpresas: any[] = [];

    search: string = "";

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch("search")
    WatchSearch(val: string){
        if(!!val){
            this.usuarioEmpresasFilter = this.usuarioEmpresas.filter(x => x.nome.toLowerCase().includes(this.search.toLowerCase()));
        }
        else{
            this.usuarioEmpresasFilter = this.usuarioEmpresas;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    mounted() {

    }

    Carregar(){
        this.loading = true;
        this.empresaService.UsuarioEmpresa().then(
            res => {
                this.usuarioEmpresas = res.data;
                this.usuarioEmpresasFilter = this.usuarioEmpresas;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
    }

    AlterarEmpresa(empresaId: string){
        this.service.TrocarEmpresa(empresaId).then(
            res => {
                //PRECISA MELHORAR A FORMA DE PEGAR O MODULO ATUAL
                let param = this.$route.params.modulo;
                if(param == null || param == undefined || !(param.length > 0)){
                    param = this.$route.path.split("/")[1];
                }
                else if(param.includes("@")){
                    param = "geral";
                }
                let routeLink = this.$router.resolve({
                    path: `/auth/${param}`,
                    query: {
                        token: res.data.token
                    }
                });
                window.open(routeLink.href, '_parent');
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Clear(){
        this.usuarioEmpresas = [];
    }

    Close(){
        this.Clear();
        this.dialog = false;
    }
}
