import { Produto, UnidadeMedida } from "../estoque";
import { PropostaEngenhariaNaval } from "./Proposta";

export class PropostaItem implements Shared.IEntity{

    id: number = 0;
    propostaId: number = 0;
    proposta!: PropostaEngenhariaNaval;
    produtoServicoId: number = 0;
    produtoServico!: Produto;
    unidadeMedidaId: number = 0;
    unidadeMedida!: UnidadeMedida;
    valor: number = 0;
    valorHoraExtra: number = 0;

    constructor(model?: PropostaItem) {
    
        if(!model)
            return;

        this.id = model.id;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;
        this.produtoServicoId = model.produtoServicoId;
        this.produtoServico = model.produtoServico;
        this.unidadeMedidaId = model.unidadeMedidaId;
        this.unidadeMedida = model.unidadeMedida;
        this.valor = model.valor;
        this.valorHoraExtra = model.valorHoraExtra;
    }
}