import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { Parametro } from "@/core/models/documentos";

export class DocumentosService {

    private _nomeControle: string = 'v1/documentos'

    //PARAMETRO
    public GetParametro() {
        return http.get(`${this._nomeControle}/parametro`, httpHeader);
    }

    public SaveParametro(model: Parametro) {
        return http.put(`${this._nomeControle}/parametro`, model);
    }
}