import { http } from "@/core/ApiConfig";
import { Service } from "../shared";
import { MedicaoItemEtapa } from "@/core/models/engenhariaNaval";

export class MedicaoService extends Service {

    constructor() {
        super('v1/medicao');
    }

    VincularOrdemServicoItem(id: number, ordemServicoId: number, ordemServicoItemId: number, vincular: boolean, etapas?: MedicaoItemEtapa[]) {
        return http.post(`${this.GetNomeControle()}/${id}/VincularOrdemServicoItem`, etapas, {
            params: {
                ordemServicoId,
                ordemServicoItemId,
                vincular
            }
        });
    }

    Detalhar(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/Detalhar`);
    }

}