
import { Component, Prop, Watch } from "vue-property-decorator";
import { CrudBase, PageBase } from '@/core/models/shared';
import { PropostaEquipamento } from "@/core/models/operacaoportuaria/PropostaEquipamento";
import { Produto, UnidadeMedida } from "@/core/models/estoque";
import { ProdutoService, UnidadeMedidaService } from "@/core/services/estoque";

@Component
export default class CadastroPropostaOperacaoPortuariaEquipamento extends CrudBase{
    @Prop() item!: PropostaEquipamento;
    @Prop() private value!: string;

    dialog: boolean = false;
    validDialog: boolean = true;
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    $refs!: {
        form: HTMLFormElement
    }

    equipamentos: Produto[] = [];
    equipamentoService: ProdutoService = new ProdutoService();

    unidadesMedida: UnidadeMedida[] = [];
    unidadeMedidaService: UnidadeMedidaService = new UnidadeMedidaService();
    
    @Watch('item')
    Item(){
        if(this.$refs.form){
            this.$refs.form.resetValidation();
        }
    }

    @Watch("value")
    Value(){
        this.dialog = this.value ? true : false;

        if(this.dialog){
            this.Carregar();
        }
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    beforeUpdate() {
        if(!this.dialog){
            this.$emit("fechou");
        }
    }

    Carregar(){
        this.equipamentoService.ListarTudo().then(
            res => {
                this.equipamentos = res.data.items;
            }
        )
        this.unidadeMedidaService.ListarTudo().then(
            res => {
                this.unidadesMedida = res.data.items;
            }
        )
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.equipamento = this.equipamentos.find(x => x.id == this.item.equipamentoId)!;
            this.item.regraCobranca = this.unidadesMedida.find(x => x.id == this.item.regraCobrancaId)!;
            this.Salvou();
        }
    }  

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close() {
        this.dialog = false;
    }
}   
