import { http, httpHeader } from "@/core/ApiConfig";

export class RelatorioService {

    private _nomeControle: string = 'v1/estoque/relatorio';
    
    public EstoqueMinimo() {
        return http.get(`${this._nomeControle}/EstoqueMinimo`);
    }

    public ValorPorAlmoxarifado() {
        return http.get(`${this._nomeControle}/ValorPorAlmoxarifado`);
    }

    public MaisMovimentados(quantidade: number) {
        return http.get(`${this._nomeControle}/MaisMovimentados`, {
            params: {
                quantidade
            }
        });
    }

    public ValorEstoquePorCategoria() {
        return http.get(`${this._nomeControle}/ValorEstoquePorCategoria`);
    }
}