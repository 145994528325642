
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class CadastroCotacao extends Vue{
    @Prop({default: true}) fullScreen!: boolean;
    @Prop({default: false}) dialog!: boolean;
    @Prop({default: "Cadastro"}) titulo!: string;
    @Prop({default: false}) extensions!: boolean;
    @Prop({default: true}) valid!: boolean;
    @Prop({default: false}) overlay!: boolean;

    Close(){
        this.$emit("fechar");
    }

    Salvar(){
        this.$emit("salvar");
    }
}
