import { http } from "@/core/ApiConfig";
import { TransferenciaModel } from "@/core/models/estoque/model";
import { Service } from "../shared";

export class MovimentacaoService extends Service {
    
    constructor() {
        super('v1/movimentacao');
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';
            
            if (filter.dataEmissaoInicio){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora ge ${filter.dataEmissaoInicio}`;
            }

            if (filter.dataEmissaoFim){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataHora le ${filter.dataEmissaoFim}`;
            }
            
            if (filter.almoxarifadoId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `almoxarifadoItem/id eq ${filter.almoxarifadoId}`; 
            }

            if (filter.produtoId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `almoxarifadoItem/produtoId eq ${filter.produtoId}`;
            }

            if (filter.descricao){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `Contains(descricao, '${filter.descricao}')`;
            }

            if (filter.tipo) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `tipoId eq ${filter.tipo}`
            }

            if (filter.origem) {
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `tipoOrigemId eq ${filter.origem}`
            }
    
            if (paramTemp) {  
                if(parametros)
                    parametros += '&';            
                else
                    parametros += '?';            

                parametros += `$filter=${paramTemp}`;
            }  
        }  
        return parametros;
    }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);
        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    } 

    public Transferir(item: TransferenciaModel){
        return http.post(`${this.GetNomeControle()}/transferir`, item);
    }

    public ObterCustoMedio(produtoId: number, dataCompetencia: string) {
        return http.get(`${this.GetNomeControle()}/ObterCustoMedio`, {
            params: {
                produtoId,
                dataCompetencia,
            }
        });
    }

    public Recalcular(produtoId: number) {
        return http.post(`${this.GetNomeControle()}/Recalcular/${produtoId}`);
    }

    public Relatorio(dataInicial?: string, dataFinal?: string, produtoId?: number, almoxarifadoId?: number, categoriaProdutoId?: number, valorInicial?: number, valorFinal?: number){
        return http.get(`${this.GetNomeControle()}/Relatorio/Movimentacao/Visualizacao`, {
            params: {
                dataInicial,
                dataFinal,
                produtoId,
                almoxarifadoId,
                categoriaProdutoId,
                valorInicial,
                valorFinal,
            }
        });
    }
}