import { Produto } from "../estoque";
import { Pessoa } from "../geral";
import { RegistroOperacao } from "./";

export class RegistroOperacaoEquipamento implements Shared.IEntity {
    
    id: number = 0;
    sequencia: number = 0;
    registroOperacaoId: number = 0;
    registroOperacao!: RegistroOperacao;
    equipamentoId: number = 0;
    equipamento!: Produto;
    fornecedorId?: number;
    fornecedor!: Pessoa;
    quantidade: number = 1;
    dataHoraInicio: string = '';
    dataHoraTermino?: string;
    valorUnitario: number = 0;
    desconto: number = 0;
    observacao: string = '';
    avulso: boolean = false;
    
    verificado: boolean = false;
    alterado: boolean = false;

    equipamentoFornecedor!: string;

    constructor(model? : RegistroOperacaoEquipamento) {
        
        if (!model)
            return;

        this.id = model.id;
        this.sequencia = model.sequencia;
        this.registroOperacaoId = model.registroOperacaoId;
        this.registroOperacao = model.registroOperacao;
        this.equipamentoId = model.equipamentoId;
        this.equipamento = model.equipamento;
        this.fornecedorId = model.fornecedorId;
        this.fornecedor = model.fornecedor;
        this.quantidade = model.quantidade;
        this.dataHoraInicio = model.dataHoraInicio.toDateTimeDDMMYYYY();
        this.dataHoraTermino = model.dataHoraTermino ? model.dataHoraTermino.toDateTimeDDMMYYYY() : '';
        this.valorUnitario = model.valorUnitario;
        this.desconto = model.desconto;
        this.observacao = model.observacao;
        this.avulso = model.avulso;

        this.verificado = model.verificado;
        this.alterado = model.alterado;

        this.equipamentoFornecedor = model.equipamentoFornecedor;
    }
}