import { IndicadorFormaPagamento, MeioPagamento } from ".";

export class NFeInformacaoPagamento implements Shared.IEntity{

  id: number = 0;
  meioPagamentoId: number = 0;
  meioPagamento!: MeioPagamento;
  indicadorFormaPagamentoId?: number;
  indicadorFormaPagamento!: IndicadorFormaPagamento;
  valor: number = 0;
  descricaoMeioPagamento?: string;

  constructor(model?: NFeInformacaoPagamento){

    if(!model)
      return;

    this.id = model.id;
    this.meioPagamentoId = model.meioPagamentoId;
    this.meioPagamento = model.meioPagamento;
    this.indicadorFormaPagamentoId = model.indicadorFormaPagamentoId;
    this.indicadorFormaPagamento = model.indicadorFormaPagamento;
    this.valor = model.valor;
    this.descricaoMeioPagamento = model.descricaoMeioPagamento;
  }
}