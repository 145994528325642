
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import { EnderecoService } from '@/core/services/finder';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Contrato, ContratoItem, ModalidadeFrete, Pedido, PedidoItem, TabelaPreco, Venda, VendaItem } from '@/core/models/vendas';
import { ContratoService, PedidoService, VendaService } from '@/core/services/vendas';
import { Pessoa, PessoaEndereco } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { CondicaoPagamento, FormaPagamento } from '@/core/models/financeiro';
import { CondicaoPagamentoService, FormaPagamentoService } from '@/core/services/financeiro';
import { ModalidadeFreteService } from '@/core/services/vendas';
import { VeiculoService } from '@/core/services/transporte';
import { Veiculo } from '@/core/models/transporte';
import { RegraFiscalService, NFSeService } from '@/core/services/dfe';
import { RegraFiscal, NFSeTributo } from '@/core/models/dfe';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';

@Component
export default class CadastroVendaServico extends CrudBase{
    @Prop() item!: Venda;
    @Prop() private value!: string;

    tipoVenda: number = 0;

    itemOriginal!: Venda;
    service: VendaService = new VendaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    panel: any = [0];

    tributosServico: NFSeTributo = new NFSeTributo();

    pessoaService: PessoaService = new PessoaService();
    regraFiscalService: RegraFiscalService = new RegraFiscalService();
    nfseService: NFSeService = new NFSeService();
    isPessoaLoading: boolean = false;
    clientes: Pessoa[] = [];
    cliente: Pessoa = new Pessoa();
    onSearchCliente: any = null;
    vendedores: Pessoa[] = [];
    onSearchVendedor: any = null;
    transportadores: Pessoa[] = [];
    onSearchTransportador: any = null;
    motoristas: Pessoa[] = [];
    regrasFiscais: RegraFiscal[] = [];
    onSearchMotorista: any = null;

    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    estados: Estado[] = [];
    municipios: Municipio[] = [];

    isVeiculoLoading: boolean = false;
    onSearchVeiculo: any = null;
    veiculoService: VeiculoService = new VeiculoService();
    veiculos: Veiculo[] = [];
    
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();
    formaPagamentos: FormaPagamento[] = [];

    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();
    condicoes: CondicaoPagamento[] = [];

    modalidadeFreteService: ModalidadeFreteService = new ModalidadeFreteService();
    modalidadeFretes: ModalidadeFrete[] = [];

    isContratoLoading: boolean = false;
    contratoService: ContratoService = new ContratoService();
    contratos: Contrato[] = [];
    contratoItens: ContratoItem[] = [];
    onSearchContrato: any = null;

    isPedidoLoading: boolean = false;
    pedidoService: PedidoService = new PedidoService();
    pedidos: Pedido[] = [];
    pedidoItens: PedidoItem[] = [];
    onSearchPedido: any = null;

    tabelaPreco: TabelaPreco = new TabelaPreco();

    locaisEntregas: any[] = [];

    dialogVendaItem: boolean = false;
    vendaItem: VendaItem = new VendaItem();
    valorLiquido: number = 0;
    produtoHeader: any[] = [
        { text: '', value: 'actions' },
        { text: 'Serviço', value: 'produto.codigoNome' },
        { text: 'Unitário', value: 'valorUnitario' },
        { text: 'Qtd', value: 'quantidade' },
        { text: 'Desconto', value: 'desconto' },
        { text: 'Total', value: 'valorTotal' }
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.estadoId = this.item.servico.municipioPrestacao ? this.item.servico.municipioPrestacao.estadoId : 0;
        }
        else {
            this.estadoId = 33;
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }

        if(this.item.clienteId > 0){
            this.clientes.push(this.item.cliente);
        }

        if(this.item.comercial.vendedorId){
            this.vendedores.push(this.item.comercial.vendedor);
        }
    }

    @Watch("tipoVenda")
    WatchTipoVenda(){
        this.item = new Venda();        
        this.locaisEntregas = [];
        this.pedidoItens = [];
        this.contratoItens = [];
        this.vendedores = [];
        // this.item.clienteId = 0;
        // this.item.cliente = undefined;
        // this.item.contratoId = undefined;
        // this.item.contrato = undefined;
        // this.item.pedidoId = undefined;
        // this.item.pedido = undefined;
        // this.item.comercial.vendedorId = undefined;
        // this.item.formaPagamentoId = 0;
        // this.item.condicaoPagamentoId = 0;
        // this.item.enderecoEntregaId = undefined;
    }

    @Watch('item.clienteId')
    WatchCliente(){
        if(this.item.clienteId > 0) {
            this.pessoaService.ObterPorId(this.item.clienteId, "Enderecos.Endereco.Municipio.Estado, Endereco.Municipio.Estado, ProdutosServicos.ProdutoServico, Cliente.Comercial.TabelaPreco.Itens.Item").then(
                res => {
                    this.cliente = new Pessoa(res.data)
                    this.estadoId = this.cliente.endereco.municipio.estadoId;
                    this.item.servico.municipioPrestacaoId = this.cliente.endereco.municipioId;
                    if(!this.item.contratoId){
                        this.locaisEntregas = [];
                        this.locaisEntregas = this.cliente.enderecos;
                        this.locaisEntregas.unshift({
                            id: undefined,
                            endereco: {
                                enderecoSemCep: "O mesmo do principal"
                            }
                        })
                    }
                    if(this.cliente.cliente.comercial.tabelaPrecoId)
                        this.tabelaPreco = this.cliente.cliente.comercial.tabelaPreco;
                    
                    else if(!this.cliente.cliente.comercial.tabelaPrecoId)
                        this.tabelaPreco = new TabelaPreco();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('item.contratoId')
    WatchContrato(){
        if(!!this.item.contratoId){
            this.contratoService.ObterPorId(this.item.contratoId, "Itens.Item").then(
                res => {
                    let contrato = new Contrato(res.data);
                    this.item.clienteId = contrato.clienteId;
                    this.contratoItens = contrato.itens.filter(x => x.item.isServico == true);
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('item.pedidoId')
    WatchPedido(){
        if(!!this.item.pedidoId){
            this.pedidoService.ObterPorId(this.item.pedidoId, "Itens.ContratoItem.Item").then(
                res => {
                    let pedido = new Pedido(res.data);
                    this.item.contratoId = pedido.contratoId;
                    this.pedidoItens = pedido.itens.filter(x => x.contratoItem.item.isServico == true);

                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        
        if (this.item.clienteId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVendedor')
    searchVendedor (val: string) {

        if (this.item.comercial.vendedorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Vendedor).then(
            res => {
                this.vendedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchTransportador')
    searchTransportador (val: string) {

        if (this.item.transporte.transportadorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Transportador).then(
            res => {
                this.transportadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchMotorista')
    searchMotorista (val: string) {

        if (this.item.transporte.motoristaId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Motorista).then(
            res => {
                this.motoristas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVeiculo')
    searchVeiculo (val: string) {

        if (this.item.transporte.veiculoId) return;
        if (this.isVeiculoLoading) return;
        if (!val) return;

        this.isVeiculoLoading = true
        this.veiculoService.AutoComplete(val).then(
            res => {
                this.veiculos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isVeiculoLoading = false));
    }

    @Watch('onSearchContrato')
    searchContrato (val: string) {
        if (this.item.contratoId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        this.isPessoaLoading = true
        this.contratoService.AutoComplete(val, '1').then(
            res => {
                this.contratos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchPedido')
    searchPedido (val: string) {
        if (this.item.pedidoId) return;
        if (this.isPedidoLoading) return;
        if (!val) return;
        this.isPedidoLoading = true
        this.pedidoService.AutoComplete(val).then(
            res => {
                this.pedidos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPedidoLoading = false));
    }

    @Watch('item.servico.regraFiscalId')
    CalcularTributos(){
        if (this.item.servico.regraFiscalId){
            this.nfseService.CalcularTributos(this.SomarValorTotal(), this.item.valorDesconto, this.item.servico.regraFiscalId).then(
                res => {
                    this.tributosServico = res.data.tributo;
                    this.item.servico.totalRetencao = res.data.tributo.totalRetencao;
                    this.valorLiquido = res.data.valorLiquido;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
        }
        else {
            this.tributosServico = new NFSeTributo();
            this.item.servico.totalRetencao = 0;
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formaPagamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => {
                this.condicoes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.modalidadeFreteService.ListarTudo().then(
            res => {
                this.modalidadeFretes = res.data.items;
            }
        )
        this.regraFiscalService.AutoComplete(true).then(
            res => this.regrasFiscais = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    CarregarMunicipio(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipios = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    AdicionarItem(){
        this.vendaItem = new VendaItem();
        this.dialogVendaItem = true;
    }

    SalvarItem(){
        if(this.item.itens.find(x => x.produtoId == this.vendaItem.produtoId)){
            AlertSimple("Aviso", "Esse item já se encontra inserido.", "warning");
            this.vendaItem = new VendaItem();    
            return;
        }
        this.vendaItem.SetValorTotal();
        this.item.itens.push(this.vendaItem);
        this.vendaItem = new VendaItem();
        this.CalcularTributos();
    }

    ExcluirItem(item: VendaItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    SomarValorTotal() : number {
        let valor = 0;
        
        this.item.itens.forEach(x => {            
            valor += x['valorTotal'];
        });

        return valor;
    }
    
    Close(){
        this.estadoId = 0;
        this.dialog = false;
    }
}
