import { http, httpHeader } from "@/core/ApiConfig";
import { DespesaClassificacao, DespesaParcela } from "@/core/models/financeiro";
import { Service } from "../shared";
import { DespesaReceitaBaixaModel } from "@/core/models/financeiro/model/DespesaReceitaModel";

export class DespesaService extends Service {
    
    constructor() {
        super('v1/despesa');
    }

    public AutoComplete(q: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                q: q
            },
            headers: httpHeader.headers
        });
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';

            if (filter.numero){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `despesa/numero eq ${filter.numero}`;
            }

            if (filter.descricao){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `contains(despesa/descricao, '${filter.descricao}')`;
            }

            if (Array.isArray(filter.situacaoId) && filter.situacaoId.length > 0){
                if (paramTemp) { paramTemp += ' and '; }
                if (filter.situacaoId.some(x => x == -1)){
                    paramTemp += `situacaoId in (1,3,4)`;
                }
                else {
                    let situacaoIds ='';

                    filter.situacaoId.forEach((id, index) => {
                        if(index == 0)
                            situacaoIds = `${id}`;
                        else
                            situacaoIds += `,${id}`;
                    });
    
                    paramTemp += `situacaoId in (${situacaoIds})`;
                }
            }

            if (filter.valorInicio){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `valor ge ${filter.valorInicio}`;
            }

            if (filter.valorFim){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `valor le ${filter.valorFim}`;
            }

            if (filter.dataCriacaoInicio){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `despesa/dataHoraCriacao ge ${filter.dataCriacaoInicio}`;
            }

            if (filter.dataCriacaoFim){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `despesa/dataHoraCriacao le ${filter.dataCriacaoFim}`;
            }

            if (filter.dataEmissaoInicio){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `despesa/dataEmissao ge ${filter.dataEmissaoInicio}`;
            }

            if (filter.dataEmissaoFim){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `despesa/dataEmissao le ${filter.dataEmissaoFim}`;
            }

            if (filter.dataVencimentoInicio){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataVencimento ge ${filter.dataVencimentoInicio}`;
            }

            if (filter.dataVencimentoFim){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `dataVencimento le ${filter.dataVencimentoFim}`;
            }            

            if (filter.dataRecebimentoInicio){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `baixas/any(o: o/dataPagamento ge ${filter.dataRecebimentoInicio} and o/cancelamento/realizado eq false)`;
            }

            if (filter.dataRecebimentoFim){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `baixas/any(o: o/dataPagamento le ${filter.dataRecebimentoFim} and o/cancelamento/realizado eq false)`;
            }
            
            if (filter.pessoaId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `despesa/pessoaId eq ${filter.pessoaId}`;
            }

            if (filter.contaCorrenteId){
                if (paramTemp) { paramTemp += ' and '; }
                paramTemp += `baixas/any(o: o/contaCorrenteId eq ${filter.contaCorrenteId})`; 
            }

            if (Array.isArray(filter.centroCustoId) && filter.centroCustoId.length > 0){
                if (paramTemp) { paramTemp += ' and '; }

                let centroCustoIds ='';

                filter.centroCustoId.forEach((id, index) => {
                    if(index == 0)
                        centroCustoIds = `${id}`;
                    else
                        centroCustoIds += `,${id}`;
                });

                paramTemp += `despesa/classificacoes/any(o: o/centroCustoId in (${centroCustoIds}))`;
            }

            if (Array.isArray(filter.contaGerencialId) && filter.contaGerencialId.length > 0) {

                if (paramTemp) { paramTemp += ' and '; }

                let contaGerencialIds ='';

                filter.contaGerencialId.forEach((id, index) => {
                    if(index == 0)
                        contaGerencialIds = `${id}`;
                    else
                        contaGerencialIds += `,${id}`;
                });

                paramTemp += `despesa/classificacoes/any(o: o/contaGerencialId in (${contaGerencialIds}))`;
            }
    
            if (paramTemp) {  
                if(parametros)
                    parametros += '&';            
                else
                    parametros += '?';            

                parametros += `$filter=${paramTemp}`;
            }  
        }  
        return parametros;
    }
    
    ListarParcelas(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter?:any, include?:string){
        
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        
        parametros = this.GetFiltro(parametros, filter);

        let paramPagamento = '';

        if (filter.dataRecebimentoInicio){
            if (parametros) { paramPagamento += '&'; }
            paramPagamento += `dataPagamentoInicial=${filter.dataRecebimentoInicio}`;
        }

        if (filter.dataRecebimentoFim){
            if (paramPagamento) { paramPagamento += '&'; }
            paramPagamento += `dataPagamentoFinal=${filter.dataRecebimentoFim}`;
        }
        
        return http.get(`${this.GetNomeControle()}/parcela${parametros}${paramPagamento}`,this.GetHeader(include))
    }

    ObterParcelaPorId(id: number, include?: string){
        return http.get(`${this.GetNomeControle()}/parcela/${id}`,this.GetHeader(include));
    }

    BaixarParcela(id: number, modelo: any){
        return http.put(`${this.GetNomeControle()}/parcela/${id}/baixar`, modelo);
    }

    CancelarParcela(id: number, motivo: string){
        return http.put(`${this.GetNomeControle()}/parcela/${id}/cancelar`, null, {
            params: {
                motivo
            }
        });
    }

    AlterarParcela(modelo: any, id?: number) : Promise<any> {
        return http.patch(`${this.GetNomeControle()}/parcela/${id}`, modelo, this.GetHeader());
    }

    CancelarBaixa(id: number){
        return http.put(`${this.GetNomeControle()}/baixa/${id}/cancelar`);
    }

    public VisualizarParcelas(valorTotal: number, quantidade: number, inicioParcela: number, vencimentoInicial: string, parcelas: DespesaParcela[], salvar: boolean, receitaId?: number){
        return http.post(`${this.GetNomeControle()}/Parcela/Visualizar`, parcelas, {
            params: {
                valorTotal,
                quantidade,
                inicioParcela,
                vencimentoInicial,
                salvar,
                receitaId
            },
            headers: httpHeader.headers
        });
    }

    ValorEmAtraso(){
        return http.get(`${this.GetNomeControle()}/ValorEmAtraso`);
    }

    public ValoresProximosDias(dias: number){
        return http.get(`${this.GetNomeControle()}/ValoresProximosDias`, {
            params: {
                dias
            },
            headers: httpHeader.headers
        });
    }

    public DistribuirEmClassificacoes(classificacoes : DespesaClassificacao[], valorDespesa: number){
        classificacoes.forEach(classificacao => {
            classificacao.contaGerencial.tipo = "R"
        });
        return http.post(`${this.GetNomeControle()}/DistribuirEmClassificacoes`, classificacoes, {
            params: {
                valorDespesa
            },
            headers: httpHeader.headers
        });
    }

    GetAgrupadoPorContaGerencial(sortBy:any[], desc:any[], search:any, columns:any[], filter?:any){
        
        let parametros = this.GetParamentroPaginacaoOrdenacao(-1, -1, sortBy,desc,search, columns);
        
        parametros = this.GetFiltro(parametros, filter);
        
        //return http.get(`${this.GetNomeControle()}/AgrupadoPorContaGerencial${parametros}`)
        return http.get(`${this.GetNomeControle()}/AgrupadoPorContaGerencial${parametros}&ordenarPor=${filter.ordenarPor}`)
    }

    GetAgrupadoPorContaGerencialAPagar(filter?:any){
        
        //return http.get(`${this.GetNomeControle()}/AgrupadoPorContaGerencial?$filter=(situacaoId eq 1 and despesa/dataEmissao le ${filter.dataEmissaoFim}) or (situacaoId eq 3 and despesa/dataEmissao le ${filter.dataEmissaoFim} and baixas/any(o: o/dataPagamento ge ${filter.dataRecebimentoInicio} and o/cancelamento/realizado eq false))&ordenarPor=${filter.ordenarPor}`)
        
        return http.get(`${this.GetNomeControle()}/AgrupadoPorContaGerencial`, {
            params: {
                numero: filter.numero,
                descricao: filter.descricao,
                situacaoId: filter.situacaoId,
                dataCriacaoInicio: filter.dataCriacaoInicio,
                dataCriacaoFim: filter.dataCriacaoFim,
                dataEmissaoInicio: filter.dataEmissaoInicio,
                dataEmissaoFim: filter.dataEmissaoFim,
                dataVencimentoInicio: filter.dataVencimentoInicio,
                dataVencimentoFim: filter.dataVencimentoFim,
                dataRecebimentoInicio: filter.dataRecebimentoInicio,
                dataRecebimentoFim: filter.dataRecebimentoFim,
                pessoaId: filter.pessoaId,
                centroCustoId: filter.centroCustoId,
                contaGerencialId: filter.contaGerencialId,
                valorInicio: filter.valorInicio,
                valorFim: filter.valorFim,
                contaCorrenteId: filter.contaCorrenteId,
                aPagar: filter.aPagar
            }
        })
    }

    GetAgrupadoPorPessoa(sortBy:any[], desc:any[], search:any, columns:any[], filter?:any){
        
        let parametros = this.GetParamentroPaginacaoOrdenacao(-1, -1, sortBy,desc,search, columns);
        
        parametros = this.GetFiltro(parametros, filter);

        let paramPagamento = '';

        if (filter.dataRecebimentoInicio){
            if (parametros) { paramPagamento += '&'; }
            paramPagamento += `dataPagamentoInicial=${filter.dataRecebimentoInicio}`;
        }

        if (filter.dataRecebimentoFim){
            if (paramPagamento) { paramPagamento += '&'; }
            paramPagamento += `dataPagamentoFinal=${filter.dataRecebimentoFim}`;
        }
        
        return http.get(`${this.GetNomeControle()}/AgrupadoPorPessoa${parametros}${paramPagamento}`)
    }

    GetAgrupadoPorPessoaApagar(filter?:any){
        return http.get(`${this.GetNomeControle()}/AgrupadoPorPessoa?$filter=(situacaoId eq 1 and despesa/dataEmissao le ${filter.dataEmissaoFim}) or (situacaoId eq 3 and despesa/dataEmissao le ${filter.dataEmissaoFim} and baixas/any(o: o/dataPagamento ge ${filter.dataRecebimentoInicio} and o/cancelamento/realizado eq false) or (situacaoId eq 4 and despesa/dataEmissao le ${filter.dataEmissaoFim}))&ordenarPor=${filter.ordenarPor}`)
        //return http.get(`${this.GetNomeControle()}/AgrupadoPorPessoa?$filter=(situacaoId eq 1 and despesa/dataEmissao le 2023-12-31) or (situacaoId eq 3 and despesa/dataEmissao le 2023-12-31 and baixas/any(o: o/dataPagamento ge 2024-01-01 and o/cancelamento/realizado eq false) or (situacaoId eq 4 and despesa/dataEmissao le 2023-12-31))&ordenarPor=nome`);
    }

    public TransferenciaContaGerencial(contaGerencialOrigemId: number, contaGerencialDestinoId: number) {
        return http.post(`${this.GetNomeControle()}/TransferenciaContaGerencial`, undefined, {
            params: {
                contaGerencialOrigemId,
                contaGerencialDestinoId,
            }
        });
    }

    public TransferenciaCentroCusto(centroCustoOrigemId: number, centroCustoDestinoId: number ){
        return http.post(`${this.GetNomeControle()}/TransferenciaCentroCusto`, undefined, {
        params: {
            centroCustoOrigemId,
            centroCustoDestinoId,
        }
        });
    }

    GravarDocumento(arquivo: any, id: number){
        let bodyFormData = new FormData();
        bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/pdf' }), arquivo.name);
        return http.post(`${this.GetNomeControle()}/${id}/GravarDocumento`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        })
    }

    DownloadDocumento(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/DownloadDocumento`, {
            responseType: 'arraybuffer'
        });
    }

    ListarDocumentos(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/ListarDocumentos`);
    }

    GravarDocumentos(id: number, links?: any, arquivos?: any){
        let bodyFormData = new FormData();
        bodyFormData.append("links", links);
        arquivos.forEach(arquivo => {
            bodyFormData.append("arquivos", new Blob([arquivo]), arquivo.name)
        });
        return http.post(`${this.GetNomeControle()}/${id}/GravarDocumentos`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        });
    }

    ExcluirDocumentos(id: number, ids: string){
        return http.delete(`${this.GetNomeControle()}/${id}/ExcluirDocumentos`, {
            params: {
                ids
            }
        });
    }

    DownloadDocumentos(id: number, documentoId: number){
        return http.get(`${this.GetNomeControle()}/${id}/DownloadDocumentos/${documentoId}`, {
            responseType: 'arraybuffer'
        });
    }

    GravarComprovante(arquivo: any, id: number){
        let bodyFormData = new FormData();
        bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/pdf' }), arquivo.name);
        return http.post(`${this.GetNomeControle()}/${id}/GravarComprovante`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            }
        })
    }

    DownloadComprovante(id: number){
        return http.get(`${this.GetNomeControle()}/${id}/DownloadComprovante`, {
            responseType: 'arraybuffer'
        });
    }

    ExportarPorExcel(sortBy:any[], desc:any[], columns:any[], filter?:any, include?:string){
        
        let parametros = this.GetParamentroPaginacaoOrdenacao(-1, -1, sortBy, desc, null, columns);        
        parametros = this.GetFiltro(parametros, filter);
        
        return http.post(`${this.GetNomeControle()}/excel${parametros}`, {
            colunas: columns,
        }, 
        { 
            responseType: 'blob', 
            headers: { 
                include: include
            } 
        });
    }

    BaixasMultiplas(baixas: any) {
        return http.post(`${this.GetNomeControle()}/BaixasMultiplas`, baixas, {
            headers: httpHeader.headers
        });
    }
}