import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";

export class MDFeService extends Service {

    constructor() {
        super('v1/MDFe')
    }

    private GetFiltro(parametros: string, filter: any){
        if (filter) {
            let paramTemp = '';

            if(filter.dataEmissaoInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataEmissao ge ${filter.dataEmissaoInicial}`;
            }

            if(filter.dataEmissaoFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `dataEmissao le ${filter.dataEmissaoFinal}`;
            }

            if(filter.numeroInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numero ge ${filter.numeroInicial}`;
            }

            if(filter.numeroFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `numero le ${filter.numeroFinal}`;
            }

            if(filter.serieInicial){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `serie ge ${filter.serieInicial}`;
            }

            if(filter.serieFinal){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `serie le ${filter.serieFinal}`;
            }

            if(filter.veiculoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `transporte/veiculo/veiculoReboque/veiculoId eq ${filter.veiculoId}`;
            }

            if(filter.chaveAcesso){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `chaveAcesso eq ${filter.chaveAcesso}`;
            }

            if(filter.situacaoId){
                if(paramTemp){paramTemp += ' and '; }
                paramTemp += `situacaoId eq ${filter.situacaoId}`;
            }

            if (paramTemp) {

            if(parametros){
                parametros += '&';
            }
            else {
                parametros += '?';
            }

            parametros += `$filter=${paramTemp}`;
            }
        }
        
        return parametros;  
    }

    public ListarComFiltro(page:number, pageSize:number, sortBy:any[], desc:any[], search:any, columns:any[], filter:any, include){
        let parametros = this.GetParamentroPaginacaoOrdenacao(page,pageSize, sortBy,desc,search, columns);
        parametros = this.GetFiltro(parametros, filter);

        return http.get(`${this.GetNomeControle()}${parametros}`, this.GetHeader(include));
    } 

    public Cancelar(id: number, motivo: string){
        return http.post(`${this.GetNomeControle()}/${id}/Cancelar`, null, 
        {
            params: {
                motivo
            }
        });
    }
}