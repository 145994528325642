
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import {  Component, Watch, Prop } from 'vue-property-decorator'
import { PessoaEndereco } from '@/core/models/geral';
import { EnderecoService } from '@/core/services/finder';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class CadastroEndereco extends CrudBase{
    @Prop() item!: PessoaEndereco;
    @Prop() private value!: string;

    $refs!: {
        form: HTMLFormElement
    }

    mskCep = process.env.VUE_APP_MSK_CEP;
    loadEndereco: boolean = false;
    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    estados: Estado[] = [];
    municipios: Municipio[] = [];

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item')
    WatchItem(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if(this.item.id > 0){
            this.estadoId = this.item.endereco.municipio.estadoId;
        }
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");            
        }
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
    }

    CarregarMunicipio(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipios = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    ObterEndereco(cep: string){
        if(cep){
            this.loadEndereco = true;
            this.enderecoService.ObterPorCep(cep).then(
                res => {
                    const endereco = res.data;
                    if (endereco) {
                        this.item.endereco.logradouro = endereco.logradouro;
                        this.item.endereco.bairro = endereco.bairro;
                        this.estadoId = endereco.estadoId;
                        this.item.endereco.municipioId = endereco.municipioId;
                    }
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loadEndereco = false;
            });
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.item.endereco.estadoTexto = this.estados.find(x => x.id === Number(this.item.endereco.municipioId.toString().substring(0,2)))!.uf;
            this.item.endereco.municipioTexto = this.municipios.find(x => x.id === this.item.endereco.municipioId)!.nome.toUpperCase();
            this.item.endereco.enderecoSemCep = `${this.item.endereco.logradouro}, ${this.item.endereco.numero} ${this.item.endereco.complemento} - ${this.item.endereco.municipioTexto} - ${this.item.endereco.estadoTexto}`;
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close(){
        this.dialog = false;
    }
}
