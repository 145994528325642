
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { AlertExcludeQuestion, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { RegistroOperacao, RelatorioMedicao, RelatorioMedicaoItem } from "@/core/models/operacaoportuaria";
import { RelatorioMedicaoService } from "@/core/services/operacaoportuaria";
import { CrudBase } from "@/core/models/shared";

@Component
export default class CadastroRelatorioMedicao extends CrudBase {
    @Prop() private item!: RelatorioMedicao;
    @Prop() private value!: string;

    itemOriginal!:RelatorioMedicao;
    service = new RelatorioMedicaoService();
    $refs!: {
        form: HTMLFormElement
    }

    registroOperacoes: RegistroOperacao[] = [];
    
    headerItens: any[] = [
        { text: '', value:'actions', sortable: false },
        { text: '#', value: 'registroOperacaoEquipamento.sequencia', type:'number', sortable: false },
        { text: 'Equipamento/Serviço', value: 'registroOperacaoEquipamento.equipamento.nome', sortable: false },
        { text: 'Inicio', value: 'registroOperacaoEquipamento.dataHoraInicio', type:'date', sortable: false },
        { text: 'Fim', value: 'registroOperacaoEquipamento.dataHoraTermino', type:'date', sortable: false },
        { text: 'Observação', value: 'observacao', sortable: false }
    ];

    @Watch('item')
    Item(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
        if (this.dialog){
            this.itemOriginal = jiff.clone(this.item);
            this.registroOperacoes.push(this.item.registroOperacao);
        }
    }

    mounted() {

    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    ExcluirEquipamentoServico(item: RelatorioMedicaoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index,1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar() {
        if (this.$refs.form.validate()) {
            this.loading = true;
            let pacthModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(pacthModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso!", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.loading = false;
            });
        }
    }

    Close() {
        this.dialog = false;
    }
}
