import { LocalOperacao, Pessoa, PessoaContato, Porto } from "../geral";
import { PropostaTerno, PropostaEquipamento, PropostaLocalOperacaoPeriodo, TipoProposta } from ".";

export class PropostaOperacaoPortuaria implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    tipoId: number = 0
    tipo!: TipoProposta;
    clienteId: number = 0;
    cliente!: Pessoa;
    contatoId?: number;
    contato?: PessoaContato;
    responsavelId: number = 0;
    responsavel?: Pessoa;
    portoId: number = 0;
    porto!: Porto;
    localOperacaoId: number = 0;
    localOperacao!: LocalOperacao;
    numero: number = 0;
    data: string = new Date().toYYYYMMDD();
    moedaId: number = 1;
    valorAmarracaoDesamarracao: number = 0;
    valorDWT: number = 0;
    localOperacaoPeriodo: number = 0;
    valorPeriodoOperante: number = 0;
    valorPeriodoInoperante: number = 0;
    observacao: string = '';
    cobrarDiaria: boolean = false;

    localOperacaoPeriodos: PropostaLocalOperacaoPeriodo[] = [];
    ternos: PropostaTerno[] = [];
    equipamentos: PropostaEquipamento[] = [];

    constructor(model? : PropostaOperacaoPortuaria) {
        
        if (!model)
            return;
        
        this.id = model.id;
        this.empresaId = model.empresaId;
        this.tipoId = model.tipoId;
        this.tipo = model.tipo;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.contatoId = model.contatoId;
        this.contato = model.contato;
        this.responsavelId = model.responsavelId;
        this.responsavel = model.responsavel;
        this.portoId = model.portoId;
        this.porto = model.porto;
        this.localOperacaoId = model.localOperacaoId;
        this.localOperacao = model.localOperacao;
        this.numero = model.numero;
        this.data = model.data ? model.data.toDateYYYYMMDD() : "";
        this.moedaId = model.moedaId;
        this.valorAmarracaoDesamarracao = model.valorAmarracaoDesamarracao;
        this.valorDWT = model.valorDWT;
        this.localOperacaoPeriodo = model.localOperacaoPeriodo;
        this.valorPeriodoOperante = model.valorPeriodoOperante;
        this.valorPeriodoInoperante = model.valorPeriodoInoperante;
        this.observacao = model.observacao;
        this.cobrarDiaria = model.cobrarDiaria;

        this.localOperacaoPeriodos = model.localOperacaoPeriodos;
        this.ternos = model.ternos;
        this.equipamentos = model.equipamentos;
    }
}