import { Almoxarifado } from "./Almoxarifado";
import { Produto } from "./Produto";
import { Requisicao } from "./Requisicao";

export class RequisicaoItem implements Shared.IEntity{
    
    id: number = 0;
    requisicaoId: number = 0;
    requisicao!: Requisicao;
    produtoId: number = 0;
    produto!: Produto;
    almoxarifadoId: number = 0;
    almoxarifado!: Almoxarifado;
    quantidade: number = 1;

    constructor(model?: RequisicaoItem){

        if(!model)
            return;

        this.id = model.id;
        this.requisicaoId = model.requisicaoId;
        this.requisicao = model.requisicao;
        this.produtoId = model.produtoId;
        this.produto = model.produto;
        this.almoxarifadoId = model.almoxarifadoId;
        this.almoxarifado = model.almoxarifado;
        this.quantidade = model.quantidade;
    }
}