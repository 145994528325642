import { Funcao } from "../geral";
import { ModeloSelecao } from "./ModeloSelecao";
import { ModeloSelecaoTipo } from "./ModeloSelecaoTipo";
import { TipoDocumento } from "./TipoDocumento";

export class ModeloSelecaoFuncionario implements Shared.IEntity {

    id : number = 0;
    nome: string = "";
    entidadeId: number = 0;
    entidade: string = "";
    modeloSelecao!: ModeloSelecao;
    modeloSelecaoId?: string;
    funcao!: Funcao;
    funcaoId : number = 0;
    tiposDocumentos : ModeloSelecaoTipo[] = [];

    constructor (model? : ModeloSelecaoFuncionario) {
        if (!model)
            return;

        this.id = model.id
        this.nome = model.nome;
        this.entidadeId = model.entidadeId;
        this.entidade = model.entidade;
        this.modeloSelecao = model.modeloSelecao;
        this.funcao = model.funcao;
        this.funcaoId = model.funcaoId;
        this.tiposDocumentos = model.tiposDocumentos;
    }
}