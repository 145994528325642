

import { CrudBase } from "@/core/models/shared";
import { TabelaPreco, TabelaPrecoItem } from "@/core/models/vendas";
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { TabelaPrecoService } from "@/core/services/vendas";
import { Component, Prop, Watch } from "vue-property-decorator";
import jiff from 'jiff';
import { Produto } from "@/core/models/estoque";
import { ProdutoService } from "@/core/services/estoque";

@Component
export default class CadastroTabelaPreco extends CrudBase{
    @Prop() item!: TabelaPreco;
    @Prop() private value!: string;

    validProdutoServico: boolean = false;

    itemOriginal!: TabelaPreco;
    service: TabelaPrecoService = new TabelaPrecoService();
    $refs!: {
        form: HTMLFormElement,
        formProdutoServico: HTMLFormElement
    }

    produtoServicos: Produto[] = [];
    produtoServicoService: ProdutoService = new ProdutoService();
    onSearchProdutoServico: any = null;
    isProdutoServicoLoading: boolean = false;

    tabelaPrecoItem: TabelaPrecoItem = new TabelaPrecoItem();

    itensHeaders: any[] = [
        { text: '', value: 'actions', sortable: false, class: 'action', use: true },
        { text: 'Produto/Serviço', value:'item.nome', use: true},
        { text: 'Valor', value: 'valor', type:'number', use: true}
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
        if (this.$refs.formProdutoServico) {
            this.$refs.formProdutoServico.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    @Watch('onSearchProdutoServico')
    searchProdutoServico (val: string) {
        if (this.tabelaPrecoItem.itemId) return;
        if (this.isProdutoServicoLoading) return;
        if (!val) return;
        this.isProdutoServicoLoading = true
        this.produtoServicoService.AutoComplete(val).then(
            res => {
                this.produtoServicos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isProdutoServicoLoading = false));
    }    

    Carregar(){
    }
    

    AdicionarProdutoServico(){
        if(this.$refs.formProdutoServico.validate()){
            if(this.item.itens.find(x => x.itemId == this.tabelaPrecoItem.itemId)){
                AlertSimple("Aviso!", "Já existe um item inserido", "warning");
                return;
            }
            var produtoServico = this.produtoServicos.find(x => x.id == this.tabelaPrecoItem.itemId)!;
            this.tabelaPrecoItem.item = produtoServico;
            this.item.itens.push(this.tabelaPrecoItem);
            this.tabelaPrecoItem = new TabelaPrecoItem();
            if (this.$refs.formProdutoServico) {
            this.$refs.formProdutoServico.resetValidation();
                this.$refs.formProdutoServico.resetValidation();
            }
        }
    }

    ExcluirProdutoServico(item: TabelaPrecoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                const index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }


    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.Salvou();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Salvou() {
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}

