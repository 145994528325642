import { http, httpHeader } from "@/core/ApiConfig";
import { Service } from "../shared";
import { EnumTipoRegraFiscal } from "@/core/models/dfe";

export class RegraFiscalService extends Service {

    constructor() {
        super('v1/regrafiscal')
    }

    public ListarPorTipo(tipoId: EnumTipoRegraFiscal) {

        return http.get(`${this.GetNomeControle()}?$filter=tipoId eq ${tipoId}`, this.GetHeader());
    } 

    public AutoComplete(preCarregado: boolean, q?: string) {
        return http.get(`${this.GetNomeControle()}/AutoComplete`, {
            params: {
                preCarregado: preCarregado,
                q: q
            },
            headers: httpHeader.headers
        });
    }
}