
import { Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { Contrato, ContratoItem, PedidoItem, TabelaPreco, VendaItem } from '@/core/models/vendas';
import { ProdutoService } from '@/core/services/estoque';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { RegraFiscalService } from '@/core/services/dfe';
import { RegraFiscal } from '@/core/models/dfe';
import { PessoaProdutoServico } from '@/core/models/geral';
import { ContratoService } from '@/core/services/vendas';

@Component
export default class CadastroItem extends CrudBase {    
  @Prop() item!: VendaItem;
  @Prop() clienteItens!: PessoaProdutoServico[];
  @Prop() contratoItens!: ContratoItem[];
  @Prop() pedidoItens!: PedidoItem[];
  @Prop() tabelaPreco!: TabelaPreco;
  @Prop() regraFiscal!: RegraFiscal;
  @Prop() valorFrete!: number;
  @Prop() private value!: string;

  $refs!: {
    form: HTMLFormElement
  }

  produtoService: ProdutoService = new ProdutoService();
  produtos: any[] = [];
  isProdutoLoading: boolean = false;
  onSearchProduto: any = null;

  contratoService: ContratoService = new ContratoService();

  regraFiscalService: RegraFiscalService = new RegraFiscalService();
  regrasFiscais: RegraFiscal[] = [];

  @Watch('value')
  Value(){
    this.dialog = this.value ? true : false;
  }

  @Watch("dialog")
  Dialog() {
    if (this.dialog) {
      this.Carregar();
      this.CarregarItens();
    }
    else{
      this.$emit("fechou");
    }
    if(this.$refs.form)
      this.$refs.form.resetValidation();
  }

  @Watch("item.valorUnitario")
  @Watch("item.quantidade")
  @Watch("item.desconto")
  @Watch("item.frete")
  @Watch("item.seguro")
  @Watch("item.outrasDespesas")
  WatchMudancaValores(){
    this.item.SetFrete(this.valorFrete ? this.valorFrete : 0);
    this.item.SetValorTotal();
  }

  beforeUpdate(){
    if (!this.dialog){
      this.$emit('fechou');
    }
  }

  @Watch('onSearchProduto')
  SearchProduto (val: string) {
    //CASO TENHA CONTRATO OU PEDIDO NAO TRAZER OUTROS ITENS
    if (this.contratoItens.length > 0 || this.pedidoItens.length > 0) return;
    if (this.item.produtoId) return;
    if (this.isProdutoLoading) return;
    if (!val) return;    

    this.isProdutoLoading = true
    this.produtoService.AutoComplete(val, undefined, undefined, undefined, true).then(
      res => {
        this.produtos = res.data;
      },
      err => AlertSimpleErr("Aviso!", err)
    ).finally(() => (this.isProdutoLoading = false));
  }

  @Watch("item.produtoId")
  SelectProduto(){
    if(this.item.produtoId > 0){
      let produtoPedido = this.pedidoItens ? this.pedidoItens.find(x => x.contratoItem.itemId == this.item.produtoId) : null;
      let produtoContrato = this.contratoItens ? this.contratoItens.find(x => x.itemId == this.item.produtoId) : null;
      let produtoCliente = this.clienteItens ? this.clienteItens.find(x => x.produtoServicoId == this.item.produtoId) : null;
      let produtoTabelaPreco = this.tabelaPreco ? this.tabelaPreco.itens.find(x => x.itemId == this.item.produtoId) : null;
      let produto = this.produtos.find(x => x.id == this.item.produtoId);

      if (produtoPedido){
        this.item.produto = produtoPedido.contratoItem.item;
        this.item.valorUnitario = produtoPedido.contratoItem.valor;
        this.item.regraFiscalId = produtoPedido.contratoItem.regraFiscalId;
      }
      else if(produtoContrato){
        this.item.produto = produtoContrato.item;
        this.item.valorUnitario = produtoContrato.valor;
        this.item.regraFiscalId = produtoContrato.regraFiscalId;
      }
      else if(produtoCliente){
        this.item.produto = produtoCliente.produtoServico;
        this.item.valorUnitario = produtoCliente.valor;
        this.item.regraFiscalId = produto.regraFiscalId;
      }
      else if(produtoTabelaPreco){
        this.item.produto = produtoTabelaPreco.item;
        this.item.valorUnitario = produtoTabelaPreco.valor;
        this.item.regraFiscalId = produto.regraFiscalId;
      }
      else{
        this.item.produto = produto!;
        this.item.valorUnitario = produto!.precoVenda;
        this.item.regraFiscalId = produto.regraFiscalId;
      }
    }
  }

  mounted() {
    
  }

  Carregar(){
    this.regraFiscalService.AutoComplete(true).then(
      res => this.regrasFiscais = res.data,
      err => AlertSimpleErr("Aviso!", err)
    )
  }

  CarregarItens(){
    this.produtos = [];
    if(this.pedidoItens.length > 0){
      this.pedidoItens.forEach(pedidoItem => {
        let model = pedidoItem.contratoItem.item;
        model[`saldo`] = pedidoItem.saldo;
        model[`codigoNomeSaldo`] = `${model.codigoNome} - SALDO: ${pedidoItem.saldo}`;
        this.produtos.push(model);
      });
      return;
    }
    if(this.contratoItens.length > 0){
      let contrato = new Contrato();
      this.contratoService.ObterPorId(this.contratoItens.find(x => x.contratoId)?.contratoId!).then(
        res => {
          contrato = res.data;
          this.produtos = this.contratoItens.map(x => x.item);
          this.contratoItens.forEach(contratoItem => {
            let model = contratoItem.item;
            model[`saldo`] = contratoItem.saldo;
            if (contrato.isPorQuantidade == true) {
              model[`codigoNomeSaldo`] = `${model.codigoNome} - SALDO: ${contratoItem.saldo}`;
            }
            else {
              model[`codigoNomeSaldo`] = `${model.codigoNome}`;
            }
            
            this.produtos.push(model);
      });
        },
        err => {
          AlertSimpleErr("Aviso!", err)
        }
      )

      return;
    }
  }


  isDisabled(){
        return this.clienteItens.find(x => x.produtoServicoId == this.item.produtoId),
        this.tabelaPreco.itens.find(x => x.itemId == this.item.produtoId); 
    }

  Salvar(){
    if(this.$refs.form.validate())
      this.Salvou();
  }

  Salvou(){
    this.$emit("salvou");
    this.Close();

  }

  Close(){
    this.$emit("fechou");
    this.dialog = false;
  }
}
