
import { ListBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'

@Component
export default class ListaHeader extends ListBase{
    @Prop() itemsPerPage!: number;
    @Prop() pages!: number;
    @Prop() itemsTotal!: number;
    @Prop() pageCounter!: number

    itemsPerPageNumb: number = 0;
    pagesNumb: number = 0;
    itemsTotalNumb: number = 0;

    @Watch("itemsPerPage")
    @Watch("pages")
    @Watch("itemsTotal")
    @Watch("pageCounter")
    Atualiza(){
        this.itemsPerPageNumb = this.itemsPerPage;
        this.pagesNumb = this.pages;
        this.itemsTotalNumb = this.itemsTotal;
    }
    
    mounted(){
        this.itemsPerPageNumb = this.itemsPerPage;
        this.pagesNumb = this.pages;
        this.itemsTotalNumb = this.itemsTotal;
    }

    @Watch("itemsPerPageNumb")
    WatchItemsPerPage(){
        this.$emit('itemsPerPage', this.itemsPerPageNumb);
    }

    @Watch("pagesNumb")
    WatchPage(){
        this.$emit('pages', this.pagesNumb);
    }

    public Totalizador(page: number, itemsPerPage: number, total: number){
        let inicio = (page - 1) * itemsPerPage + 1;
        let fim = Math.min(inicio + itemsPerPage - 1, total);
        return `${inicio}-${fim} de ${total}`;
    }
}
