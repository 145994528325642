
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { CrudBase } from '@/core/models/shared';
import { NfeCobrancaDuplicata } from '@/core/models/dfe/NFe';

@Component
export default class CadastroNFeCobranca extends CrudBase{
    @Prop() item!: NfeCobrancaDuplicata;
    @Prop() private value!: string;
    
    $refs!: {
        form: HTMLFormElement
    }
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.Salvou();
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }
    
    Close(){
        this.dialog = false;
    }
}
