import { TipoReferenciada } from ".";

export class NFeReferenciada implements Shared.IEntity {

  id: number = 0;
  tipoReferenciadaId: number = 0;
  tipoReferenciada!: TipoReferenciada;
  chaveAcesso: string = "";
  
  constructor(model?: NFeReferenciada) {

    if (!model)
      return;

    this.id = model.id;
    this.tipoReferenciadaId = model.tipoReferenciadaId;
    this.tipoReferenciada = model.tipoReferenciada;
    this.chaveAcesso = model.chaveAcesso;
  }
}