import { Operacao } from "../shared";
import { RegistroOperacao } from "./RegistroOperacao";
import { RelatorioMedicaoItem } from "./RelatorioMedicaoItem"

export class RelatorioMedicao implements Shared.IEntity {
    id: number = 0;
    empresaId!: string;
    numero: number = 0;
    dataReferencia: string = "";
    dataHoraCriacao: string = "";
    registroOperacaoId: number = 0;
    registroOperacao!: RegistroOperacao;
    observacao: string = '';
    aprovacao?: Operacao;

    itens: RelatorioMedicaoItem[] = [];

    constructor(model? : RelatorioMedicao) {
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.numero = model.numero;
        this.dataReferencia = model.dataReferencia ? model.dataReferencia.toDateYYYYMMDD() : "";
        this.dataHoraCriacao = model.dataHoraCriacao ? model.dataHoraCriacao.toDateTimeYYYYMMDD() : "";
        this.registroOperacaoId = model.registroOperacaoId;
        this.registroOperacao = model.registroOperacao;
        this.observacao = model.observacao;
        this.aprovacao = model.aprovacao;

        this.itens = model.itens;
    }
}