

import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import {AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Cfop, CstIcms, CstIpi, CstPisCofins, MotivoDesoneracao, OrigemMercadoria, RegraFiscal, TipoRegraFiscal, EnumTipoRegraFiscal } from '@/core/models/dfe';
import { RegraFiscalService, CfopService, CstIcmsService, CstIpiService, CstPisCofinsService, MotivoDesoneracaoService, OrigemMercadoriaService, TipoRegraFiscalService } from '@/core/services/dfe';

@Component
export default class CadastroRegraFiscal extends CrudBase {
    @Prop() item!: RegraFiscal;
    @Prop() private value!: string;

    panel: any = [];
    itemOriginal!: RegraFiscal;
    service: RegraFiscalService = new RegraFiscalService();
    $refs!: {
        form: HTMLFormElement,
        formClassificacao: HTMLFormElement
    }

    enumTipoRegraFiscal = EnumTipoRegraFiscal;
    
    operacoes: any = [ 
        {id: true, nome: "Saída"}, 
        {id: false, nome: "Entrada"}
    ];

    tipoRegras: TipoRegraFiscal[] = [];
    tipoRegraFiscalService: TipoRegraFiscalService = new TipoRegraFiscalService();

    cfops: Cfop[] = [];
    cfop: Cfop = new Cfop()
    cfopService: CfopService = new CfopService();

    origemMercadorias: OrigemMercadoria[] = [];
    origemMercadoriaService: OrigemMercadoriaService = new OrigemMercadoriaService();

    cstIcms: CstIcms[] = [];
    cstIcmsService: CstIcmsService = new CstIcmsService();

    motivoDesoneracoes: MotivoDesoneracao[] = [];
    motivoDesoneracaoService: MotivoDesoneracaoService = new MotivoDesoneracaoService();

    cstPisCofins: CstPisCofins[] = [];
    cstPisCofinsService: CstPisCofinsService = new CstPisCofinsService();

    cstIpis: CstIpi[] = [];
    cstIpiService: CstIpiService = new CstIpiService();

    @Watch('value')
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            this.FilterCfop();
            this.FilterCST();
            this.WatchTipo();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch() {
        if (this.item.id > 0) {
            this.itemOriginal = jiff.clone(this.item);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    @Watch('item.tipoId')
    WatchTipo(){
        switch(this.item.tipoId){
            case EnumTipoRegraFiscal.NFe:
                this.panel = [0, 1, 2];
                break;
            case EnumTipoRegraFiscal.NFSe:
                this.panel = [0];
                this.item.saida = true;
                this.item.atualizaEstoque = false;
                this.item.cfopId = undefined;
                break;
            case EnumTipoRegraFiscal.CTe:
                this.panel = [0];
                this.item.saida = true;
                this.item.atualizaEstoque = false;
                break;
        }
    }

    @Watch('item.saida')
    WatchItemSaida() {
        this.FilterCfop();
    }

    @Watch('item.tipoId')
    WatchTipoId() {
        this.FilterCST();
    }

    beforeUpdate() {
        if (!this.dialog) {
            this.$emit('fechou');
        }
    }

    FilterCfop() {
        this.cfopService.ListarTudo().then(
            res => {
                if(this.item.saida === true) {
                    return this.cfops = res.data.items.filter(x => x.id > 5000)
                } else {
                    this.cfops = res.data.items.filter(x => x.id <= 4999)
                }
                err => AlertSimpleErr("Aviso!", err)
            }     
        )
    }

    FilterCST() {
        this.cstIcmsService.ListarTudo().then(
            res => {
                if(this.item.tipoId == 1) {
                    this.cstIcms = res.data.items.filter(x => x.id != 1);
                } else {
                    this.cstIcms = res.data.items.filter(x => x.cte === true);
                }
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Carregar(){
        this.tipoRegraFiscalService.ListarTudo().then(
            res => {
                this.tipoRegras = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.origemMercadoriaService.ListarTudo().then(
            res => {
                this.origemMercadorias = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.motivoDesoneracaoService.ListarTudo().then(
            res => {
                this.motivoDesoneracoes = res.data.items
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.cstPisCofinsService.ListarTudo().then(
            res => {
                this.cstPisCofins = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.cstIpiService.ListarTudo().then(
            res => {
                this.cstIpis = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Salvar() {
        if (this.$refs.form.validate()) {
                this.loading = true;
                let patchModel = jiff.diff(this.itemOriginal, this.item, false);
                (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                    res => {
                        AlertSimpleRes("Aviso", res);                                                
                        this.Salvou();
                    },
                    err => AlertSimpleErr("Aviso", err)
                ).finally(() => {
                    this.loading = false;
                })
        }
    }

    Salvou(){
        this.$emit("salvou");
        this.Close();
    }

    Close() {
        this.dialog = false;
    }
}
