
import { PageBase } from '@/core/models/shared';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class Home extends PageBase{
    @Prop() private nome!: string;
    @Prop() private legenda!: string;
    @Prop() private icone!: string;
    @Prop() private path!: string;

    Abrir(){
        this.$router.push({name: this.path})
    }
}
