import { LocalOperacaoPeriodo, Porto } from "../geral";

export class LocalOperacao implements Shared.IEntity {
    
    id: number = 0;
    portoId?: number = 0;
    porto!: Porto;
    nome: string = '';
    tempoMinimo: number = 0;
    calcularDWT: boolean = false;
    calcularPeriodoOperanteInoperante: boolean = false;
    ativo: boolean = true;

    periodos: LocalOperacaoPeriodo[] = [];

    constructor (model? : LocalOperacao) {
        
        if (!model)
            return;

        this.id = model.id;
        this.portoId = model.portoId;
        this.porto = model.porto;
        this.nome = model.nome;
        this.tempoMinimo = model.tempoMinimo;
        this.calcularDWT = model.calcularDWT;
        this.calcularPeriodoOperanteInoperante = model.calcularPeriodoOperanteInoperante;
        this.ativo = model.ativo;

        this.periodos = model.periodos;
    }
}