
import { PageBase } from '@/core/models/shared';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class MasterPageFinanceiro extends PageBase{
  @Prop({default: 'mdi-cash-register'}) public icone!: string;
  @Prop({default: 'Financeiro'}) public titulo!: string;
  @Prop({default: false}) public overlay!: boolean;
  menuOpts: any[] = [];

  created() {
    this.menuOpts = this.app.sistema.modulos.filter(x => x.termo == 'financeiro')[0].menuParents;
  }

  mounted() {
    
  }
}
