import { http, httpHeader } from "@/core/ApiConfig";
import { Parametro } from "@/core/models/dfe";

export class DFeService {

    private _nomeControle: string = 'v1/dfe';
    
    GravarCertificadoDigital(arquivo: any, senha: any){
        let bodyFormData = new FormData();
        bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/x-pkcs12' }), arquivo.name);
        return http.post(`${this._nomeControle}/CertificadoDigital`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            params: {
                senha
            }
        })
    }

    ValidadeCertificadoDigital(arquivo: any, senha: any){

        if (!arquivo) {
            return Promise.resolve(null);
        }

        let bodyFormData = new FormData();
        bodyFormData.append('arquivo', new Blob([arquivo], { type: 'application/x-pkcs12' }), arquivo.name);
        return http.post(`${this._nomeControle}/CertificadoDigital/Validade`, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            params: {
                senha
            }
        })
    }

    public GetAvisoCertificadoDigital() {
        return http.get(`${this._nomeControle}/CertificadoDigital/Aviso`, httpHeader);
    }

    //PARAMETRO
    public GetParametro() {
        return http.get(`${this._nomeControle}/parametro`, httpHeader);
    }

    public SaveParametro(model: Parametro) {
        return http.put(`${this._nomeControle}/parametro`, model);
    }

    //ENUMERADOS
    GetDfe(){
        return http.get(`${this._nomeControle}/enumerado/tipo`);
    }

    GetDestinoOperacao(){
        return http.get(`${this._nomeControle}/enumerado/nfe/destinoOperacao`);
    }

    GetTipoIdentificacaoEvento(){
        return http.get(`${this._nomeControle}/enumerado/nfe/transporteVeiculo`);
    }

    GetTipoTransportador(){
        return http.get(`${this._nomeControle}/enumerado/mdfe/tipoTransportador`);
    }

    GetTipoEmitente(){
        return http.get(`${this._nomeControle}/enumerado/mdfe/tipoEmitente`);
    }

    GetUnidadeMedida(){
        return http.get(`${this._nomeControle}/enumerado/mdfe/unidadeMedida`);
    }

    GetResponsavelSeguro(){
        return http.get(`${this._nomeControle}/enumerado/mdfe/responsavelSeguro`);
    }
}