
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase } from '@/core/models/shared';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { CondicaoPagamentoService, FormaPagamentoService } from '@/core/services/financeiro';
import { Contrato, ContratoPessoaEndereco, ModalidadeFrete, SituacaoContrato, ContratoItem } from '@/core/models/vendas';
import { ContratoService, ModalidadeFreteService, SituacaoContratoService, VendasService } from '@/core/services/vendas';
import { PessoaService } from '@/core/services/geral';
import { Pessoa, PessoaEndereco } from '@/core/models/geral';
import { CondicaoPagamento, FormaPagamento } from '@/core/models/financeiro';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';

@Component
export default class CadastroContrato extends CrudBase{
    @Prop() item!: Contrato;
    @Prop() private value!: string;

    panel = [0];

    itemOriginal!: Contrato;
    service: ContratoService = new ContratoService();
    $refs!: {
        form: HTMLFormElement
    }

    pessoaService: PessoaService = new PessoaService();
    isPessoaLoading: boolean = false;
    
    onSearchCliente: any = null;
    clientes: Pessoa[] = [];

    onSearchVendedor: any = null;
    vendedores: Pessoa[] = [];
    
    contratoItem: ContratoItem = new ContratoItem();
    dialogContratoItem: boolean = false;
    
    editaItens: boolean = false;

    enderecoCobrancas: PessoaEndereco[] = [];
    enderecoEntregas: PessoaEndereco[] = [];
    contratoEnderecoEntrega: ContratoPessoaEndereco = new ContratoPessoaEndereco();

    situacoes: SituacaoContrato[] = [];
    situacaoService: SituacaoContratoService = new SituacaoContratoService();

    dialogFormaPagamento: boolean = false;
    formaPagamento: FormaPagamento = new FormaPagamento();
    formaPagamentos: FormaPagamento[] = [];
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();

    dialogCondicaoPagamento: boolean = false;
    condicaoPagamento: CondicaoPagamento = new CondicaoPagamento();
    condicaoPagamentos: CondicaoPagamento[] = [];
    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();

    dialogCliente: boolean = false;
    editaCliente: boolean = false;
    clienteId: number = -1;
    
    modalidadeFretes: ModalidadeFrete[] = [];
    modalidadeFretesService: ModalidadeFreteService = new ModalidadeFreteService();

    listaSimNao: any[] = [ { id: true, nome: 'Sim' }, { id: false, nome: 'Não' }];

    headerItens: any[]= [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Produto', value: 'item.nome', sortable:  false, use: false },
        { text: 'Regra Fiscal', value: 'regraFiscal.numeroNome', sortable:  false },
        { text: 'Quantidade', value: 'quantidade', sortable:  false, use: false },
        { text: 'Valor', value: 'valor', sortable:  false }
    ]
    
    headerEnderecos: any[] = [
        { text: '', value: 'actions', sortable:  false },
        { text: 'Endereço', value: 'pessoaEndereco.endereco.enderecoSemCep', sortable:  false },
        { text: 'Valor', value: 'valor', sortable:  false }
    ]

    TipoContratoSelect: any[] = [
        { nome: 'Quantidade', value: true },
        { nome: 'Valor', value: false }
    ]
    
    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch('item.isPorQuantidade')
    MudarTipoContrato(){
        if (this.item.isPorQuantidade == false) {
            this.item.itens.forEach(x => x.quantidade = 0);
            
        }
        else {
            this.item.valor = 0;
        }
    };

    @Watch('onSearchCliente')
    searchCliente (val: string) {

        if (this.item.clienteId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true;
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVendedor')
    searchVendedor (val: string) {
        if (this.item.vendedorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;
        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Vendedor).then(
            res => {
                this.vendedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('item.clienteId')
    findCliente (val: number) {

        if (!val) return;

        const cliente = this.clientes.find(x => x.id = val);

        if (cliente === null) {
            AlertSimple("Aviso!", "Cliente não encontrado.", "warning");
            return;
        }

        this.pessoaService.GetEnderecos(this.item.clienteId, false).then(
            res => {
                this.enderecoCobrancas = res.data;
            }
        )

        this.pessoaService.GetEnderecos(this.item.clienteId, true).then(
            res => {
                this.enderecoEntregas = res.data;
            }
        )
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.clientes.push(this.item.cliente);
            this.vendedores.push(this.item.vendedor);
        }
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    AbrirDialogFormaPagamento() {
        this.formaPagamento = new FormaPagamento();
        this.dialogFormaPagamento = true;
    }

    SalvarFormaPagamento(id: any) {
        this.formaPagamentoService.ObterPorId(id).then(
            res => {
                this.item.formaPagamentoId = id;
                this.formaPagamentos.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogCondicaoPagamento() {
        this.condicaoPagamento = new CondicaoPagamento();
        this.dialogCondicaoPagamento = true;
    }

    SalvarCondicaoPagamento(id: any) {
        this.condicaoPagamentoService.ObterPorId(id).then(
            res => {
                this.item.condicaoPagamentoId = id;
                this.condicaoPagamentos.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogCliente(){
        this.clienteId = 0;
        this.dialogCliente = true;
    }

    SalvarCliente(id: any){
        this.pessoaService.ObterPorId(id).then(
            res => {
                this.item.clienteId = id;
                this.clientes.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Carregar() {
        this.situacaoService.ListarTudo().then(
            res => this.situacoes = res.data.items,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.formaPagamentoService.AutoComplete(true).then(
            res => this.formaPagamentos = res.data,
            err => AlertSimpleErr("Aviso!", err) 
        )
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => this.condicaoPagamentos = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
        this.modalidadeFretesService.ListarTudo().then(
            res => {
                this.modalidadeFretes = res.data.items;
                },
            err => AlertSimpleErr("Aviso!", err)
        )

        new VendasService().GetParametro().then(
            res => {
                this.item.freteInvisivel = res.data.freteInvisivel;
                },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    Somar(){
        let valor = 0;
        this.item.itens.forEach(x => {
            valor += x.quantidade * x.valor
        });
        return valor;
    }

    //ENDERECO ENTREGA
    AdicionarEndereçoEntrega() {
        this.contratoEnderecoEntrega.contratoId = this.item.id;
        this.contratoEnderecoEntrega.pessoaEndereco = this.enderecoEntregas.find(x => x.id === this.contratoEnderecoEntrega.pessoaEnderecoId)!
        if(this.item.enderecoEntregas.find(x => x.pessoaEnderecoId == this.contratoEnderecoEntrega.pessoaEnderecoId)){
            AlertSimple("Aviso!", "Esse item já se encontra inserido.", "warning");
            this.contratoEnderecoEntrega = new ContratoPessoaEndereco();
            return;
        }
        this.item.enderecoEntregas.push(this.contratoEnderecoEntrega);
        this.contratoEnderecoEntrega = new ContratoPessoaEndereco();
    }

    ExcluirEndereco(item: ContratoPessoaEndereco){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.enderecoEntregas.indexOf(item)
                context.item.enderecoEntregas.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    //ITENS
    AbrirDialogCadastroItem(item?: ContratoItem){
        if(item) {
            this.editaItens = true;
            this.contratoItem = item;
        }
        else {
            this.editaItens = false;
            this.contratoItem = new ContratoItem();
        }
        this.dialogContratoItem = true;
    }

    SalvarItem(){
        if(!this.editaItens)
            this.item.itens.push(this.contratoItem);
        this.contratoItem = new ContratoItem();
    }

    ExcluirItens(item: ContratoItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                if(item.entregue > 0){
                    AlertSimple("Aviso!", "O item possui venda realizada!", "warning")
                    return;
                }

                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    Close(){
        this.dialog = false;
    }
}
