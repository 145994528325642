export class BoletimMedicaoAlteracoesModel {

    id: number = 0;
    detalharImposto: boolean = false;
    descontoGeral: number = 0;
    descontoAtracacao: number = 0;
    descontoAmarracao: number = 0;

    ternos: BoletimMedicaoTernoAlteracoesModel[] = [];
    fainas: BoletimMedicaoFainaAlteracoesModel[] = [];
    equipamentos: BoletimMedicaoEquipamentoAlteracoesModel[] = [];

    constructor(model?: BoletimMedicaoAlteracoesModel)
    {
        if(!model)
            return;

        this.id = model.id;
        this.detalharImposto = model.detalharImposto;
        this.descontoGeral = model.descontoGeral;
        this.descontoAtracacao = model.descontoAtracacao;
        this.descontoAmarracao = model.descontoAmarracao;

        this.ternos = model.ternos;
        this.fainas = model.fainas;
        this.equipamentos = model.equipamentos;
    }
}

export class BoletimMedicaoTernoAlteracoesModel {

    id: number = 0;
    desconto: number = 0;

    constructor(model?: BoletimMedicaoTernoAlteracoesModel) {

        if(!model)
            return;

        this.id = model.id;
        this.desconto = model.desconto;
    }

}

export class BoletimMedicaoFainaAlteracoesModel {

    id: number = 0;
    desconto: number = 0;

    constructor(model?: BoletimMedicaoTernoAlteracoesModel) {

        if(!model)
            return;

        this.id = model.id;
        this.desconto = model.desconto;
    }

}

export class BoletimMedicaoEquipamentoAlteracoesModel {

    id: number = 0;
    valor: number = 0;
    desconto: number = 0;

    constructor(model?: BoletimMedicaoEquipamentoAlteracoesModel) {

        if(!model)
            return;

        this.id = model.id;
        this.valor = model.valor;
        this.desconto = model.desconto;
    }

}