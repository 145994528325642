import { http } from "@/core/ApiConfig";
import { Service } from "../shared";
import { SelecaoDocumentos } from "@/core/models/documentos/SelecaoDocumentos";

export class SelecaoDocumentosService extends Service {

    constructor() {
        super('v1/selecaodocumentos')
    }

    Enviar(id: string) {
        return http.post(`${this.GetNomeControle()}/Enviar/${id}`);
    }

    Download(id: string){
        return http.get(`${this.GetNomeControle()}/${id}/download`, {responseType: 'blob'});
    }

    CopiarLink(id: string){
        return http.get(`${this.GetNomeControle()}/ObterLink`, {
            params: {
                id
            }
        });
    }
}