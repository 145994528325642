import { LocalOperacao, MedicaoItem, Embarcacao } from ".";
import { Pessoa } from "../geral";
import { Operacao } from "../shared";

export class Medicao implements Shared.IEntity {
    
    id: number = 0;
    empresaId!: string;
    clienteId: number = 0;
    cliente!: Pessoa;
    embarcacaoId: number = 0;
    embarcacao!: Embarcacao;
    localOperacaoId: number = 0;
    localOperacao!: LocalOperacao;
    numero: number = 0;
    escopo: string = "";
    cargas: string = "";
    criacao: Operacao = new Operacao();

    itens: MedicaoItem[] = [];

    constructor(model?: Medicao) {
        
        if (!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.clienteId = model.clienteId;
        this.cliente = model.cliente;
        this.embarcacaoId = model.embarcacaoId;
        this.embarcacao = model.embarcacao;
        this.localOperacaoId = model.localOperacaoId;
        this.localOperacao = model.localOperacao;
        this.numero = model.numero;
        this.escopo = model.escopo;
        this.cargas = model.cargas;
        this.criacao = model.criacao;

        this.itens = model.itens;        
    }
}