
import { Component, Watch, Prop } from 'vue-property-decorator'
import * as jsonpatch from 'fast-json-patch';
import { ContaGerencial, Orcamento, OrcamentoContaGerencial } from '@/core/models/financeiro';
import { CentroCustoService, ContaGerencialService, OrcamentoService } from '@/core/services/financeiro';
import { CrudBase } from '@/core/models/shared';
import { AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';

@Component
export default class CadastroOrcamento extends CrudBase{
   @Prop() item!: Orcamento;
   @Prop() private value!: string;

   observer! : jsonpatch.Observer<Orcamento>;
   itemOriginal!: Orcamento;
   service: OrcamentoService = new OrcamentoService();
   $refs!: {
       form: HTMLFormElement,
       file: HTMLFormElement
   }

   contaGerenciais: any[] = [];
   contaGerencialService: ContaGerencialService = new ContaGerencialService();
    
   tipos: any = [ 
        {id: true, nome: "Despesa"}, 
        {id: false, nome: "Receita"}
    ];

    centroCustos: any = [];
   
   @Watch('value')
   Value(){
       this.dialog = this.value ? true : false;

       if (this.dialog){
          this.observer = jsonpatch.observe(this.item);
        }
        else{
            jsonpatch.unobserve(this.item, this.observer);
        }
   }

   @Watch("dialog")
   Dialog() {
       if (this.dialog) {
           this.Carregar();
       }
       else {
        this.$emit("fechou");
       }
   }

   @Watch('item')
   ItemWatch(){
       if(this.item.id > 0){
           //this.itemOriginal = jiff.clone(this.item);
       }
       else {
         this.AtualizarContaGerencial('D');
       }
       
       if (this.$refs.form) {
           this.$refs.form.resetValidation();
       }
   }

   @Watch('item.despesa')
   onChangedDespesa(){
       if (this.item.despesa){
         this.AtualizarContaGerencial('D');
       }
       else {
         this.AtualizarContaGerencial('R');
       }
   }

   beforeUpdate(){
       if (!this.dialog){
           this.$emit('fechou');
       }
   }

   Salvar(){
       if (this.$refs.form.validate()) {
           this.loading = true;
           let patchModel = jsonpatch.generate(this.observer);
           (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
               res => {
                   AlertSimpleRes("Aviso", res);
                   this.$emit("salvou");
                   this.Close();
               },
               err => {
                   AlertSimpleErr("Aviso", err);
               }
           ).finally(() => {
               this.loading = false;
           })
       }
   }
   
   Close(){
       this.dialog = false;
   }

   Carregar(){
        new CentroCustoService().Listagem().then(
            res => {
                this.centroCustos = res.data;
                this.centroCustos.unshift({
                    key: undefined,
                    value: ""
                });
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

   AtualizaItem(itemConta: OrcamentoContaGerencial){
        itemConta.valor.AtualizarTotal();
        this.item.AtualizarTotal();

        this.ContaCheck(itemConta.contaGerencialId);
   }

   AtualizarContaGerencial(tipo: string){
        this.contaGerencialService.Listagem(tipo).then(
            res => {
                this.contaGerenciais = res.data;

                if (this.item.id == 0){
                    this.item.contasGerenciais = [];
                    this.contaGerenciais.forEach(conta => {
                        let orcamentoConta = new OrcamentoContaGerencial();
                        orcamentoConta.contaGerencialId = conta.id;
                        orcamentoConta.contaGerencial = new ContaGerencial();
                        orcamentoConta.contaGerencial = conta;
                        this.item.contasGerenciais.push(orcamentoConta);
                    });
                }
            },
            err => AlertSimpleErr("Aviso", err)
        )
    }

    ContaCheck(id: number){
        let conta = this.item.contasGerenciais.find(x => x.contaGerencialId == id);
        conta!.check = true;

        this.VerificaParaCima(id);
        this.VerificaParaBaixo(id);
    }

    VerificaParaCima(id: number){

        let conta = this.item.contasGerenciais.find(x => x.contaGerencialId == id);

        if (conta?.check){

            let contaPai = this.item.contasGerenciais.find(x => x.contaGerencialId == conta!.contaGerencial.contaGerencialPaiId);
            contaPai!.check = false;

        }
    }

    VerificaParaBaixo(id: number){

        let contaPai = this.item.contasGerenciais.find(x => x.contaGerencialId == id);

        if (contaPai?.check){
            let contaFilhos = this.item.contasGerenciais.filter(x => x.contaGerencial.contaGerencialPaiId == id)

            contaFilhos.forEach(conta => {
                conta.check = false;
                conta.valor.ZerarValores();
            });
        }
    }

    ObterCorAgrupador(): string{
        return this.$vuetify.theme.dark ? '#383535' : '#F0F0F0';
    }

    TemFilho(contaGerencialId: number): boolean{
        return this.item.contasGerenciais.some(x => x.contaGerencial.contaGerencialPaiId == contaGerencialId);
    }

}
