import { http } from '../../ApiAuthConfig'

export class EmpresaService {

    private _nomeControle: string = 'v1/empresa';    

    public DadosEmpresa(guid: string){
        return http.get(`${this._nomeControle}/${guid}/dados`);
    }

    public UsuarioEmpresa(){
        return http.get(`${this._nomeControle}/UsuarioEmpresa`);
    }
}