import { PropostaOperacaoPortuaria, RegistroOperacao } from ".";
import { Operacao } from "../shared";

export class BoletimMedicao implements Shared.IEntity {

    id: number = 0;
    empresaId!: string;
    registroOperacaoId: number = 0;
    registroOperacao!: RegistroOperacao;
    propostaId: number = 0;
    proposta!: PropostaOperacaoPortuaria;

    criacao!: Operacao;
    atualizacao!: Operacao;

    detalharImposto: boolean = false;

    //VALORES PROPOSTA
    valorAmarracaoDesamarracao: number = 0;
    valorDWT: number = 0;
    localOperacaoPeriodo: number = 0;
    valorPeriodoOperante: number = 0;
    valorPeriodoInoperante: number = 0;

    //DESCONTOS ROA
    descontoGeral: number = 0;
    descontoAtracacao: number = 0;
    descontoAmarracao: number = 0;

    constructor(model?: BoletimMedicao)
    {
        if(!model)
            return;

        this.id = model.id;
        this.empresaId = model.empresaId;
        this.registroOperacaoId = model.registroOperacaoId;
        this.registroOperacao = model.registroOperacao;
        this.propostaId = model.propostaId;
        this.proposta = model.proposta;
        this.criacao = model.criacao;
        this.atualizacao = model.atualizacao;
        this.detalharImposto = model.detalharImposto;
        
        this.valorAmarracaoDesamarracao = model.valorAmarracaoDesamarracao;
        this.valorDWT = model.valorDWT;
        this.localOperacaoPeriodo = model.localOperacaoPeriodo;
        this.valorPeriodoOperante = model.valorPeriodoOperante;
        this.valorPeriodoInoperante = model.valorPeriodoInoperante;
        
        this.descontoGeral = model.descontoGeral;
        this.descontoAtracacao = model.descontoAtracacao;
        this.descontoAmarracao = model.descontoAmarracao;
    }
}
