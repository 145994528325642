
import { Component, Watch, Prop } from 'vue-property-decorator'
import jiff from 'jiff';
import { CrudBase, Estado, Municipio } from '@/core/models/shared';
import { EnderecoService } from '@/core/services/finder';
import { AlertExcludeQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from '@/core/services/shared/AlertService';
import { Contrato, ContratoItem, ModalidadeFrete, Pedido, PedidoItem, TabelaPreco, Venda, VendaItem } from '@/core/models/vendas';
import { ContratoService, PedidoService, VendaService } from '@/core/services/vendas';
import { Pessoa } from '@/core/models/geral';
import { PessoaService } from '@/core/services/geral';
import { CondicaoPagamento, FormaPagamento, Receita, SituacaoReceitaParcela } from '@/core/models/financeiro';
import { CondicaoPagamentoService, FormaPagamentoService, ReceitaService } from '@/core/services/financeiro';
import { ModalidadeFreteService } from '@/core/services/vendas';
import { VeiculoService } from '@/core/services/transporte';
import { Veiculo } from '@/core/models/transporte';
import { RegraFiscalService, NFSeService } from '@/core/services/dfe';
import { RegraFiscal, NFSeTributo } from '@/core/models/dfe';
import { EnumPessoaNatureza } from '@/core/models/shared/Enumerados';
import { PedidoAutoCompleteModel } from '@/core/models/vendas/model';
import { AddDays } from '@/assets/scripts/helper';

@Component
export default class CadastroVenda extends CrudBase{
    @Prop() item!: Venda;
    @Prop() pedido!: PedidoAutoCompleteModel;
    @Prop() private value!: string;
    @Prop() visualizar!: boolean;

    tipoVendaId: number = 0;

    itemOriginal!: Venda;
    service: VendaService = new VendaService();
    $refs!: {
        form: HTMLFormElement,
        file: HTMLFormElement
    }

    tipoVendaLista: any = [ 
        {id: 0, nome: "Avulso"}, 
        {id: 1, nome: "Contrato"},
        {id: 2, nome: "Pedido"}
    ];

    panel: any = [0];

    tributosServico: NFSeTributo = new NFSeTributo();

    nfseService: NFSeService = new NFSeService();

    regraFiscalService: RegraFiscalService = new RegraFiscalService();
    regrasFiscais: RegraFiscal[] = [];

    pessoaService: PessoaService = new PessoaService();
    isPessoaLoading: boolean = false;
    clientes: Pessoa[] = [];
    cliente: Pessoa = new Pessoa();
    onSearchCliente: any = null;
    
    vendedores: Pessoa[] = [];
    onSearchVendedor: any = null;
    
    transportadores: Pessoa[] = [];
    onSearchTransportador: any = null;
    
    motoristas: Pessoa[] = [];
    onSearchMotorista: any = null;

    enderecoService: EnderecoService = new EnderecoService();
    estadoId: number = 0;
    estados: Estado[] = [];
    municipios: Municipio[] = [];

    isVeiculoLoading: boolean = false;
    onSearchVeiculo: any = null;
    veiculoService: VeiculoService = new VeiculoService();
    veiculos: Veiculo[] = [];
    dialogVeiculo: boolean = false;
    veiculo: Veiculo = new Veiculo();
    
    formaPagamentoService: FormaPagamentoService = new FormaPagamentoService();
    formaPagamentos: FormaPagamento[] = [];

    condicaoPagamentoService: CondicaoPagamentoService = new CondicaoPagamentoService();
    condicoes: CondicaoPagamento[] = [];

    modalidadeFreteService: ModalidadeFreteService = new ModalidadeFreteService();
    modalidadeFretes: ModalidadeFrete[] = [];

    isContratoLoading: boolean = false;
    contratoService: ContratoService = new ContratoService();
    contratos: Contrato[] = [];
    contratoItens: ContratoItem[] = [];
    onSearchContrato: any = null;

    isPedidoLoading: boolean = false;
    pedidoService: PedidoService = new PedidoService();
    pedidos: PedidoAutoCompleteModel[] = [];
    pedidoItens: PedidoItem[] = [];
    onSearchPedido: any = null;
    
    tabelaPreco: TabelaPreco = new TabelaPreco();
    
    valorFrete: number = 0;
    locaisEntregas: any[] = [];

    gerarReceita: boolean = false;
    receitaGerada: boolean = false;
    receita: Receita = new Receita();

    dialogVendaItem: boolean = false;
    vendaItem: VendaItem = new VendaItem();
    valorLiquido: number = 0;
    produtoHeader: any[] = [
        { text: '', value: 'actions' },
        { text: 'Produto/Serviço', value: 'produto.codigoNome' },
        { text: 'Unitário', value: 'valorUnitario' },
        { text: 'Qtd', value: 'quantidade' },
        { text: 'Desconto', value: 'desconto' },
        { text: 'Frete', value: 'frete' },
        { text: 'Outras', value: 'outrasDespesas' },
        { text: 'Total', value: 'valorTotal' },
    ]

    @Watch('value')
    Value(){
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (this.dialog) {
            this.Carregar();
            
            if(!!this.pedido){
                this.tipoVendaId = 2;
                this.pedidos.push(this.pedido);
                this.item.pedidoId = this.pedido.id;
            }
        }
        else{
            this.$emit("fechou");
        }
    }

    @Watch('item')
    ItemWatch(){
        if(this.item.id > 0){
            this.itemOriginal = jiff.clone(this.item);
            this.estadoId = this.item.servico.municipioPrestacao ? this.item.servico.municipioPrestacao.estadoId : 0;
        }
        else {
            this.estadoId = 33;
        }

        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        }

        if(this.item.clienteId > 0){
            this.clientes.push(this.item.cliente);
        }

        if(this.item.comercial.vendedorId){
            this.vendedores.push(this.item.comercial.vendedor);
        }
    }

    @Watch("tipoVenda")
    WatchTipoVenda(){
        
        if(!!this.pedido)
            return;

        this.item = new Venda();        
        this.locaisEntregas = [];
        this.pedidoItens = [];
        this.contratoItens = [];
        this.vendedores = [];
        // this.item.clienteId = 0;
        // this.item.cliente = undefined;
        // this.item.contratoId = undefined;
        // this.item.contrato = undefined;
        // this.item.pedidoId = undefined;
        // this.item.pedido = undefined;
        // this.item.comercial.vendedorId = undefined;
        // this.item.formaPagamentoId = 0;
        // this.item.condicaoPagamentoId = 0;
        // this.item.enderecoEntregaId = undefined;
    }

    @Watch("item.enderecoEntregaId")
    WatchLocalEntrega(){
        if(!this.item.enderecoEntregaId)
            return;

        if(this.tipoVendaId == 0 || (this.item.contratoId && this.item.freteInvisivel)){
            this.valorFrete = 0;
        }
        else if(this.tipoVendaId == 1 || this.tipoVendaId == 2){
            let endereco = this.locaisEntregas.find(x => x.pessoaEnderecoId == this.item.enderecoEntregaId)!;
            if(endereco)
                this.valorFrete = endereco.valor;
            else
                this.valorFrete = 0;
        }
    }

    @Watch('item.clienteId')
    WatchCliente(){
        if(this.item.clienteId > 0) {
            this.pessoaService.ObterPorId(this.item.clienteId, "Enderecos.Endereco.Municipio.Estado, Endereco.Municipio.Estado, ProdutosServicos.ProdutoServico, Cliente.Comercial.TabelaPreco.Itens.Item").then(
                res => {
                    this.cliente = new Pessoa(res.data);
                    this.estadoId = this.cliente.endereco.municipio.estadoId;
                    this.item.servico.municipioPrestacaoId = this.cliente.endereco.municipioId;
                    if (this.cliente.cliente.comercial.vendedorId && !this.item.comercial.vendedorId) {
                        this.item.comercial.vendedorId = this.cliente.cliente.comercial.vendedorId;
                        this.pessoaService.ObterPorId(this.item.comercial.vendedorId).then(
                            res => {
                                let vendedor = new Pessoa(res.data)
                                this.vendedores.push(vendedor);
                            },
                            err => {
                                AlertSimpleErr("Aviso", err);
                            }
                        )
                    }
                    if(!this.item.contratoId){
                        this.locaisEntregas = [];
                        this.locaisEntregas = this.cliente.enderecos;
                        this.locaisEntregas.unshift({
                            id: null,
                            endereco: {
                                enderecoSemCep: "O mesmo do principal"
                            }
                        })
                    }
                    if(this.cliente.cliente.comercial.tabelaPrecoId)
                        this.tabelaPreco = this.cliente.cliente.comercial.tabelaPreco;
                    
                    else if(!this.cliente.cliente.comercial.tabelaPrecoId)
                        this.tabelaPreco = new TabelaPreco();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch("estadoId")
    onEstadoIdChanged(value: number) {
        this.CarregarMunicipio(value);
    }

    @Watch('onSearchCliente')
    searchCliente (val: string) {
        
        if (this.item.clienteId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Cliente).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVendedor')
    searchVendedor (val: string) {

        if (this.item.comercial.vendedorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Vendedor, false, false).then(
            res => {
                this.vendedores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchTransportador')
    searchTransportador (val: string) {

        if (this.item.transporte.transportadorId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Transportador, false, false).then(
            res => {
                this.transportadores = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchMotorista')
    searchMotorista (val: string) {

        if (this.item.transporte.motoristaId) return;
        if (this.isPessoaLoading) return;
        if (!val) return;

        this.isPessoaLoading = true
        this.pessoaService.AutoComplete(val, EnumPessoaNatureza.Motorista, false, false).then(
            res => {
                this.motoristas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPessoaLoading = false));
    }

    @Watch('onSearchVeiculo')
    searchVeiculo (val: string) {

        if (this.item.transporte.veiculoId) return;
        if (this.isVeiculoLoading) return;
        if (!val) return;

        this.isVeiculoLoading = true
        this.veiculoService.AutoComplete(val).then(
            res => {
                this.veiculos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isVeiculoLoading = false));
    }

    @Watch('onSearchContrato')
    searchContrato (val: string) {
        if (this.item.contratoId) return;
        if (this.isContratoLoading) return;
        if (!val) return;
        this.isContratoLoading = true
        this.contratoService.AutoComplete(val, '1').then(
            res => {
                this.contratos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isContratoLoading = false));
    }

    @Watch('onSearchPedido')
    searchPedido (val: string) {
        if (this.item.pedidoId) return;
        if (this.isPedidoLoading) return;
        if (!val) return;
        this.isPedidoLoading = true
        this.pedidoService.AutoComplete(val).then(
            res => {
                this.pedidos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.isPedidoLoading = false));
    }

    @Watch('item.servico.regraFiscalId')
    CalcularTributos(){
        if (this.item.servico.regraFiscalId){
            this.nfseService.CalcularTributos(this.SomarValorTotal(), this.item.valorDesconto, this.item.servico.regraFiscalId).then(
                res => {
                    this.tributosServico = res.data.tributo;
                    this.item.servico.totalRetencao = res.data.tributo.totalRetencao;
                    this.valorLiquido = res.data.valorLiquido;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
        }
        else {
            this.tributosServico = new NFSeTributo();
            this.item.servico.totalRetencao = 0;
        }
    }

    @Watch('item.contratoId')
    WatchContrato(){
        if(!!this.item.contratoId){
            this.contratoService.ObterPorId(this.item.contratoId, "Vendedor, Itens.Item, EnderecoEntregas.PessoaEndereco.Endereco.Municipio.Estado").then(
                res => {
                    let contrato = new Contrato(res.data);
                    this.item.clienteId = contrato.clienteId;
                    this.vendedores.push(contrato.vendedor);
                    this.item.comercial.vendedorId = contrato.vendedorId;
                    this.item.formaPagamentoId = contrato.formaPagamentoId;
                    this.item.condicaoPagamentoId = contrato.condicaoPagamentoId;
                    this.item.freteInvisivel = contrato.freteInvisivel;
                    this.contratoItens = contrato.itens.filter(x => x.item.isServico == false);

                    this.locaisEntregas = contrato.enderecoEntregas;
                    this.locaisEntregas.unshift({
                        pessoaEnderecoId: null,
                        enderecoValor: "O mesmo do principal"
                    });

                    this.WatchLocalEntrega();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    @Watch('item.pedidoId')
    WatchPedido(){
        if(!!this.item.pedidoId){
            this.pedidoService.ObterPorId(this.item.pedidoId, "Itens.ContratoItem.Item, EnderecoEntrega.PessoaEndereco").then(
                res => {
                    let pedido = new Pedido(res.data);
                    this.item.contratoId = pedido.contratoId;
                    this.pedidoItens = pedido.itens.filter(x => x.contratoItem.item.isServico == false);

                    this.item.enderecoEntregaId = pedido.enderecoEntrega.pessoaEnderecoId;

                    this.WatchLocalEntrega();
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    AbrirDialogVeiculo(){
        this.veiculo = new Veiculo();
        this.dialogVeiculo = true;
    }

    SalvarVeiculo(id: any){
        this.veiculoService.ObterPorId(id).then(
            res => {
                this.item.transporte.veiculoId = id;
                this.veiculos.push(res.data);
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    beforeUpdate(){
        if (!this.dialog){
            this.$emit('fechou');
        }
    }

    Carregar(){
        this.enderecoService.ObterEstados().then(
            res => {
                this.estados = res.data.items;
            },
            err => AlertSimpleErr("Aviso!", err)
        );
        this.formaPagamentoService.AutoComplete(true).then(
            res => {
                this.formaPagamentos = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.condicaoPagamentoService.AutoComplete(true).then(
            res => {
                this.condicoes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.modalidadeFreteService.ListarTudo().then(
            res => {
                this.modalidadeFretes = res.data.items;
            }
        )
        this.regraFiscalService.AutoComplete(true).then(
            res => this.regrasFiscais = res.data,
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    CarregarMunicipio(estadoId: number){
        if(estadoId > 0)
            this.enderecoService.ObterMunicipios(estadoId).then(
                res => {
                    this.municipios = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            );
    }

    AdicionarItem(){
        this.vendaItem = new VendaItem();
        this.dialogVendaItem = true;
    }

    SalvarItem(){
        if(this.item.itens.find(x => x.produtoId == this.vendaItem.produtoId)){
            AlertSimple("Aviso", "Esse item já se encontra inserido.", "warning");
            this.vendaItem = new VendaItem();    
            return;
        }

        this.vendaItem.SetValorTotal();
        this.item.itens.push(this.vendaItem);
        this.vendaItem = new VendaItem();

        this.CalcularTributos();
    }

    ExcluirItem(item: VendaItem){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                let index = context.item.itens.indexOf(item);
                context.item.itens.splice(index, 1);
            });
        }
        AlertExcludeQuestion(excluir, true);
    }

    GerarReceita(){
        this.receita = new Receita();
        this.receita.descricao = `Gerado via Venda`;
        this.receita.numero = this.item.numero;
        this.receita.dataEmissao = this.item.dataEmissao;
        this.receita.pessoaId = this.item.clienteId;
        this.receita.pessoa = this.clientes.find(x => x.id == this.receita.pessoaId)!;
        this.receita.valor = this.item.itens.reduce((a, b) => a + b.GetValorTotal(), 0);
        this.receita.parcelas = [];
        let condicao = this.condicoes.find(x => x.id == this.item.condicaoPagamentoId)!;
        let qtdParcelas = condicao.itens.length;
        new ReceitaService().VisualizarParcelas(this.receita.valor, qtdParcelas, 1, AddDays(this.item.dataEmissao, condicao.itens[0].dias).toYYYYMMDD(), this.receita.parcelas, false).then(
            res => {
                this.receita.parcelas = res.data;
                this.receita.parcelas.forEach(parcela => {
                    parcela.situacao = new SituacaoReceitaParcela();
                    parcela.situacao.id = 1;
                    parcela.situacao.nome = "Aberto";
                });
                this.gerarReceita = true;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    EditarReceita(){
        this.receita = new Receita(this.item.receita);
        this.gerarReceita = true;
    }

    CarregarReceita()
    {
        this.gerarReceita = false;
        this.receitaGerada = true;
        this.item.receita = this.receita;
    }

    Salvar(){
        if (this.$refs.form.validate()) {
            this.loading = true;
            let patchModel = jiff.diff(this.itemOriginal, this.item, false);
            (this.item.id > 0 ? this.service.Salvar(patchModel, this.item.id) : this.service.Salvar(this.item)).then(
                res => {
                    const routeLink = this.$router.resolve(
                    { 
                        name: "vendaDetalhar",
                        params: { id: res.data.id.toString() }
                    });

                    window.open(routeLink.href, '_blank');

                    AlertSimpleRes("Aviso", res);
                    this.$emit("salvou");
                    this.Close();
                },
                err => {
                    AlertSimpleErr("Aviso", err);
                }
            ).finally(() => {
                this.loading = false;
            })
        }
    }

    SomarValorTotal() : number {
        let valor = 0;
        
        this.item.itens.forEach(x => {            
            valor += x['valorTotal'];
        });

        return valor;
    }
    
    Close(){
        this.estadoId = 0;
        this.dialog = false;
    }
}
