
export class Encargo {
    
    jurosDia: number = 0;
    multa: number = 0;
    descontoVencimento: number = 0;
    diasDescontoVencimento: number = 0;
    descontoAntecipacao: number = 0;
    diasAposVencimentoNaoReceber: number = 0;
    diasProtestar: number = 0;
    protestar: boolean = false;    

    constructor(model?: Encargo){

        if(!model)
            return;

        this.jurosDia = model.jurosDia;
        this.multa = model.multa;
        this.descontoVencimento = model.descontoVencimento;
        this.diasDescontoVencimento = model.diasDescontoVencimento;
        this.descontoAntecipacao = model.descontoAntecipacao;
        this.diasAposVencimentoNaoReceber = model.diasAposVencimentoNaoReceber;
        this.diasProtestar = model.diasProtestar;
        this.protestar = model.protestar;
    }
}