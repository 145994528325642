export class NFSeTributo {

  iss: NFSeTributoBase = new NFSeTributoBase();
  pis: NFSeTributoBase = new NFSeTributoBase();
  cofins: NFSeTributoBase = new NFSeTributoBase();
  inss: NFSeTributoBase = new NFSeTributoBase();
  irpj: NFSeTributoBase = new NFSeTributoBase();
  csll: NFSeTributoBase = new NFSeTributoBase();
  outros: NFSeTributoBase = new NFSeTributoBase();
  creditoIptu: number = 0;
  totalRetencao : number = 0;

  constructor(model?: NFSeTributo){

    this.iss.retem = false;

    if(!model)
      return;

    this.iss = model.iss;
    this.pis = model.pis;
    this.cofins = model.cofins;
    this.inss = model.inss;
    this.irpj = model.irpj;
    this.csll = model.csll;
    this.outros = model.outros;
    this.creditoIptu = model.creditoIptu;
    this.totalRetencao = model.totalRetencao;
  }
}

export class NFSeTributoBase {

  aliquota: number = 0;
  valor: number = 0;
  retem: boolean = true;
  valorRetido: number = 0;

  constructor(model?: NFSeTributoBase){

    if(!model)
      return;

    this.aliquota = model.aliquota;
    this.valor = model.valor;
    this.retem = model.retem;
    this.valorRetido = model.valorRetido;
  }
}